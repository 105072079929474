import { useState, useEffect, ChangeEvent } from "react"
import { memberLogPromotions } from "../../types";
import { Pagination, Typography } from "@mui/material";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import { styled } from '@mui/system';
import moment from "moment";

const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "rgba(255, 255, 255, .7)", // สีของหน้าที่ไม่ได้เลือก
    },
    '& .Mui-selected': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "white",
    },
});

interface PropsType {
    member_id: number
}

const MemberLogGetPromotions = ({ member_id }: PropsType) => {
    const cookies = new Cookies(null, { path: '/' });
    const [transaction, setTransaction] = useState<memberLogPromotions[]>([]);
    const [countAllTransactions, setCountAllTransactions] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);

    useEffect(() => {
        if(!member_id)
        {
            setTransaction([]);
            setCountAllTransactions(0);
        }

        getTransaction();
    }, [page, limit, member_id]);

    const getTransaction = async () => {
        try {
            const service = new ApiService();
            const getTransactions = await service.getMemberLogGetPromotions(cookies.get('access_token'), member_id, page, limit);

            if(getTransactions.data.status_code === 200)
            {
                setCountAllTransactions(getTransactions.data.count);
                setTransaction(getTransactions.data.transactions);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangePage = (_e: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <>
            <div className="col-12">
                <Typography fontWeight={`bold`} fontSize={16}>
                    ประวัติการรับโปรโมชั่น
                </Typography>
            </div>
            <div className="row">
                <div className="col-6">
                    <CustomPagination
                        count={(Math.ceil((countAllTransactions === null ? 1 : countAllTransactions) / limit)) <= 0 ? 1 : Math.ceil((countAllTransactions === null ? 1 : countAllTransactions) / limit)}
                        onChange={handleChangePage}
                        page={page}
                    />
                </div>
                <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                        style={{
                            maxHeight: 40,
                            maxWidth: 100,
                            marginBottom: 5
                        }}
                        defaultValue={`10`}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            if(!e.target)
                            {
                                return;
                            }

                            setLimit(parseInt(e.target.value));
                        }}
                        value={limit}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                    </select>
                </div>
            </div>
            <div className="card-datatable table-responsive">
                <table className="datatables-users table">
                    <thead className="border-top">
                        <tr>
                            <td align="center">ชื่อโปรโมชั่น</td>
                            <td align="center">วันที่และเวลา</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            transaction.length > 0 ? transaction.map((transaction: memberLogPromotions, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td align="center">
                                            {
                                                transaction.name
                                            }
                                        </td>
                                        <td align="center">
                                            {
                                                moment(transaction.createdAt).format('DD/MM/YYYY H:m:s')
                                            }
                                        </td>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <td align="center" colSpan={6}>
                                        ไม่พบข้อมูล
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default MemberLogGetPromotions;