import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// ROUTES
import App from './App';
import Home from './components/Home';
import Login from './components/Login/Login';
import Members from './components/Members/Members';
import MemberDetail from './components/MemberDetail/MemberDetail';
import CreateMember from './components/Members/CreateMember';
import DepositTransaction from './components/Deposit/DepositTransaction';
import DepositTransactionScb from './components/Deposit/DepositTransactionScb';
import WithdrawTransaction from './components/Withdraw/WithdrawTransaction';
import LogAddCredit from './components/LogCredit/LogAddCredit';
import LogRemoveCredit from './components/LogCredit/LogRemoveCredit';
import AgentTurnOver from './components/TurnOver/AgentTurnOver';
import PromotionList from './components/Promotion/PromotionList';
import Logout from './Logout';
import Profile from './components/Profile/Profile';
import CreateAdmin from './components/Admin/CreateAdmin';
import LogsAddCredit from './components/Admin/LogsAddCredit';
import ReportAll from './components/Report/ReportAll';
import ReportData from './components/Report/ReportData';
import ReportClick from './components/Report/ReportClick';
import SettingsMain from './components/Settings/SettingsMain';
import ManageLotto from './components/Lotto/ManageLotto';
import ReportLotto from './components/Report/ReportLotto';
import DepositScbCreateTransaction from './components/Deposit/DepositScbCreateTransaction';

// CSS
import './assets/css/index.css';
import ManageLottoById from './components/Lotto/ManageLottoById';
import LottoBillByPeriodId from './components/Lotto/LottoBillByPeriodId';
import TopNumber from './components/Lotto/TopNumber';
import ManageBankAccountMain from './components/ManageBankAccount/ManageBankAccountMain';
import ReportMember from './components/Report/ReportMember';
import ReportAffiliate from './components/Report/ReportAffiliate';
import ReportSumDepositTotal from './components/Report/ReportSumDepositTotal';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/login', element: <Login />
    },
    {
        path: '/logout', element: <Logout />
    },
    {
        element: <App />,
        children: [
            {
                path: "/", element: <Home />
            },
            {
                path: "/member", element: <Members />
            },
            {
                path: "/profile", element: <Profile />
            },
            {
                path: "/create-admin", element: <CreateAdmin />
            },
            {
                path: "/create-member", element: <CreateMember />
            },
            {
                path: "/transaction-deposit", element: <DepositTransaction />
            },
            {
                path: '/transaction-scb-created-list', element: <DepositScbCreateTransaction />
            },
            {
                path: "/transaction-deposit-scb", element: <DepositTransactionScb />
            },
            {
                path: "/transaction-withdraw", element: <WithdrawTransaction />
            },
            {
                path: "/report-all", element: <ReportAll />
            },
            {
                path: "/report-lotto", element: <ReportLotto />
            },
            {
                path: "/report-data", element: <ReportData />
            },
            {
                path: "/report-member", element: <ReportMember />
            },
            {
                path: "/report-sum-deposit-total", element: <ReportSumDepositTotal />
            },
            {
                path: "/report-affiliate", element: <ReportAffiliate />
            },
            {
                path: "/report-click", element: <ReportClick />
            },
            {
                path: "/member/:member_id", element: <MemberDetail />
            },
            {
                path: "/log/add/credit", element: <LogAddCredit />
            },
            {
                path: "/log/remove/credit", element: <LogRemoveCredit />
            },
            {
                path: "/agent/turnover", element: <AgentTurnOver />
            },
            {
                path: "/promotion", element: <PromotionList />
            },
            {
                path: "/settings", element: <SettingsMain />
            },
            {
                path: "/managelotto", element: <ManageLotto />
            },
            {
                path: "/managelotto/:lotto_id", element: <ManageLottoById />
            },
            {
                path: "/lotto/bills/:lotto_id/:period_id", element: <LottoBillByPeriodId />
            },
            {
                path: "/lotto/topnumber/:lotto_id/:period_id", element: <TopNumber />
            },
            {
                path: '/managebankaccount', element: <ManageBankAccountMain />
            },
            {
                path: '/logs-add-credit', element: <LogsAddCredit />
            }
        ]
    }
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
