import { useEffect, useState, ChangeEvent, useRef, FormEvent } from "react";
import { adminPermissionsType, logsAddCredit } from '../../types';
import { ApiService } from '../../services/api.service';
import Cookies from "universal-cookie";
import { Typography, Pagination } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";
import { styled } from '@mui/system';
import moment from "moment";

const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "rgba(255, 255, 255, .7)", // สีของหน้าที่ไม่ได้เลือก
    },
    '& .Mui-selected': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "white",
    },
});

const LogsAddCredit = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [logs, setLogs] = useState<logsAddCredit[]>([]);
    const [counts, setCounts] = useState<number | null>(null);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        reloadLogs();
    }, [page, limit]);

    const reloadLogs = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getLogs = await service.getAddCreditLogs(cookies.get('access_token'), page, limit);
            if(getLogs.data.status_code === 200)
            {
                setCounts(getLogs.data.counts);
                setLogs(getLogs.data.logs);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const handleChangePage = (_e: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.create_admin ? <>
                    <div className="col-12 mb-3">
                        <div className="card py-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-6">
                                    <CustomPagination
                                        count={Math.ceil((counts === null ? 0 : counts) / limit)}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                                <div className="col-sm-12 col-xl-6" style={{ display: `flex`, justifyContent: `flex-end` }}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-datatable table-responsive">
                            <table className="datatables-users table">
                                <thead className="border-top">
                                    <tr>
                                        <td align="center">ชื่อผู้ใช้</td>
                                        <td align="center">จำนวน</td>
                                        <td align="center">ดำเนินการโดย</td>
                                        <td align="center">วันเวลาที่ดำเนินการ</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        logs.length > 0 ? logs.map((element: logsAddCredit, index: number) => {
                                            return (
                                                <tr key={`logs-add-credit-${index}`}>
                                                    <td>
                                                        {
                                                            element.ufa_username
                                                        }
                                                    </td>
                                                    <td align="center">
                                                        {
                                                            parseFloat(`${element.amount}`).toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })
                                                        }
                                                    </td>
                                                    <td align="center">
                                                        {
                                                            element.username
                                                        }
                                                    </td>
                                                    <td align="center">
                                                        {
                                                            `${moment(element.createdAt).format("DD/MM/YYYY")} ${moment(element.createdAt).format("H").padStart(2, '0')}:${moment(element.createdAt).format("m").padStart(2, '0')}:${moment(element.createdAt).format("s").padStart(2, '0')}`
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                            <tr>
                                                <td colSpan={12}>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        ไม่พบข้อมูล
                                                    </Typography>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card py-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-6">
                                    <CustomPagination
                                        count={Math.ceil((counts === null ? 0 : counts) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                                <div className="col-sm-12 col-xl-6" style={{ display: `flex`, justifyContent: `flex-end` }}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default LogsAddCredit;