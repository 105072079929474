import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const Logout = () => {
    const cookies = new Cookies(null, {path: '/'});
    const navigate = useNavigate();

    useEffect(() => {
        if(cookies.get('access_token'))
        {
            cookies.remove('access_token');
        }

        navigate('/');
    }, []);

    return (
        <>
            ระบบกำลังออกจากระบบให้คุณ
        </>
    )
}

export default Logout;