import { ApiService } from "../../services/api.service";
import { adminPermissionsType, memberScbListTransactionType, memberScbTransactionDepositType } from "../../types";
import Cookies from "universal-cookie";
import { useEffect, useState, ChangeEvent } from "react";
import { Typography, Pagination } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { Link } from "react-router-dom";
import { useOutletContext } from "../../OutletContext";
import { styled } from "@mui/system";
import moment from "moment";
import EditMember from "../Members/EditMember";

const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "rgba(255, 255, 255, .7)", // สีของหน้าที่ไม่ได้เลือก
    },
    '& .Mui-selected': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "white",
    },
});

const DepositScbCreateTransaction = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [countTransactions, setCountTransactions] = useState<number | null>(null);
    const [transactions, setTransactions] = useState<memberScbListTransactionType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);

    const [selectMemberId, setSelectMemberId] = useState<number | null>(null);
    const [tabOpen, setTabOpen] = useState<boolean>(false);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        getAllTransactions();
    }, [page, limit]);

    const getAllTransactions = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getTransactions = await service.getListTransactionsScb(cookies.get('access_token'), page, limit);
            if(getTransactions.data.status_code !== 200)
            {
                return;
            }
            setCountTransactions(getTransactions.data.count);
            setTransactions(getTransactions.data.lists);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const handleChangePage = (_e: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.deposit_scb ? <>
                    <EditMember tabOpen={tabOpen} setTabOpen={setTabOpen} member_id={selectMemberId} />
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-4 d-flex" style={{
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    
                                </div>
                                <div className="col-sm-12 col-xl-8" style={{ 
                                    display: `flex`,
                                    justifyContent: `flex-end`,
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    แสดง:&nbsp;
                                    <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                        style={{
                                            maxHeight: 40,
                                            maxWidth: 100
                                        }}
                                        defaultValue={`10`}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            if(!e.target)
                                            {
                                                return;
                                            }

                                            setLimit(parseInt(e.target.value));
                                        }}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="70">70</option>
                                        <option value="80">80</option>
                                        <option value="90">90</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <CustomPagination
                                        count={Math.ceil((countTransactions === null ? 0 : countTransactions) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="table-responsive">
                            <table className="table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                รหัสอ้างอิง
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ชื่อผู้ใช้
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                จำนวนเงินที่ต้องการฝาก
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                จำนวนเงินที่ต้องโอน
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                สถานะ
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                สร้างเมื่อ
                                            </Typography>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactions.length > 0 ? transactions.sort((a: memberScbListTransactionType, b: memberScbListTransactionType) => {
                                            const dateA = new Date(`${a.createdAt}`).getTime();
                                            const dateB = new Date(`${b.createdAt}`).getTime();
                                            return dateB - dateA;
                                        }).map((transaction: memberScbListTransactionType, index: number) => {
                                            return (
                                                <tr key={index} style={
                                                    {
                                                        backgroundColor: transaction.status === 'success' ? "rgba(0, 190, 0, .2)" : (transaction.status === 'pending' ? "rgba(190, 190, 0, .2)" : "rgba(255, 0, 0, .2)")
                                                    }
                                                }>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                transaction.ref_code
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            <Link to={`/transaction-scb-created-list`} onClick={() => {
                                                                setSelectMemberId(transaction.member_id);
                                                                setTabOpen(true);
                                                            }}>
                                                                {
                                                                    transaction.member_username
                                                                }
                                                            </Link>
                                                        </Typography>
                                                        <Typography align="center" fontSize={`0.65rem`}>
                                                            {
                                                                transaction.member_full_name
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            ฿{
                                                                parseFloat(String(transaction.full_amount)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            ฿{
                                                                parseFloat(String(transaction.transfer_amount)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })
                                                            }
                                                        </Typography>
                                                        
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                transaction.status.toUpperCase()
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                `
                                                                    ${moment(transaction.createdAt).format('DD').padStart(2, '0')}/${moment(transaction.createdAt).format('MM').padStart(2, '0')}/${moment(transaction.createdAt).format('YYYY')} ${moment(transaction.createdAt).format('H').padStart(2, '0')}:${moment(transaction.createdAt).format('m').padStart(2, '0')}:${moment(transaction.createdAt).format('s').padStart(2, '0')}
                                                                `
                                                            }
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr>
                                            <td colSpan={6}>
                                                <Typography align="center" fontSize={`0.95rem`}>
                                                    ไม่พบข้อมูล
                                                </Typography>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <CustomPagination
                                        count={Math.ceil((countTransactions === null ? 0 : countTransactions) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default DepositScbCreateTransaction;