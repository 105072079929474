import { Box, Grid, Typography } from "@mui/material";
import { useState, useRef, useEffect, FormEvent, ChangeEvent } from "react";
import { checkedEnableNumberType, limitListType, updatePeriodResultsType } from "../../types";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

interface PropsType {
    tabOpen: boolean,
    setTabOpen: (state: boolean) => void,
    lottoId: number | null | undefined
    periodId: number | null
}

const ManageLimitsNumber = ({ tabOpen, setTabOpen, lottoId, periodId }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [list, setList] = useState<limitListType[]>([]);

    const numberRef = useRef<HTMLInputElement>(null);
    const [selectType, setSelectType] = useState<string>('3-1');
    const payRateRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if(!tabOpen)
        {
            return;
        }

        if(!periodId || !lottoId)
        {
            return;
        }

        loadPeriodLimitNumberData();
    }, [tabOpen, lottoId, periodId]);

    const loadPeriodLimitNumberData = async () => {
        if(!periodId || !lottoId)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getLimitList = await service.loadLimitNumberByPeriodIdAndLottoId(cookies.get('access_token'), lottoId, periodId);

            if(getLimitList.data.status_code === 200)
            {
                setList(getLimitList.data.list);
                setLoading(false);
            }
            else
            {
                setTabOpen(false);
                Swal.fire({
                    title: 'แจ้งเตือน',
                    icon: 'error',
                    text: `เกิดข้อผิดพลาด - ${getLimitList.data.status_code} ${getLimitList.data.message}`
                })
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
        setLoading(false);
    }

    const handleDeleteLimitNumber = async (number: string, state: number, subType: number) => {
        if(processing)
        {
            return;
        }

        if(!lottoId || !periodId)
        {
            return;
        }

        setProcessing(true);
        try {
            const service = new ApiService();
            const deleteLimitNumber = await service.deleteLimitNumberByPeriodIdAndLottoId(cookies.get('access_token'), lottoId, periodId, number, state, subType);

            if(deleteLimitNumber.data.status_code === 200)
            {
                setList(deleteLimitNumber.data.list);
                setProcessing(false);
            }
            else
            {
                setTabOpen(false);
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด - ${deleteLimitNumber.data.status_code} ${deleteLimitNumber.data.message}`
                })
            }
        } catch (err) {
            console.log(err);
            setProcessing(false);
        }
        setProcessing(false);
    }

    const addLimitNumber = async (e: FormEvent) => {
        e.preventDefault();
        if(!numberRef || !numberRef.current || !payRateRef || !payRateRef.current || !selectType)
        {
            return;
        }
        
        if(processing)
        {
            return;
        }

        if(!lottoId || !periodId)
        {
            return;
        }

        setProcessing(true);
        try {
            const service = new ApiService();
            const addLimitNumber = await service.addLimitNumber(cookies.get('access_token'), lottoId, periodId, numberRef.current.value, parseInt(selectType.split("-")[0]), parseInt(selectType.split("-")[1]), parseFloat(payRateRef.current.value));

            if(addLimitNumber.data.status_code === 200)
            {
                setList(addLimitNumber.data.list);
                setProcessing(false);

                const elementNumber = document.getElementById("add_limit_number_number") as HTMLInputElement;
                const elementPayRate = document.getElementById("add_limit_number_pay") as HTMLInputElement;

                if(elementNumber)
                {
                    elementNumber.value = "";
                }

                if(elementPayRate)
                {
                    elementPayRate.value = "";
                }
            }
            else
            {
                setTabOpen(false);
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด - ${addLimitNumber.data.status_code} ${addLimitNumber.data.message}`
                })
            }
        } catch (err) {
            console.log(err);
            setProcessing(false);
        }
        setProcessing(false);
    }

    return (
        lottoId && periodId && tabOpen && !loading ? <>
            <Box sx={
                {
                    display: 'block',
                    maxWidth: {
                        xs: `90%`,
                        sm: `90%`,
                        md: `50%`,
                        lg: `50%`
                    },
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: tabOpen ? 0 : -3000,
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: 10000000,
                    color: 'black',
                    transition: 'all .3s',
                    borderLeft: 'solid 3px #e0e3e6',
                    overflowY: 'scroll'
                }
            }>
                <Box p={2}>
                    <div className="row">
                        <div className="col-6">
                            <Typography fontSize={18} fontWeight={`bold`}>
                                จัดการเลขอั้น
                            </Typography>
                        </div>
                        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button className="btn btn-danger" onClick={() => {
                                setTabOpen(false)
                            }}>ปิด</button>
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="col-12">
                            <Typography fontSize={14} fontWeight={`bold`}>
                                เพิ่มเลขอั้น
                            </Typography>
                        </div>
                        <form name="add_limit_number_form" id="add_limit_number_form" onSubmit={addLimitNumber}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <div className="col-12">
                                        เลข
                                    </div>
                                    <div className="col-12">
                                        <input type="number" className="form-control form-control-sm" id="add_limit_number_number" name="add_limit_number_number" ref={numberRef} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="col-12">
                                        ประเภท
                                    </div>
                                    <div className="col-12">
                                        <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                            style={{
                                                maxHeight: 40,
                                            }}
                                            defaultValue={selectType}
                                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                                if(!e.target)
                                                {
                                                    return;
                                                }

                                                setSelectType(e.target.value);
                                            }}
                                        >
                                            <option value="3-1">3 ตัวบน</option>
                                            <option value="3-3">3 ตัวบนโต๊ด</option>
                                            <option value="2-1">2 ตัวบน</option>
                                            <option value="2-3">2 ตัวล่าง</option>
                                            <option value="4-1">วิ่งบน (2ตัว)</option>
                                            <option value="4-2">วิ่งล่าง (2ตัว)</option>
                                        </select>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className="col-12">
                                        อัตราจ่าย
                                    </div>
                                    <div className="col-12">
                                        <input type="string" className="form-control form-control-sm" id="add_limit_number_pay" name="add_limit_number_pay" ref={payRateRef} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <button className="btn btn-primary d-grid w-100" onClick={() => {

                                    }}>เพิ่ม</button>
                                </Grid>
                            </Grid>
                        </form>
                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="border-top">
                                        <tr>
                                            <th align="center">
                                                <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                    เลข
                                                </Typography>
                                            </th>
                                            <th align="center">
                                                <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                    ประเภท
                                                </Typography>
                                            </th>
                                            <th align="center">
                                                <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                    จ่าย
                                                </Typography>
                                            </th>
                                            <th align="center">
                                                <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                    จัดการ
                                                </Typography>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.length > 0 ? list.map((element: limitListType, index: number) => {
                                                return (
                                                    <tr key={`limit-number-${periodId}-${lottoId}-${index}`}>
                                                        <td>
                                                            <Typography align="center" fontSize={`0.95rem`}>
                                                                { element.number }
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography align="center" fontSize={`0.95rem`}>
                                                                {
                                                                    element.amountNumberState === 3 && element.subType === 1
                                                                    ? "3 ตัวบน"
                                                                    : element.amountNumberState === 3 && element.subType === 3
                                                                    ? "3 ตัวบนโต๊ด"
                                                                    : element.amountNumberState === 2 && element.subType === 1
                                                                    ? "2 ตัวบน"
                                                                    : element.amountNumberState === 2 && element.subType === 3
                                                                    ? "2 ตัวล่าง"
                                                                    : element.amountNumberState === 4 && element.subType === 1
                                                                    ? "วิ่งบน"
                                                                    : element.amountNumberState === 4 && element.subType === 2
                                                                    ? "วิ่งล่าง"
                                                                    : `${element.amountNumberState}-${element.subType}`
                                                                }
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography align="center" fontSize={`0.95rem`}>
                                                                ฿{
                                                                    parseFloat(String(element.pay)).toLocaleString('en-US', {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 0
                                                                    })
                                                                }
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-danger d-grid" onClick={() => handleDeleteLimitNumber(element.number, element.amountNumberState, element.subType)}>ลบ</button>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <tr>
                                                <td colSpan={4}>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        ยังไม่มีเลขอั้น
                                                    </Typography>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <Box sx={
                {
                    display: tabOpen ? 'block' : 'none',
                    maxWidth: `100%`,
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, .7)',
                    zIndex: 10000000 - 1,
                    transition: 'all .5s'
                }
            } onClick={() => setTabOpen(false)} />
        </> : null
    )
}

export default ManageLimitsNumber;