import { Box, Typography, Divider } from "@mui/material";
import { useState, useRef, ChangeEvent, useEffect } from "react";
import { checkedEnableNumberType, createLottoType, lottoDetailType, lottoPeriodType, updateLottoNonFileType } from "../../types";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import PageLoading from "../PageLoading/PageLoading";
import { useNavigate } from "react-router-dom";

interface PropsType {
    tabOpen: boolean,
    setTabOpen: (state: boolean) => void,
    reload: () => void,
    lotto_id: number | null
}

const EditLottoTab = ({ tabOpen, setTabOpen, reload, lotto_id }: PropsType) => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, {path: '/'});
    const [updating, setUpdating] = useState<boolean>(false);
    const lottoNameRef = useRef<HTMLInputElement>(null);

    const sixNumberRateRef = useRef<HTMLInputElement>(null);
    const threeNumberTodRateRef = useRef<HTMLInputElement>(null);
    const firstThreeNumberRateRef = useRef<HTMLInputElement>(null);
    const lastThreeNumberRateRef = useRef<HTMLInputElement>(null);
    const lastThreeNumberTwoRateRef = useRef<HTMLInputElement>(null);
    const lastTwoNumberUpperRateRef = useRef<HTMLInputElement>(null);
    const lastTwoNumberLowwerRateRef = useRef<HTMLInputElement>(null);
    const runUpperRateRef = useRef<HTMLInputElement>(null);
    const runLowwerRateRef = useRef<HTMLInputElement>(null);
    const minBetRef = useRef<HTMLInputElement>(null);
    const maxBetRef = useRef<HTMLInputElement>(null);

    const [lottoDetail, setLottoDetail] = useState<lottoDetailType | null>(null);
    const [period, setPeriod] = useState<lottoPeriodType[] | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [valueStartDate, setValueStartDate] = useState<Dayjs | null>(null);
    const [valueEndDate, setValueEndDate] = useState<Dayjs | null>(null);
    const [lottoId, setLottoId] = useState<number | null>(null);
    const [zIndex, setZIndex] = useState<number>(10000000);

    const [checkedEnableNumber, setCheckedEnableNumber] = useState<checkedEnableNumberType>({
        six_number: false,
        first_three_number: false,
        last_three_number: false,
        last_two_number: false
    });

    const handleCheckedEnableNumber = (check: string) => {
        if(typeof(check) !== 'string')
        {
            return;
        }

        const tmp_checked: checkedEnableNumberType = {
            ...checkedEnableNumber
        };

        if(check === 'six_number')
        {
            tmp_checked.six_number = !tmp_checked.six_number
        }
        else if(check === 'first_three_number')
        {
            tmp_checked.first_three_number = !tmp_checked.first_three_number
        }
        else if(check === 'last_three_number')
        {
            tmp_checked.last_three_number = !tmp_checked.last_three_number
        }
        else if(check === 'last_two_number')
        {
            tmp_checked.last_two_number = !tmp_checked.last_two_number
        }

        setCheckedEnableNumber(tmp_checked);
    }

    useEffect(() => {
        if(!lotto_id){ return; }
        loadLottoData(lotto_id);
    }, [lotto_id]);

    const loadLottoData = async (lotto_id: number) => {
        try {
            const service = new ApiService();
            const loadData = await service.loadLottoDataForEdit(cookies.get('access_token'), lotto_id);

            if(loadData.data.status_code === 200)
            {
                setLottoId(lotto_id);
                setLottoDetail({
                    name: loadData.data.data.name,
                    img_path: loadData.data.data.img_path,
                    active_period_id: loadData.data.data.active_period_id,
                    active_result_period_id: loadData.data.data.active_result_period_id,
                    payRate: loadData.data.data.payRate,
                    min_bet: loadData.data.data.min_bet,
                    max_bet: loadData.data.data.max_bet
                });
                setCheckedEnableNumber(JSON.parse(loadData.data.data.enable_number));

                setPeriod(loadData.data.data.period);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleUpdateLotto = async () => {
        if(updating)
        {
            return;
        }
        else if(
            !lottoNameRef.current ||
            !lottoId ||
            !sixNumberRateRef.current || 
            !firstThreeNumberRateRef.current || 
            !lastThreeNumberRateRef.current || 
            !lastThreeNumberTwoRateRef.current || 
            !lastTwoNumberUpperRateRef.current ||
            !lastTwoNumberLowwerRateRef.current ||
            !threeNumberTodRateRef.current ||
            !runUpperRateRef.current ||
            !runLowwerRateRef.current ||
            !minBetRef.current ||
            !maxBetRef.current
        )
        {
            return;
        }
        else if(
            lottoNameRef.current.value.trim() === '' ||
            sixNumberRateRef.current.value.trim() === '' ||
            firstThreeNumberRateRef.current.value.trim() === '' ||
            lastThreeNumberRateRef.current.value.trim() === '' ||
            lastThreeNumberTwoRateRef.current.value.trim() === '' ||
            lastTwoNumberUpperRateRef.current.value.trim() === '' ||
            lastTwoNumberLowwerRateRef.current.value.trim() === '' ||
            threeNumberTodRateRef.current.value.trim() === '' ||
            runUpperRateRef.current.value.trim() === '' ||
            runLowwerRateRef.current.value.trim() === '' ||
            minBetRef.current.value.trim() === '' ||
            maxBetRef.current.value.trim() === ''
        )
        {
            return;
        }

        setUpdating(true);
        try {
            const data: updateLottoNonFileType = {
                id: lottoId,
                name: lottoNameRef.current.value.trim(),
                checkedEnableNumber: JSON.stringify(checkedEnableNumber),
                payRate: JSON.stringify({
                    six_number: sixNumberRateRef.current.value.trim(),
                    first_three_number: firstThreeNumberRateRef.current.value.trim(),
                    three_tod_number: threeNumberTodRateRef.current.value.trim(),
                    last_three_number: lastThreeNumberRateRef.current.value.trim(),
                    last_three_number_two: lastThreeNumberTwoRateRef.current.value.trim(),
                    last_two_upper_number: lastTwoNumberUpperRateRef.current.value.trim(),
                    last_two_lowwer_number: lastTwoNumberLowwerRateRef.current.value.trim(),
                    run_upper_number: runUpperRateRef.current.value.trim(),
                    run_lowwer_number: runLowwerRateRef.current.value.trim()
                }),
                min_bet: minBetRef.current.value.trim(),
                max_bet: maxBetRef.current.value.trim()
            }

            const service = new ApiService();
            let update = null;

            if(file)
            {
                const formData: FormData = new FormData();
                formData.append('image', file);
                for(const key in data)
                {
                    formData.append(key, (data as any)[key]);
                }
                update = await service.updateLottoWithFile(cookies.get('access_token'), formData);
            }
            else
            {
                update = await service.updateLottoNonFile(cookies.get('access_token'), data);
            }

            setTabOpen(false);
            setLottoDetail(null);
            setPeriod(null);
            setFile(null);
            setPreview(null);
            setValueStartDate(null);
            setValueEndDate(null);
            Swal.fire({
                icon: update.data.status_code === 200 ? "success" : "error",
                title: 'แจ้งเตือน',
                text: update.data.status_code === 200 ? "แก้ไขหวยเรียบร้อยแล้ว" : update.data.message
            });

            reload();
        } catch (err) {
            console.log(err);
        }
        setUpdating(false);
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            const previewUrl = URL.createObjectURL(e.target.files[0]);
            setPreview(previewUrl);
        }
        else
        {
            setFile(null);
            setPreview(null);
        }
    };

    return (
        lottoDetail ? <>
            <Box sx={
                {
                    display: 'block',
                    maxWidth: {
                        xs: `90%`,
                        sm: `90%`,
                        md: `50%`,
                        lg: `50%`
                    },
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: tabOpen ? 0 : -3000,
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: zIndex,
                    color: 'black',
                    transition: 'all .3s',
                    borderLeft: 'solid 3px #e0e3e6',
                    overflowY: 'scroll'
                }
            }>
                <Box p={2}>
                    <div className="row">
                        <div className="col-6">
                            <Typography fontSize={18} fontWeight={`bold`}>
                                แก้ไข { lottoDetail.name }
                            </Typography>
                        </div>
                        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button className="btn btn-primary" onClick={handleUpdateLotto} disabled={updating}>แก้ไข</button>
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
                            <div className="card p-2">
                                <div className="col-12">
                                    <Typography align="center">
                                        รูปหวย
                                    </Typography>
                                </div>
                                <div className="col-12">
                                    <Box sx={{ display: `flex`, justifyContent: `center`, mt: 1 }}>
                                        <img src={preview ? preview : `${process.env.REACT_APP_API_URL}/image/${lottoDetail.img_path}`} alt="300x300" style={{ width: '100%', maxWidth: '250px', maxHeight: '250px' }} />
                                    </Box>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="formFile" className="form-label">เลือกไฟล์รูปภาพ</label>
                                    <input className="form-control" type="file" id="formFile" onChange={handleFileChange} />
                                </div>
                                <div className="col-12 mt-3">
                                    ชื่อหวย
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_name" name="lotto_name" ref={lottoNameRef} defaultValue={lottoDetail.name} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 6 ตัว
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_sixNumberRateRef" name="lotto_sixNumberRateRef" ref={sixNumberRateRef} defaultValue={lottoDetail.payRate.six_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 3 ตัวบน
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_firstThreeNumberRateRef" name="lotto_firstThreeNumberRateRef" ref={firstThreeNumberRateRef} defaultValue={lottoDetail.payRate.first_three_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 3 โต๊ด
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_ThreeNumberTodRateRef" name="lotto_ThreeNumberTodRateRef" ref={threeNumberTodRateRef} defaultValue={lottoDetail.payRate.three_tod_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 3 ตัวล่าง (1)
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_lastThreeNumberRateRef" name="lotto_lastThreeNumberRateRef" ref={lastThreeNumberRateRef} defaultValue={lottoDetail.payRate.last_three_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 3 ตัวล่าง (2)
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_lastThreeNumberTwoRateRef" name="lotto_lastThreeNumberTwoRateRef" ref={lastThreeNumberTwoRateRef} defaultValue={lottoDetail.payRate.last_three_number_two} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 2 ตัวบน
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_lastTwoNumberUpperRateRef" name="lotto_lastTwoNumberUpperRateRef" ref={lastTwoNumberUpperRateRef} defaultValue={lottoDetail.payRate.last_two_upper_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 2 ตัวล่าง
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_lastTwoNumberLowwerRateRef" name="lotto_lastTwoNumberLowwerRateRef" ref={lastTwoNumberLowwerRateRef} defaultValue={lottoDetail.payRate.last_two_lowwer_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย วิ่งบน
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_runUpperRateRef" name="lotto_runUpperRateRef" ref={runUpperRateRef} defaultValue={lottoDetail.payRate.run_upper_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย วิ่งล่าง
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_runLowwerRateRef" name="lotto_runLowwerRateRef" ref={runLowwerRateRef} defaultValue={lottoDetail.payRate.run_lowwer_number} />
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <hr/>
                                </div>
                                <div className="col-12 mt-3">
                                    ยอดแทงขั้นต่ำ / เลข
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_minBet" name="lotto_minBet" ref={minBetRef} defaultValue={lottoDetail.min_bet} />
                                </div>
                                <div className="col-12 mt-3">
                                    ยอดแทงสูดสุด / เลข
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_maxBet" name="lotto_maxBet" ref={maxBetRef} defaultValue={lottoDetail.max_bet} />
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <hr/>
                                </div>
                                {/* <div className="col-sm-12 col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="enable_sixnumber_edit" checked={checkedEnableNumber.six_number} onClick={() => handleCheckedEnableNumber('six_number')} />
                                        <label className="form-check-label" htmlFor="enable_sixnumber_edit">
                                            6 ตัว (รางวัลที่ 1)
                                        </label>
                                    </div>
                                </div> */}
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="enable_first_three_number_edit" checked={checkedEnableNumber.first_three_number} onClick={() => handleCheckedEnableNumber('first_three_number')} />
                                        <label className="form-check-label" htmlFor="enable_first_three_number_edit">
                                            3 ตัวหน้า
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="enable_last_three_number_edit" checked={checkedEnableNumber.last_three_number} onClick={() => handleCheckedEnableNumber('last_three_number')} />
                                        <label className="form-check-label" htmlFor="enable_last_three_number_edit">
                                            3 ตัวท้าย
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="enable_last_two_number_edit" checked={checkedEnableNumber.last_two_number} onClick={() => handleCheckedEnableNumber('last_two_number')} />
                                        <label className="form-check-label" htmlFor="enable_last_two_number_edit">
                                            2 ตัวล่าง
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <Box sx={
                {
                    display: tabOpen ? 'block' : 'none',
                    maxWidth: `100%`,
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    backgroundColor: zIndex !== 10000000 ? 'transparent' : 'rgba(0, 0, 0, .7)',
                    zIndex: zIndex - 1,
                    transition: 'all .5s'
                }
            } onClick={() => {
                setTabOpen(false);
                setLottoDetail(null);
                setPeriod(null);
                setFile(null);
                setPreview(null);
                setValueStartDate(null);
                setValueEndDate(null);
            }} />
        </> : (lotto_id ? <PageLoading /> : null)
    )
}

export default EditLottoTab;