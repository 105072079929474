import { useEffect, useState, useRef } from "react";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import { turnoverDataType, agentListType } from "../../types";
import Swal from "sweetalert2";

import {
    Grid,
    Box,
    Typography,
    Paper,
    Divider,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Select,
    MenuItem,
    Button
} from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const AgentTurnOver = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [turnoverData, setTurnoverData] = useState<turnoverDataType[] | null>(null);
    const [agent, setAgent] = useState<agentListType[] | null>(null);
    const agentRef = useRef<HTMLSelectElement>(null);
    const [valueStartDate, setValueStartDate] = useState<Dayjs | null>(null);
    const [valueEndDate, setValueEndDate] = useState<Dayjs | null>(null);

    const formatDate = (date: Dayjs | null) => {
        if (!date) return '';
        return date.format('DD/MM/YYYY');
    };

    useEffect(() => {
        (async () => {
            await getWebsiteSettings();
        })();
    }, []);

    const getWebsiteSettings = async () => {
        setLoading(true);
        try {
            const service = new ApiService();
            const getSettings = await service.getWebsiteSettings(cookies.get('access_token'));

            if(!getSettings.data || getSettings.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด (ERR: ${getSettings.data.message || "ไม่ทราบสาเหตุ"})`
                });
            }

            setAgent(getSettings.data.agent);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const getTurnoverData = async () => {
        if(loading)
        {
            return;
        }

        if(!valueStartDate || !valueEndDate || !agentRef.current)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getTurnOverData = await service.getTurnoverDataByAgentUsername(cookies.get('access_token'), agentRef.current.value, formatDate(valueStartDate), formatDate(valueEndDate));

            if(getTurnOverData.data.status_code === 200)
            {
                setTurnoverData(getTurnOverData.data.data);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด'
                });
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper sx={{ background: '#181818' }}>
                    <Box p={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography display={`flex`} justifyContent={`center`} color={`white`}>
                                    Turnover ทั้งหมด
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ borderColor: 'white', mb: 2 }} />
                            </Grid>
                            {
                                loading ? <Grid item xs={12} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                    <CircularProgress size={24} sx={
                                        {
                                            color: 'white'
                                        }
                                    } />
                                </Grid> : <>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        {
                                            agent !== null && agent.length > 0 ? <Select
                                                fullWidth
                                                // size="small"
                                                inputRef={agentRef}
                                                sx={
                                                    {
                                                        backgroundColor: 'white',
                                                    }
                                                }
                                                defaultValue={agent[0].username}
                                            >
                                                {
                                                    agent.map((element: agentListType) => {
                                                        return (
                                                            <MenuItem value={element.username}>
                                                                {element.username}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select> : null
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        {
                                            agent !== null ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="เริ่มต้น"
                                                    value={valueStartDate}
                                                    onChange={(newValue) => setValueStartDate(newValue)}
                                                    sx={
                                                        {
                                                            backgroundColor: 'white',
                                                            width: '100%'
                                                        }
                                                    }
                                                    format="DD/MM/YYYY"
                                                />
                                            </LocalizationProvider> : null
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        {
                                            agent !== null ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="สิ้นสุด"
                                                    value={valueEndDate}
                                                    onChange={(newValue) => setValueEndDate(newValue)}
                                                    sx={
                                                        {
                                                            backgroundColor: 'white',
                                                            width: '100%'
                                                        }
                                                    }
                                                    format="DD/MM/YYYY"
                                                    minDate={dayjs(valueStartDate)}
                                                />
                                            </LocalizationProvider> : null
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        {
                                            agent !== null ? <Button sx={
                                                {
                                                    backgroundColor: '#f4ce5c',
                                                    color: 'black',
                                                    px: 3,
                                                    width: {
                                                        xs: `100%`,
                                                        sm: `100%`,
                                                        md: 'auto',
                                                        lg: 'auto'
                                                    },
                                                    mt: {
                                                        xs: 1,
                                                        sm: 1,
                                                        md: 0,
                                                        lg: 0
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: '#e2be55',
                                                        color: 'black',
                                                    }
                                                }
                                            } onClick={getTurnoverData}>
                                                ดู
                                            </Button> : null
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper} sx={{ backgroundColor: '#181818' }}>
                                            <Table>
                                                <TableHead sx={{ backgroundColor: '#000000' }}>
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ชื่อผู้ใช้
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ติดต่อ
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                สกุลเงิน
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                จำนวนเดิมพัน
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                เงินหมุนเวียน
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                จำนวนสมาชิก
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                การนับเดิมพัน
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ยอดรวมของคอมมิชชั่น
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center" colSpan={3}>
                                                            <Typography color={`white`}>
                                                                สมาชิก
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center" colSpan={4}>
                                                            <Typography color={`white`}>
                                                                เอเย่นต์
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center" colSpan={4}>
                                                            <Typography color={`white`}>
                                                                มาสเตอร์เอเย่นต์/เอเย่นต์
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center" colSpan={4}>
                                                            <Typography color={`white`}>
                                                                บริษัท
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ชนะ/แพ้
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                คอมมิชชั่น
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ยอดรวม
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                เงินหมุนเวียน
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ชนะ/แพ้
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                คอมมิชชั่น
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ยอดรวม
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                เงินหมุนเวียน
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ชนะ/แพ้
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                คอมมิชชั่น
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ยอดรวม
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                เงินหมุนเวียน
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ชนะ/แพ้
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                คอมมิชชั่น
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ยอดรวม
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        turnoverData !== null && turnoverData.length > 0 ? turnoverData.map((element: turnoverDataType, index: number) => {
                                                            return (
                                                                <TableRow key={`turnover-${index}`}>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {element.username}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {element.contact}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {element.currency}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {element.bet_amount}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {element.turnover}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {element.member_count}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {element.stake_count}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {element.gross_commission}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.member_wl.includes("-") ? `red` : `#05c855`}>
                                                                            {element.member_wl}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.member_commission.includes("-") ? `red` : `#05c855`}>
                                                                            {element.member_commission}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.member_total.includes("-") ? `red` : `#05c855`}>
                                                                            {element.member_total}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.agent_turnover.includes("-") ? `red` : `#05c855`}>
                                                                            {element.agent_turnover}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.agent_wl.includes("-") ? `red` : `#05c855`}>
                                                                            {element.agent_wl}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.agent_commission.includes("-") ? `red` : `#05c855`}>
                                                                            {element.agent_commission}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.agent_total.includes("-") ? `red` : `#05c855`}>
                                                                            {element.agent_total}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.master_turnover.includes("-") ? `red` : `#05c855`}>
                                                                            {element.master_turnover}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.master_wl.includes("-") ? `red` : `#05c855`}>
                                                                            {element.master_wl}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.master_commission.includes("-") ? `red` : `#05c855`}>
                                                                            {element.master_commission}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.master_total.includes("-") ? `red` : `#05c855`}>
                                                                            {element.master_total}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.company_turnover.includes("-") ? `red` : `#05c855`}>
                                                                            {element.company_turnover}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.company_wl.includes("-") ? `red` : `#05c855`}>
                                                                            {element.company_wl}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.company_commission.includes("-") ? `red` : `#05c855`}>
                                                                            {element.company_commission}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.company_total.includes("-") ? `red` : `#05c855`}>
                                                                            {element.company_total}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }) : <TableRow>
                                                            <TableCell align="center" colSpan={23}>
                                                                <Typography color={`white`}>
                                                                    ไม่พบข้อมูล
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default AgentTurnOver;