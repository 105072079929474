import { ApiService } from "../../services/api.service";
import { adminPermissionsType, depositTransactionsType } from "../../types";
import Cookies from "universal-cookie";
import { useEffect, useState, ChangeEvent } from "react";
import { Typography, Pagination } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import EditMember from "../Members/EditMember";

const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "rgba(255, 255, 255, .7)", // สีของหน้าที่ไม่ได้เลือก
    },
    '& .Mui-selected': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "white",
    },
});

const DepositTransaction = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [countTransactions, setCountTransactions] = useState<number | null>(null);
    const [transactions, setTransactions] = useState<depositTransactionsType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);

    const [selectMemberId, setSelectMemberId] = useState<number | null>(null);
    const [tabOpen, setTabOpen] = useState<boolean>(false);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        getAllTransactions();
    }, [page, limit]);

    const getAllTransactions = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getTransactions = await service.getDepositTransactions(cookies.get('access_token'), page, limit);
            if(getTransactions.data.status_code !== 200)
            {
                return;
            }
            setCountTransactions(getTransactions.data.allCount);
            setTransactions(getTransactions.data.transaction);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const handleChangePage = (_e: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.deposit_transactions ? <>
                    <EditMember tabOpen={tabOpen} setTabOpen={setTabOpen} member_id={selectMemberId} />
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-12" style={{ 
                                    display: `flex`,
                                    justifyContent: `flex-end`,
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    แสดง:&nbsp;
                                    <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                        style={{
                                            maxHeight: 40,
                                            maxWidth: 100
                                        }}
                                        defaultValue={`10`}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            if(!e.target)
                                            {
                                                return;
                                            }

                                            setLimit(parseInt(e.target.value));
                                        }}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="70">70</option>
                                        <option value="80">80</option>
                                        <option value="90">90</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <CustomPagination
                                        count={Math.ceil((countTransactions === null ? 0 : countTransactions) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="table-responsive">
                            <table className="table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                #
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ชื่อผู้ใช้
                                            </Typography>
                                        </th>
                                        <th align="center" colSpan={3}>
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                เครดิต
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                สถานะ
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ช่องทาง
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                วันเวลาแจ้งฝาก
                                            </Typography>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th />
                                        <th />
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ก่อนฝาก
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ฝาก
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                หลังฝาก
                                            </Typography>
                                        </th>
                                        <th />
                                        <th />
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactions.length > 0 ? transactions.sort((a: depositTransactionsType, b: depositTransactionsType) => {
                                            const dateA = new Date(a.createdAt).getTime();
                                            const dateB = new Date(b.createdAt).getTime();
                                            return dateB - dateA;
                                        }).map((transaction: depositTransactionsType, index: number) => {
                                            return (
                                                <tr key={index} style={{
                                                    backgroundColor: transaction.status === 'success' ? "rgba(0, 190, 0, .2)" : (transaction.status === 'pending' ? "rgba(190, 190, 0, .2)" : "rgba(255, 0, 0, .2)")
                                                }}>
                                                    <td align="center">
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                transaction.payment_id
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td align="center">
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            <Link to={`/transaction-deposit`} onClick={() => {
                                                                setSelectMemberId(transaction.member_id);
                                                                setTabOpen(true);
                                                            }}>
                                                                {
                                                                    transaction.ufa_username
                                                                }
                                                            </Link>
                                                        </Typography>
                                                    </td>
                                                    <td align="center">
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                parseFloat(String(transaction.credit_before)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td align="center">
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                parseFloat(String(transaction.amount)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td align="center">
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                parseFloat(String(transaction.credit_after)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td align="center">
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                transaction.status.toUpperCase()
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td align="center">
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                transaction.channel.toUpperCase()
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td align="center">
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                `${String(new Date(String(transaction.createdAt)).getDate())}/${String(new Date(String(transaction.createdAt)).getMonth() + 1)}/${String(new Date(String(transaction.createdAt)).getFullYear())}`
                                                            }
                                                            &nbsp;
                                                            {
                                                                `${String(new Date(String(transaction.createdAt)).getHours())}:${String(new Date(String(transaction.createdAt)).getMinutes())}:${String(new Date(String(transaction.createdAt)).getSeconds())}`
                                                            }
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                            <tr>
                                                <td colSpan={8}>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        ไม่พบข้อมูล
                                                    </Typography>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <CustomPagination
                                        count={Math.ceil((countTransactions === null ? 0 : countTransactions) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default DepositTransaction;