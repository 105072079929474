import { useEffect, useState } from "react";
import { depositWithdrawChartType, depositWithdrawSumType, dashboardMemberType, registerReferrerType, adminPermissionsType, listRegisterByWhoType, scbBalanceType } from "../types";
import { ApiService } from "../services/api.service";
import DepositWithdrawChart from "./Chart/DepositWithdrawChart";
import Cookies from "universal-cookie";
import { useOutletContext } from "../OutletContext";
import { Typography } from "@mui/material";

const Home = () => {
    const cookies = new Cookies(null, { path: '/' });
    const [depositWithdrawChart, setDepositWithdrawChart] = useState<depositWithdrawChartType[] | null>(null);
    const [depositWithdrawSum, setDepositWithdrawSum] = useState<depositWithdrawSumType | null>(null);
    const [dashboardMemberData, setDashboardMemberData] = useState<dashboardMemberType | null>(null);
    const [referrerRegisterData, setReferrerRegisterData] = useState<registerReferrerType[] | null>(null);
    const [registerByWhoData, setRegisterByWhoData] = useState<listRegisterByWhoType[] | null>(null);
    const [hourData, setHourData] = useState<string[]>([]);
    const [depositCharData, setDepositChartData] = useState<number[]>([]);
    const [withdrawChartData, setWithdrawChartData] = useState<number[]>([]);

    const [speedPayBalance, setSpeedPayBalance] = useState<number>(0);
    const [zPayBalance, setZPayBalance] = useState<number>(0);
    const [bullpayBalance, setBullpayBalance] = useState<number>(0);
    const [scbBalance, setScbBalance] = useState<scbBalanceType[]>([]);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
        reloadData();
    }, []);

    useEffect(() => {
        if(!depositWithdrawChart)
        {
            return;
        }

        const tmp_hour_data: string[] = [];
        const tmp_deposit_data: number[] = [];
        const tmp_withdraw_data: number[] = [];
        depositWithdrawChart.forEach((element: depositWithdrawChartType) => {
            tmp_hour_data.push(element.hour);
            tmp_deposit_data.push(element.total_amount_deposit);
            tmp_withdraw_data.push(element.total_amount_withdraw);
        });
        setHourData(tmp_hour_data);
        setDepositChartData(tmp_deposit_data);
        setWithdrawChartData(tmp_withdraw_data);
    }, [depositWithdrawChart])

    const reloadData = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }

        const service = new ApiService();

        try {
            await getBullpayBalance();
            await getScbBalance();
        } catch (err) {
            console.log(err);
        }

        try {
            const getData = await service.getChartDataDashboard(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setDepositWithdrawChart(getData.data.chartData);
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const getData = await service.getChartDataDashboard(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setDepositWithdrawChart(getData.data.chartData);
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const getData = await service.getRegisterByWhoDashboard(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setRegisterByWhoData(getData.data.registerByWho);
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const getData = await service.getReferrerDashboard(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setReferrerRegisterData(getData.data.referrer);
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const getData = await service.getMemberDataDashboard(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setDashboardMemberData(getData.data.memberData);
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const getData = await service.getDepositWithdrawSum(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setDepositWithdrawSum(getData.data.sumDepositWithdraw);
            }
        } catch (err) {
            console.log(err);
        }

        // // getSpeedPayBalance();
        // // getZPayBalance();
    }

    const getScbBalance = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }

        try {
            const service = new ApiService();
            const getData = await service.getScbBalance(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setScbBalance(getData.data.balance);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getSpeedPayBalance = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }

        try {
            const service = new ApiService();
            const getData = await service.getSpeedPayBalance(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setScbBalance(getData.data.balance);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getZPayBalance = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }

        try {
            const service = new ApiService();
            const getData = await service.getZPayBalance(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setZPayBalance(getData.data.balance);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getBullpayBalance = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }

        try {
            const service = new ApiService();
            const getData = await service.getBullpayBalance(cookies.get('access_token'));

            if(getData.data.status_code === 200)
            {
                setBullpayBalance(getData.data.balance);
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-8 col-md-12 mb-4 order-0">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">ยอดฝากถอนวันนี้</h5>
                        </div>
                        <div className="card-body">
                            {
                                depositWithdrawSum !== null && <div className="d-flex justify-content-around align-items-center flex-wrap mb-4 pb-1">
                                    <div className="user-analytics text-center me-2">
                                        <i className="bx bx-user me-1"></i>
                                        <span>ยอดฝากวันนี้</span>
                                        <div className="d-flex align-items-center mt-2">
                                        <div className="chart-report" data-color="success" data-series="100"></div>
                                        <h3 className="mb-0">
                                            ฿{
                                                parseFloat(String(parseFloat(String(depositWithdrawSum.zpay_deposit)) + parseFloat(String(depositWithdrawSum.se_deposit)) + parseFloat(String(depositWithdrawSum.scb_deposit)) + parseFloat(String(depositWithdrawSum.bp_deposit)))).toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                            }
                                        </h3>
                                        </div>
                                    </div>
                                    <div className="sessions-analytics text-center me-2">
                                        <i className="bx bx-pie-chart-alt me-1"></i>
                                        <span>ยอดถอนวันนี้</span>
                                        <div className="d-flex align-items-center mt-2">
                                        <div className="chart-report" data-color="warning" data-series="10"></div>
                                        <h3 className="mb-0">
                                            ฿{
                                                parseFloat(String(parseFloat(String(depositWithdrawSum.zpay_withdraw)) + parseFloat(String(depositWithdrawSum.se_withdraw)) + parseFloat(String(depositWithdrawSum.bp_withdraw)))).toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                            }
                                        </h3>
                                        </div>
                                    </div>
                                    <div className="bounce-rate-analytics text-center">
                                        <i className="bx bx-trending-up me-1"></i>
                                        <span>ยอดคงเหลือ</span>
                                        <div className="d-flex align-items-center mt-2">
                                        <div className="chart-report" data-color="success" data-series="90"></div>
                                        <h3 className="mb-0">
                                            ฿{
                                                parseFloat(
                                                    String(
                                                        parseFloat(String(parseFloat(String(depositWithdrawSum.zpay_deposit)) + parseFloat(String(depositWithdrawSum.se_deposit)) + parseFloat(String(depositWithdrawSum.scb_deposit)) + parseFloat(String(depositWithdrawSum.bp_deposit))))
                                                        -
                                                        parseFloat(String(parseFloat(String(depositWithdrawSum.zpay_withdraw)) + parseFloat(String(depositWithdrawSum.se_withdraw)) + parseFloat(String(depositWithdrawSum.bp_withdraw))))
                                                    )
                                                ).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                            }
                                        </h3>
                                        </div>
                                    </div>
                                </div>
                            }
                            <DepositWithdrawChart hourData={hourData} depositCharData={depositCharData} withdrawChartData={withdrawChartData} />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-xl-4 mb-4">
                    <div className="card mb-3">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <div className="card-title mb-0">
                                <h5 className="m-0 me-2">ยอดสมัครใหม่วันนี้</h5>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-evenly align-items-center mb-4">
                                <div className="d-flex flex-column align-items-center gap-1">
                                    <h2 className="mb-2">
                                        {
                                            dashboardMemberData ? dashboardMemberData.count_reg_today : 0
                                        }
                                    </h2>
                                    <span>สมัครใหม่</span>
                                </div>
                                <div className="d-flex flex-column align-items-center gap-1">
                                    <h2 className="mb-2">
                                        {
                                            dashboardMemberData ? dashboardMemberData.count_reg_and_deposit_today_scb : 0
                                        }
                                    </h2>
                                    <span>สมัครฝาก</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <div className="card-title mb-0">
                                <h5 className="m-0 me-2">ยอดเงินในระบบ</h5>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-evenly align-items-center mb-4">
                                {
                                    scbBalance.length > 0 && scbBalance.map((element: scbBalanceType, index: number) => {
                                        return (
                                            <div className="d-flex flex-column align-items-center gap-1" key={`scb-balance-${index}`}>
                                                <h2 className="mb-2">
                                                    ฿{
                                                        parseFloat(String(element.balance)).toLocaleString('en-US', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })
                                                    }
                                                </h2>
                                                <span>SCB (X-{element.bank_number})</span>
                                            </div>
                                        )
                                    })
                                }
                                {/* <div className="d-flex flex-column align-items-center gap-1">
                                    <h2 className="mb-2">
                                        ฿{
                                            parseFloat(String(speedPayBalance)).toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })
                                        }
                                    </h2>
                                    <span>SpeedPay</span>
                                </div>
                                <div className="d-flex flex-column align-items-center gap-1">
                                    <h2 className="mb-2">
                                        ฿{
                                            parseFloat(String(zPayBalance)).toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })
                                        }
                                    </h2>
                                    <span>ZPay</span>
                                </div> */}
                            </div>
                            <div className="d-flex justify-content-evenly align-items-center mb-4">
                                <div className="d-flex flex-column align-items-center gap-1">
                                    <h2 className="mb-2">
                                        ฿{
                                            parseFloat(String(bullpayBalance)).toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })
                                        }
                                    </h2>
                                    <span>BullPay</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-4">
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <div className="card-title mb-0">
                                <h5 className="m-0 me-2">รายการสมัครและฝากวันนี้จากเซียน</h5>
                            </div>
                        </div>
                        <div className="card-datatable table-responsive">
                            <table className="datatables-users table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">เจ้าของ</th>
                                        <th align="center">มาจาก</th>
                                        <th align="center">จำนวน</th>
                                        <th align="center">ยอดฝากรวม</th>
                                        <th align="center">ยอดถอนรวม</th>
                                        <th align="center">ยอดคงเหลือ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        registerByWhoData && registerByWhoData.length > 0 ? registerByWhoData.map((element: listRegisterByWhoType, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {
                                                            element.reg_by
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            element.referrer
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            parseFloat(String(element.count)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                        }
                                                    </td>
                                                    <td>
                                                        ฿{
                                                            parseFloat(String(element.total_deposit_amount)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                        }
                                                    </td>
                                                    <td>
                                                        ฿{
                                                            parseFloat(String(element.total_withdraw_amount)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                        }
                                                    </td>
                                                    <td>
                                                        ฿{
                                                            (parseFloat(String(element.total_deposit_amount)) - parseFloat(String(element.total_withdraw_amount))).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr>
                                            <td colSpan={6}>
                                                <Typography align="center" fontSize={`0.95rem`}>
                                                    ไม่มีข้อมูล
                                                </Typography>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;