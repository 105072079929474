import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive'
// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

interface ShipmentConfig {
  series: {
    name: string;
    type: 'bar' | 'line';
    data: number[];
  }[];
}

const shipmentConfig: ShipmentConfig = {
  series: [
    {
      name: 'ยอดฝาก',
      type: 'bar',
      data: [38, 45, 33, 38, 32, 50, 48, 40, 42, 37, 38, 45, 33, 38, 32, 50, 48, 40, 42, 37, 38, 45, 33, 100],
    },
    {
      name: 'ยอดถอน',
      type: 'bar',
      data: [12, 10, 9, 8, 7, 5, 12, 15, 20, 15],
    }
  ],
};

interface PropsType {
  hourData: string[],
  depositCharData: number[],
  withdrawChartData: number[]
}

const DepositWithdrawChart = ({ hourData, depositCharData, withdrawChartData }: PropsType) => {
  const isDesktopOrLaptop = useMediaQuery({
      query: '(min-width: 1224px)'
  });

  const barData: ChartData<'bar'> = {
    labels: hourData,
    datasets: [
      {
        label: 'ยอดฝาก',
        data: depositCharData,
        backgroundColor: 'rgba(253, 172, 65, .9)',
        borderColor: 'rgba(255, 255, 255, 0)',
        borderRadius: 10,
        barThickness: isDesktopOrLaptop ? 12 : 5,
      },
      {
        label: 'ยอดถอน',
        data: withdrawChartData,
        backgroundColor: 'rgba(253, 0, 0, .7)',
        borderColor: 'rgba(255, 255, 255, 0)',
        borderRadius: 10,
        barThickness: isDesktopOrLaptop ? 12 : 5,
      }
    ],
  };

  const options: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: localStorage.getItem('theme') === 'dark' ? '#fff' : '#000',
          font: {
            size: 11,
            // family: 'IBM Plex Sans',
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (val) {
            return val;
          },
          color: localStorage.getItem('theme') === 'dark' ? '#fff' : '#000',
          font: {
            size: 11,
            // family: 'IBM Plex Sans',
          },
        },
        min: Math.min(...depositCharData) <= Math.min(...withdrawChartData) ? Math.min(...depositCharData) : Math.min(...withdrawChartData),
        max: Math.max(...depositCharData) >= Math.max(...withdrawChartData) ? Math.max(...depositCharData) : Math.max(...withdrawChartData),
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 15,
            // family: 'IBM Plex Sans',
          },
          color: localStorage.getItem('theme') === 'dark' ? '#fff' : '#000',
        },
      },
    },
  };

  return (
    <>
      <Bar data={barData} options={options as ChartOptions<'bar'>} />
    </>
  );
};

export default DepositWithdrawChart;
