import { Box, Typography, Grid, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { memberType, memberScbTransactionDepositType } from "../../types";
import AsyncSelect from 'react-select/async';
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

interface PropsType {
    matchTransasction: memberScbTransactionDepositType | null,
    matchOpen: boolean,
    setMatchOpen: (state: boolean) => void,
    reloadTransaction: () => void
}

interface optionsType {
    value: number;
    label: string;
}

const DepositScbMatch = ({ matchTransasction, matchOpen, setMatchOpen, reloadTransaction }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [selectMemberId, setSelectMemberId] = useState<number | null>(null);
    const [memberData, setMemberData] = useState<memberType | null>(null);

    useEffect(() => {
        if(!selectMemberId)
        {
            setMemberData(null);
            return;
        }

        (async () => {
            try {
                const service = new ApiService();
                const getMember = await service.getMemberById(cookies.get('access_token'), selectMemberId);

                if(getMember.data.status_code !== 200)
                {
                    return Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: getMember.data.message || "ไม่ทราบข้อผิดพลาด"
                    });
                }

                setMemberData(getMember.data.member);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [selectMemberId]);

    useEffect(() => {
        if(!matchOpen)
        {
            setSelectMemberId(null);
            setMemberData(null);
        }
    }, [matchOpen]);

    const handleOnChange = (option: optionsType | null) => {
        if(!option) {
            return;
        }

        setSelectMemberId(option.value)
    }

    const getMember = async (search: string) => {
        try {
            const service = new ApiService();
            const getMembers = (await service.searchMembersForMatch(cookies.get('access_token'), search)).data;

            if(getMembers.status_code === 200 && getMembers.message === 'success' && getMembers.members)
            {
                return getMembers.members.slice(0, getMembers.members.length > 50 ? 50 : getMembers.members.length);
            }
            else
            {
                return [];
            }
        } catch (err) {
            console.log(err);
            return [];
        }
    }

    const promiseOptions = (inputValue: string) => {
        return new Promise<optionsType[]>(async (resolve) => {
            const search = await getMember(inputValue);
            resolve(search);
        });
    }

    const handleMatch = async () => {
        if(!selectMemberId || !matchTransasction)
        {
            return;
        }

        try {
            const service = new ApiService();
            const rematchResult = (await service.rematchScbTransaction(cookies.get('access_token'), matchTransasction, selectMemberId)).data;

            reloadTransaction();
            setMatchOpen(false);
            if(rematchResult.status_code === 200)
            {
                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'ดำเนินการเรียบร้อยแล้ว'
                });
            }

            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `(${rematchResult.status_code}) ${rematchResult.message}` || "ไม่ทราบข้อผิดพลาด"
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Box sx={
                {
                    display: 'block',
                    maxWidth: {
                        xs: `90%`,
                        sm: `90%`,
                        md: `40%`,
                        lg: `30%`
                    },
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: matchOpen ? 0 : -1000,
                    position: 'fixed',
                    backgroundColor: localStorage.getItem('theme') === 'light' ? 'white' : '#1c222f',
                    zIndex: 10000000,
                    color: 'black',
                    transition: 'all .3s',
                    borderLeft: 'solid 3px #e0e3e6'
                }
            }>
                <Box p={2}>
                    {
                        matchTransasction ? <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography fontSize={18} align="center" sx={
                                {
                                    color: localStorage.getItem('theme') === 'light' ? 'black' : '#a1b0cb'
                                }
                            }>
                                    MATCH รายการโอน
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <AsyncSelect cacheOptions loadOptions={promiseOptions} defaultOptions onChange={handleOnChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className="border-top">
                                            <tr>
                                                <th align="center" colSpan={2}>
                                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                        รายละเอียด
                                                    </Typography>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        เพิ่ม (เครดิต)
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        {
                                                            parseFloat(String(matchTransasction.amount)).toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })
                                                        }
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    memberData !== null && <div className="table-responsive">
                                        <table className="table">
                                            <thead className="border-top">
                                                <tr>
                                                    <th align="center" colSpan={2}>
                                                        <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                            ข้อมูลผู้ใช้
                                                        </Typography>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            ชื่อผู้ใช้
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                memberData.ufa_username
                                                            }
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            เครดิต
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                parseFloat(String(memberData.credit)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })
                                                            }
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            ชื่อ-นามสกุล
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                memberData.first_name
                                                            }
                                                            &nbsp;
                                                            {
                                                                memberData.last_name
                                                            }
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            เบอร์โทร
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                memberData.phone
                                                            }
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            ธนาคาร
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                memberData.bank
                                                            }
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            เลขที่บัญชี
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                memberData.bank_number
                                                            }
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <button className="btn btn-primary d-grid w-100" type="button" onClick={() => handleMatch()}>ยืนยัน</button>
                            </Grid>
                        </Grid> : <Typography>
                            NOT FOUND TRANSACTION.
                        </Typography>
                    }
                </Box>
            </Box>
            <Box sx={
                {
                    display: matchOpen ? 'block' : 'none',
                    maxWidth: `100%`,
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, .7)',
                    zIndex: 10000000 - 1,
                    transition: 'all .5s'
                }
            } onClick={() => setMatchOpen(false)} />
        </>
    )
}

export default DepositScbMatch;