import { memberInfoType } from "../../types";


interface propsType {
    memberInfo: memberInfoType | null;
}

const MemberTransactionPayment = ({ memberInfo }: propsType) => {

    return (
        <></>
    )
}

export default MemberTransactionPayment;