import { useEffect, useState, ChangeEvent, useRef, FormEvent } from "react";
import { allReportType, adminPermissionsType } from '../../types';
import { ApiService } from '../../services/api.service';
import Cookies from "universal-cookie";
import { Typography, Pagination } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const formatDate = (date: Dayjs | null) => {
    if (!date) return '';
    return date.format('DD-MM-YYYY');
};

const ReportData = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<allReportType[]>([]);
    const [valueStartDate, setValueStartDate] = useState<Dayjs | null>(dayjs());
    const [valueEndDate, setValueEndDate] = useState<Dayjs | null>(dayjs());

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        reloadData();
    }, []);

    const reloadData = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const start_date = formatDate(valueStartDate);
            const end_date = formatDate(valueEndDate);

            const service = new ApiService();
            const getReport = await service.getReportAllData(cookies.get('access_token'), start_date, end_date);
            
            if(getReport.data.status_code === 200)
            {
                setReportData(getReport.data.report);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.report_all ? <>
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row">
                                <div className="col mb-2">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={valueStartDate}
                                            onChange={(newValue) => setValueStartDate(newValue)}
                                            sx={
                                                {
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        height: '40px', // ความสูงของ Input Base
                                                        fontSize: 14,
                                                    },
                                                    '& .MuiPickersPopper-container': {
                                                        maxHeight: '40px', // ความสูงสูงสุดของ Popper
                                                    },
                                                }
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                                popper: {
                                                  disablePortal: true,
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                            defaultValue={dayjs()}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col mb-2">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={valueEndDate}
                                            onChange={(newValue) => setValueEndDate(newValue)}
                                            sx={
                                                {
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        height: '40px', // ความสูงของ Input Base
                                                        fontSize: 14,
                                                    },
                                                    '& .MuiPickersPopper-container': {
                                                        maxHeight: '40px', // ความสูงสูงสุดของ Popper
                                                    },
                                                }
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                                popper: {
                                                  disablePortal: true,
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                            defaultValue={dayjs()}
                                            minDate={valueStartDate || dayjs()}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-sm-12 col-md-2">
                                    <button className="btn btn-primary w-100" onClick={reloadData} disabled={loading}>ค้นหา</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-datatable table-responsive">
                            <table className="datatables-users table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">เจ้าของ</th>
                                        <th align="center">มาจาก</th>
                                        <th align="center">สมาชิกใหม่</th>
                                        <th align="center">สมัครฝาก</th>
                                        <th align="center">ยอดฝากสมาชิกใหม่</th>
                                        <th align="center">ยอดฝากสมาชิกเก่า</th>
                                        <th align="center">ยอดฝากรวม</th>
                                        <th align="center">ยอดถอนรวม</th>
                                        <th align="center">คงเหลือ</th>
                                        <th align="center">จำนวนครั้งที่ฝาก</th>
                                        <th align="center">จำนวนครั้งที่ถอน</th>
                                        <th align="center">จำนวนคนที่ฝาก</th>
                                        <th align="center">จำนวนคนที่ถอน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportData && reportData.length > 0 ? <>
                                            {
                                                reportData.map((element: allReportType, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {
                                                                    element.reg_by
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.referrer.length > 30 ? `${element.referrer.slice(0, 30)}...` : element.referrer
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    parseFloat(String(element.new_member)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    parseFloat(String(element.new_member_total_deposit)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                }
                                                            </td>
                                                            <td>
                                                                ฿{
                                                                    parseFloat(String(
                                                                        parseFloat(String(element.scb_new_deposit_amount)) +
                                                                        parseFloat(String(element.zpay_new_deposit_amount)) +
                                                                        parseFloat(String(element.se_new_deposit_amount)) +
                                                                        parseFloat(String(element.bullpay_new_deposit_amount))
                                                                    )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                }
                                                            </td>
                                                            <td>
                                                                ฿{
                                                                    parseFloat(String(
                                                                        parseFloat(String(element.scb_old_deposit_amount)) +
                                                                        parseFloat(String(element.zpay_old_deposit_amount)) +
                                                                        parseFloat(String(element.se_old_deposit_amount)) +
                                                                        parseFloat(String(element.bullpay_old_deposit_amount))
                                                                    )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                }
                                                            </td>
                                                            <td>
                                                                ฿{
                                                                    parseFloat(String(
                                                                        parseFloat(String(element.scb_new_deposit_amount)) +
                                                                        parseFloat(String(element.zpay_new_deposit_amount)) +
                                                                        parseFloat(String(element.se_new_deposit_amount)) +
                                                                        parseFloat(String(element.bullpay_new_deposit_amount)) +
                                                                        parseFloat(String(element.scb_old_deposit_amount)) +
                                                                        parseFloat(String(element.zpay_old_deposit_amount)) +
                                                                        parseFloat(String(element.se_old_deposit_amount)) +
                                                                        parseFloat(String(element.bullpay_old_deposit_amount))
                                                                    )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                }
                                                            </td>
                                                            <td>
                                                                ฿{
                                                                    parseFloat(String(
                                                                        parseFloat(String(element.zpay_withdraw_amount_total)) +
                                                                        parseFloat(String(element.se_withdraw_amount_total)) +
                                                                        parseFloat(String(element.bullpay_withdraw_amount_total))
                                                                    )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                }
                                                            </td>
                                                            <td>
                                                                ฿{
                                                                    (
                                                                        parseFloat(String(
                                                                            parseFloat(String(element.scb_new_deposit_amount)) +
                                                                            parseFloat(String(element.zpay_new_deposit_amount)) +
                                                                            parseFloat(String(element.se_new_deposit_amount)) +
                                                                            parseFloat(String(element.bullpay_new_deposit_amount)) +
                                                                            parseFloat(String(element.scb_old_deposit_amount)) +
                                                                            parseFloat(String(element.zpay_old_deposit_amount)) +
                                                                            parseFloat(String(element.se_old_deposit_amount)) +
                                                                            parseFloat(String(element.bullpay_old_deposit_amount))
                                                                        ))
                                                                        -
                                                                        parseFloat(String(
                                                                            parseFloat(String(element.zpay_withdraw_amount_total)) +
                                                                            parseFloat(String(element.se_withdraw_amount_total)) +
                                                                            parseFloat(String(element.bullpay_withdraw_amount_total))
                                                                        ))
                                                                    ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    parseFloat(String(element.total_deposit_time_count)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    parseFloat(String(element.total_withdraw_time_count)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    parseFloat(String(element.total_count_deposit)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    parseFloat(String(element.total_count_withdraw)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td colSpan={2}>
                                                    สรุป
                                                </td>
                                                <td>
                                                    {
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + currentValue.new_member;
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + parseInt(String(currentValue.new_member_total_deposit));
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                    }
                                                </td>
                                                <td>
                                                    ฿{
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + parseFloat(String(currentValue.scb_new_deposit_amount)) +
                                                                parseFloat(String(currentValue.zpay_new_deposit_amount)) +
                                                                parseFloat(String(currentValue.se_new_deposit_amount)) +
                                                                parseFloat(String(currentValue.bullpay_new_deposit_amount));
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }
                                                </td>
                                                <td>
                                                    ฿{
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + parseFloat(String(currentValue.scb_old_deposit_amount)) +
                                                                parseFloat(String(currentValue.zpay_old_deposit_amount)) +
                                                                parseFloat(String(currentValue.se_old_deposit_amount)) +
                                                                parseFloat(String(currentValue.bullpay_old_deposit_amount)
                                                            );
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }
                                                </td>
                                                <td>
                                                    ฿{
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + parseFloat(String(
                                                                parseFloat(String(currentValue.scb_new_deposit_amount)) +
                                                                parseFloat(String(currentValue.zpay_new_deposit_amount)) +
                                                                parseFloat(String(currentValue.se_new_deposit_amount)) +
                                                                parseFloat(String(currentValue.bullpay_new_deposit_amount)) +
                                                                parseFloat(String(currentValue.scb_old_deposit_amount)) +
                                                                parseFloat(String(currentValue.zpay_old_deposit_amount)) +
                                                                parseFloat(String(currentValue.bullpay_old_deposit_amount)) +
                                                                parseFloat(String(currentValue.se_old_deposit_amount))
                                                            ));
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }
                                                </td>
                                                <td>
                                                    ฿{
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + (
                                                                parseFloat(String(
                                                                    currentValue.zpay_withdraw_amount_total
                                                                    )
                                                                )
                                                                +
                                                                parseFloat(String(
                                                                    currentValue.se_withdraw_amount_total
                                                                    )
                                                                )
                                                                +
                                                                parseFloat(String(
                                                                    currentValue.bullpay_withdraw_amount_total
                                                                    )
                                                                )
                                                            );
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }
                                                </td>
                                                <td>
                                                    ฿{
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + (
                                                                parseFloat(String(
                                                                    parseFloat(String(currentValue.scb_new_deposit_amount)) +
                                                                    parseFloat(String(currentValue.zpay_new_deposit_amount)) +
                                                                    parseFloat(String(currentValue.se_new_deposit_amount)) +
                                                                    parseFloat(String(currentValue.bullpay_new_deposit_amount)) +
                                                                    parseFloat(String(currentValue.scb_old_deposit_amount)) +
                                                                    parseFloat(String(currentValue.zpay_old_deposit_amount)) +
                                                                    parseFloat(String(currentValue.bullpay_old_deposit_amount)) +
                                                                    parseFloat(String(currentValue.se_old_deposit_amount)) 
                                                                ))
                                                                -
                                                                (
                                                                    parseFloat(String(
                                                                        currentValue.zpay_withdraw_amount_total
                                                                    ))
                                                                    +
                                                                    parseFloat(String(
                                                                        currentValue.se_withdraw_amount_total
                                                                    ))
                                                                    +
                                                                    parseFloat(String(
                                                                        currentValue.bullpay_withdraw_amount_total
                                                                    ))
                                                                )
                                                            );
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + parseInt(String(currentValue.total_deposit_time_count));
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + parseInt(String(currentValue.total_withdraw_time_count));
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + currentValue.total_count_deposit;
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        reportData.reduce((accumulator: number, currentValue: allReportType) => {
                                                            return accumulator + currentValue.total_count_withdraw;
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                    }
                                                </td>
                                            </tr>
                                        </> :
                                            <tr>
                                                <td colSpan={13}>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        ไม่พบข้อมูล
                                                    </Typography>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default ReportData;