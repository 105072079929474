import { ApiService } from "../../services/api.service";
import { adminPermissionsType, promotionsType } from "../../types";
import Cookies from "universal-cookie";
import { useEffect, useState, ChangeEvent } from "react";
import { Typography, Pagination } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { Link } from "react-router-dom";
import CreatePromotionTab from "./CreatePromotionTab";
import Img300 from './../../assets/images/300.svg';
import { useOutletContext } from "../../OutletContext";
import Tooltip from '@mui/material/Tooltip';
import Swal from "sweetalert2";

const PromotionList = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [countPromotions, setCountPromotions] = useState<number | null>(null);
    const [promotions, setPromotions] = useState<promotionsType[]>([]);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [tabOpen, setTabOpen] = useState<boolean>(false);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        getPromotion();
    }, [page, limit]);

    const getPromotion = async () => {
        if(loading)
        {
            return;
        }

        try {
            setLoading(true);
            const service = new ApiService();
            const getPromotions = await service.getPromotions(cookies.get('access_token'));

            if(getPromotions.data.status_code === 200)
            {
                setCountPromotions(getPromotions.data.countPromotions)
                setPromotions(getPromotions.data.promotions);
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangePage = (_e: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleToggleShow = async (promotion_id: number, toggle_number: number) => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const togglePromotion = await service.toggleShowPromotion(cookies.get('access_token'), promotion_id, toggle_number);
            
            if(togglePromotion.data.status_code === 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'อัพเดทเรียบร้อยแล้วค่ะ'
                }).then((res) => {
                    if(res)
                    {
                        getPromotion();
                    }
                });
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด (${togglePromotion.data.status_code} - ${togglePromotion.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.promotion ? <>
                    <CreatePromotionTab setTabOpen={setTabOpen} tabOpen={tabOpen} />
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row">
                                <div className="col-sm-6 col-xl-6" style={{ display: `flex`, alignContent: `center`, alignItems: `center` }}>
                                    <button className="btn btn-primary d-grid" onClick={() => setTabOpen(!tabOpen)}>เพิ่มโปรโมชั่น</button>
                                </div>
                                <div className="col-sm-6 col-xl-6" style={{ 
                                    display: `flex`,
                                    justifyContent: `flex-end`,
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    แสดง:&nbsp;
                                    <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                        style={{
                                            maxHeight: 40,
                                            maxWidth: 100
                                        }}
                                        defaultValue={`10`}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            if(!e.target)
                                            {
                                                return;
                                            }

                                            setLimit(parseInt(e.target.value));
                                        }}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="70">70</option>
                                        <option value="80">80</option>
                                        <option value="90">90</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <Pagination
                                        count={Math.ceil((countPromotions === null ? 0 : countPromotions) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="table-responsive">
                            <table className="table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                #
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                                รูปภาพ
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ชื่อโปรโมชั่น
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                                จำนวนโบนัส
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ประเภท
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                จัดการ
                                            </Typography>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        promotions.length > 0 ? promotions.map((element: promotionsType, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                element.id
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <img src={`${process.env.REACT_APP_API_URL}/image/${element.poster_image}`} style={{ maxWidth: 60 }} />
                                                    </td>
                                                    <td>
                                                        <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                            {
                                                                element.name
                                                            }
                                                        </Typography>
                                                        <div style={{ fontSize: 10 }} dangerouslySetInnerHTML={{ __html: element.html }} />
                                                    </td>
                                                    <td>
                                                        <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                            {
                                                                element.type_plus_credit == 1 ? `฿${element.plus_credit}` : `${element.plus_credit} %`
                                                            }
                                                        </Typography>
                                                        <Typography fontSize={`0.95rem`} >
                                                            โบนัสสูงสุด {
                                                                `${element.max_credit_per_person_per_day}`
                                                            } (ต่อวัน)
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                element.type === 2 ? 'โปรโมชั่น - ฝาก' : "ไม่ทราบ"
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Tooltip title={element.deleted === 0 ? "ซ่อนโปรโมชั่น" : "โชว์โปรโมชั่น"}>
                                                            <Typography align="center" fontSize={`0.95rem`} sx={{
                                                                color: '#5f91ee',
                                                                cursor: 'pointer',
                                                                transition: 'all .2s',
                                                                '&:hover': {
                                                                    color: '#7ba4f1'
                                                                }
                                                            }} onClick={() => {
                                                                handleToggleShow(element.id, element.deleted === 0 ? 1 : 0)
                                                            }}>
                                                                <i className={`bx bx-${element.deleted === 0 ? "hide" : "show"}`}></i>
                                                            </Typography>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr>
                                            <td colSpan={5}>
                                                <Typography align="center" fontSize={`0.95rem`}>
                                                    ไม่พบข้อมูล
                                                </Typography>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <Pagination
                                        count={Math.ceil((countPromotions === null ? 0 : countPromotions) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default PromotionList;