import { useEffect, useState, ChangeEvent, useRef, FormEvent } from "react";
import { memberType, dashboardMemberPageType, adminPermissionsType } from '../../types';
import { ApiService } from '../../services/api.service';
import Cookies from "universal-cookie";
import MembersTableBody from "./MembersTableBody";
import { Typography, Pagination } from "@mui/material";
import EditMember from "./EditMember";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";
import { styled } from '@mui/system';

const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "rgba(255, 255, 255, .7)", // สีของหน้าที่ไม่ได้เลือก
    },
    '& .Mui-selected': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "white",
    },
});

const Members = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [members, setMembers] = useState<memberType[]>([]);
    const [countAllMembers, setCountAllMembers] = useState<number | null>(null);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const searchRef = useRef<HTMLInputElement>(null);
    const [selectMemberId, setSelectMemberId] = useState<number | null>(null);
    const [tabOpen, setTabOpen] = useState<boolean>(false);
    const [dashboardMemberData, setDashboardMemberData] = useState<dashboardMemberPageType | null>(null);

    const calculatePercent = (new_value: number, old_value: number) => {
        const diff = new_value - old_value;
        const percent = (diff / old_value) * 100;

        return `${percent >= 0 ? '+' : ''}${parseFloat(String(percent)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`;
    }

    const redOrGreen = (new_value: number, old_value: number) => {
        const diff = new_value - old_value;
        const percent = (diff / old_value) * 100;

        return percent >= 0 ? 'success' : 'danger';
    }

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        reloadMember();
    }, [page, limit]);

    useEffect(() => {
        loadDashboard();
    }, []);

    useEffect(() => {
        if(selectMemberId)
        {
            setTabOpen(true);
        }
        else
        {
            setTabOpen(false);
        }
    }, [selectMemberId]);

    useEffect(() => {
        if(!tabOpen)
        {
            setSelectMemberId(null);
        }
    }, [tabOpen]);

    const loadDashboard = async () => {
        try {
            const service = new ApiService();

            const getDashboard = (await service.getMemberDataDetailMemberPage(cookies.get('access_token'))).data;
            setDashboardMemberData(getDashboard.memberData);
        } catch (err) {
            console.log(err);
        }
    }

    const reloadMember = async () => {
        if(loading)
        {
            return;
        }

        try {
            setLoading(true);
            const service = new ApiService();

            const getCountMembers = (await service.countAllMember(cookies.get('access_token'))).data;
            setCountAllMembers(getCountMembers.count);

            const getMembers = (await service.getMembers(cookies.get('access_token'), page, limit)).data;
            if(getMembers.status_code === 200 && getMembers.message === 'success' && getMembers.members)
            {
                setMembers(getMembers.members);
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangePage = (_e: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const searchMember = async (e: FormEvent) => {
        e.preventDefault();
        if(loading || !searchRef || !searchRef.current || searchRef.current.value.trim() === '')
        {
            return;
        }

        try {
            setLoading(true);
            const service = new ApiService();

            const getMembers = (await service.searchMembers(cookies.get('access_token'), searchRef.current.value.trim())).data;
            if(getMembers.status_code === 200 && getMembers.message === 'success' && getMembers.members)
            {
                setMembers(getMembers.members);
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.member ? <>
                    <EditMember tabOpen={tabOpen} setTabOpen={setTabOpen} member_id={selectMemberId} />
                    {
                        dashboardMemberData !== null && <div className="row g-3 mb-3">
                            <div className="col-sm-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div className="content-left">
                                            <span>สมาชิกทั้งหมด</span>
                                            <div className="d-flex align-items-end mt-2">
                                                <h4 className="mb-0 me-2">
                                                    {
                                                        parseFloat(String(dashboardMemberData.count_member || 0)).toLocaleString('en-US', {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })
                                                    }
                                                </h4>
                                                <small className={`text-${redOrGreen(dashboardMemberData.register_this_week, dashboardMemberData.register_last_week)} mb-0`}>({calculatePercent(dashboardMemberData.register_this_week, dashboardMemberData.register_last_week)})</small>
                                            </div>
                                            <small>เปรียบเทียบข้อมูลจากสัปดาห์ก่อน</small>
                                        </div>
                                        <div className="avatar">
                                        <span className="avatar-initial rounded bg-label-primary">
                                            <i className="bx bx-user bx-sm" />
                                        </span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div className="content-left">
                                            <span>สมัครฝากสัปดาห์นี้</span>
                                            <div className="d-flex align-items-end mt-2">
                                                <h4 className="mb-0 me-2">
                                                    {
                                                        parseFloat(String(dashboardMemberData.count_reg_and_deposit_this_week_scb || 0)).toLocaleString('en-US', {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })
                                                    }
                                                </h4>
                                                <small className={`text-${redOrGreen(dashboardMemberData.count_reg_and_deposit_this_week_scb, dashboardMemberData.count_reg_and_deposit_last_week_scb)} mb-0`}>({calculatePercent(dashboardMemberData.count_reg_and_deposit_this_week_scb, dashboardMemberData.count_reg_and_deposit_last_week_scb)})</small>
                                            </div>
                                            <small>เปรียบเทียบข้อมูลจากสัปดาห์ก่อน</small>
                                        </div>
                                        <div className="avatar">
                                        <span className="avatar-initial rounded bg-label-danger">
                                            <i className="bx bx-user-check bx-sm" />
                                        </span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="content-left">
                                                <span>เข้าสู่ระบบ 7 วันล่าสุด</span>
                                                <div className="d-flex align-items-end mt-2">
                                                    <h4 className="mb-0 me-2">
                                                        {
                                                            parseFloat(String(dashboardMemberData.count_login_this_week || 0)).toLocaleString('en-US', {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0
                                                            })
                                                        }
                                                    </h4>
                                                    <small className={`text-${redOrGreen(dashboardMemberData.count_login_this_week, dashboardMemberData.count_login_last_week)} mb-0`}>({calculatePercent(dashboardMemberData.count_login_this_week, dashboardMemberData.count_login_last_week)})</small>
                                                </div>
                                                <small>เปรียบเทียบข้อมูลจากสัปดาห์ก่อน</small>
                                            </div>
                                            <div className="avatar">
                                            <span className="avatar-initial rounded bg-label-success">
                                                <i className="bx bx-group bx-sm" />
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div className="content-left">
                                            <span>สมัครไม่ฝาก</span>
                                            <div className="d-flex align-items-end mt-2">
                                                <h4 className="mb-0 me-2">
                                                    {
                                                        parseFloat(String(dashboardMemberData.count_reg_and_not_deposit_this_week_scb || 0)).toLocaleString('en-US', {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })
                                                    }
                                                </h4>
                                                <small className={`text-${redOrGreen(dashboardMemberData.count_reg_and_not_deposit_this_week_scb, dashboardMemberData.count_reg_and_not_deposit_last_week_scb)} mb-0`}>({calculatePercent(dashboardMemberData.count_reg_and_not_deposit_this_week_scb, dashboardMemberData.count_reg_and_not_deposit_last_week_scb)})</small>
                                            </div>
                                            <small>เปรียบเทียบข้อมูลจากสัปดาห์ก่อน</small>
                                        </div>
                                        <div className="avatar">
                                        <span className="avatar-initial rounded bg-label-warning">
                                            <i className="bx bx-user-voice bx-sm" />
                                        </span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-4">
                                    <form id="formSearchMember" className="mb-3" onSubmit={searchMember}>
                                        <div className="row">
                                            <div className="col-sm-12 col-xl-6 mt-3">
                                                <input type="text" className="form-control" id="search_input" name="search_input"
                                                    placeholder="Search..." ref={searchRef} />
                                            </div>
                                            <div className="col-sm-12 col-xl-4 mt-3">
                                                <button className="btn btn-primary d-grid w-100" type="submit">ค้นหา</button>
                                            </div>
                                            <div className="col-sm-12 col-xl-2 mt-3">
                                                <button className="btn btn-danger d-grid w-100" type="button" onClick={() => {
                                                    const element = document.getElementById('search_input') as HTMLInputElement;
                                                    if(element)
                                                    {
                                                        element.value = '';
                                                        reloadMember();
                                                    }
                                                }}>
                                                    <i className="bx bx-x"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-sm-12 col-xl-8" style={{ 
                                    display: `flex`,
                                    justifyContent: `flex-end`,
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    แสดง:&nbsp;
                                    <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                        style={{
                                            maxHeight: 40,
                                            maxWidth: 100
                                        }}
                                        defaultValue={`10`}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            if(!e.target)
                                            {
                                                return;
                                            }

                                            setLimit(parseInt(e.target.value));
                                        }}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="70">70</option>
                                        <option value="80">80</option>
                                        <option value="90">90</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="card py-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-6">
                                    <CustomPagination
                                        count={Math.ceil((countAllMembers === null ? 0 : countAllMembers) / limit)}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                                <div className="col-sm-12 col-xl-6" style={{ display: `flex`, justifyContent: `flex-end` }}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-datatable table-responsive">
                            <table className="datatables-users table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">#</th>
                                        <td align="center">ขื่อ-สกุล</td>
                                        <td align="center">ชื่อผู้ใช้</td>
                                        <td align="center">บัญชีธนาคาร</td>
                                        <td align="center">เครดิต</td>
                                        <td align="center">สถานะ</td>
                                        <td align="center">รายละเอียด</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        members.length > 0 ? members.sort((a: memberType,b: memberType) => b.id - a.id).map((member: memberType, index: number) => {
                                            return (
                                                <MembersTableBody key={`member-${member.id}-${index}`} member={member} reloadMember={reloadMember} setSelectMember={setSelectMemberId} />
                                            )
                                        }) :
                                            <tr>
                                                <td colSpan={12}>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        ไม่พบข้อมูล
                                                    </Typography>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card py-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-6">
                                    <CustomPagination
                                        count={Math.ceil((countAllMembers === null ? 0 : countAllMembers) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                                <div className="col-sm-12 col-xl-6" style={{ display: `flex`, justifyContent: `flex-end` }}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default Members;