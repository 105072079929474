import { ApiService } from "../../services/api.service";
import { memberScbTransactionDepositType, memberInfoType } from "../../types";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { Grid, Box, Paper, Typography, Divider, TableContainer, Table, TableRow, TableBody, TableCell, TableHead } from "@mui/material";


interface propsType {
    memberInfo: memberInfoType | null;
}

const MemberTransactionSCB = ({ memberInfo }: propsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [transactions, setTransactions] = useState<memberScbTransactionDepositType[]>([]);

    useEffect(() => {
        getAllSCBTransactions();
    }, []);

    const getAllSCBTransactions = async () => {
        if(memberInfo === null)
        {
            return;
        }

        try {
            const service = new ApiService();
            const getTransactions = await service.getDepositTransactionsScbByMemberId(cookies.get('access_token'), memberInfo.id);
            if(getTransactions.data.status_code !== 200)
            {
                return;
            }

            setTransactions(getTransactions.data.transaction);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ backgroundColor: '#181818' }}>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#000000' }}>
                            <TableRow>
                                {/* <TableCell align="center">
                                    <Typography color={`white`}>
                                        #
                                    </Typography>
                                </TableCell> */}
                                <TableCell align="center">
                                    <Typography color={`white`}>
                                        ชื่อผู้ใช้งาน UFA
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography color={`white`}>
                                        บัญชีที่โอนเข้า
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" colSpan={3}>
                                    <Typography color={`white`}>
                                        เครดิต
                                    </Typography>
                                </TableCell>
                                {/* <TableCell align="center">
                                    <Typography color={`white`}>
                                        เบอร์โทรศัพท์
                                    </Typography>
                                </TableCell> */}
                                <TableCell align="center">
                                    <Typography color={`white`}>
                                        สถานะเพิ่มเครดิต
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography color={`white`}>
                                        วันเวลาที่โอน
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography color={`white`}>
                                        วันเวลาที่เติมเข้า
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell align="center">
                                    <Typography color={`white`}>
                                        ก่อนเพิ่ม
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography color={`white`}>
                                        เพิ่ม
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography color={`white`}>
                                        หลังเพิ่ม
                                    </Typography>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                transactions.length > 0 ? transactions.sort((a: memberScbTransactionDepositType, b: memberScbTransactionDepositType) => {
                                    const dateA = new Date(`${String(a.date)} ${String(a.time)}`).getTime();
                                    const dateB = new Date(`${String(b.date)} ${String(b.time)}`).getTime();
                                    return dateB - dateA;
                                }).map((transaction: memberScbTransactionDepositType, index: number) => {
                                    return (
                                        <TableRow key={`transaction-${index}`}>
                                            {/* <TableCell align="center">
                                                <Typography color={`white`}>
                                                    {
                                                        transaction.id
                                                    }
                                                </Typography>
                                            </TableCell> */}
                                            {/* <TableCell align="center">
                                                <Typography color={`white`}>
                                                    {
                                                        transaction.ref_code
                                                    }
                                                </Typography>
                                            </TableCell> */}
                                            {/* <TableCell align="center">
                                                <Typography color={`white`}>
                                                    {
                                                        transaction.phone
                                                    }
                                                </Typography>
                                            </TableCell> */}
                                            <TableCell align="center">
                                                <Typography sx={
                                                    {
                                                        color: transaction.ufa_username === null ? "#f4ce5c" : 'white'
                                                    }
                                                }>
                                                    {
                                                        transaction.ufa_username === null ? "กำลังรอ | NOT MATCH" : transaction.ufa_username
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography sx={
                                                    {
                                                        color: 'white'
                                                    }
                                                }>
                                                    {
                                                        transaction.bank_account_name
                                                    }
                                                    &nbsp;(x{transaction.bank_number})
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography color={`white`}>
                                                    {
                                                        transaction.ufa_username !== null ? <>
                                                            {
                                                                transaction.get_allCredit
                                                            }
                                                        </> : '0'
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography color={transaction.ufa_username !== null ? "#05c855" : "#f4ce5c"}>
                                                    +{
                                                        transaction.amount
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography color={`white`}>
                                                    {
                                                        transaction.get_newCredit
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography sx={
                                                    {
                                                        color: transaction.ufa_username === null ? "#f4ce5c" : "#05c855"
                                                    }
                                                }>
                                                    {
                                                        transaction.ufa_username === null ? "กำลังรอ | NOT MATCH" : "สำเร็จ"
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography color={`white`}>
                                                    {
                                                        `${String(new Date(String(transaction.date)).getDate())}/${String(new Date(String(transaction.date)).getMonth())}/${String(new Date(String(transaction.date)).getFullYear())}`
                                                    }
                                                    &nbsp;
                                                    {
                                                        `${String(transaction.time)}`
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography sx={
                                                    {
                                                        color: transaction.updatedAt === null ? "#f4ce5c" : "#05c855"
                                                    }
                                                }>
                                                    {
                                                        transaction.updatedAt !== null ? <>
                                                            {
                                                                `${String(new Date(String(transaction.updatedAt)).getDate())}/${String(new Date(String(transaction.updatedAt)).getMonth())}/${String(new Date(String(transaction.updatedAt)).getFullYear())}`
                                                            }
                                                            &nbsp;
                                                            {
                                                                `${String(new Date(String(transaction.updatedAt)).getHours())}:${String(new Date(String(transaction.updatedAt)).getMinutes())}`
                                                            }
                                                        </> : "กำลังรอ | NOT MATCH"
                                                    }
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) : <TableRow>
                                    <TableCell align="center" colSpan={9}>
                                        <Typography color={`white`}>
                                            ไม่พบข้อมูล
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default MemberTransactionSCB;