import { Box, Typography } from "@mui/material";
import { useState, useRef, ChangeEvent } from "react";
import { checkedEnableNumberType, createFirstLottoType } from "../../types";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import Img300 from './../../assets/images/300.svg';

interface PropsType {
    tabOpen: boolean,
    setTabOpen: (state: boolean) => void,
    reload: () => void
}

const CreateLottoTab = ({ tabOpen, setTabOpen, reload }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [creating, setCreating] = useState<boolean>(false);
    const lottoNameRef = useRef<HTMLInputElement>(null);
    const sixNumberRateRef = useRef<HTMLInputElement>(null);
    const firstThreeNumberRateRef = useRef<HTMLInputElement>(null);
    const threeNumberTodRateRef = useRef<HTMLInputElement>(null);
    const lastThreeNumberRateRef = useRef<HTMLInputElement>(null);
    const lastThreeNumberTwoRateRef = useRef<HTMLInputElement>(null);
    const lastTwoNumberUpperRateRef = useRef<HTMLInputElement>(null);
    const lastTwoNumberLowwerRateRef = useRef<HTMLInputElement>(null);
    const runUpperRateRef = useRef<HTMLInputElement>(null);
    const runLowwerRateRef = useRef<HTMLInputElement>(null);
    const minBetRef = useRef<HTMLInputElement>(null);
    const maxBetRef = useRef<HTMLInputElement>(null);

    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);

    const [checkedEnableNumber, setCheckedEnableNumber] = useState<checkedEnableNumberType>({
        six_number: false,
        first_three_number: false,
        last_three_number: false,
        last_two_number: false
    });

    const handleCheckedEnableNumber = (check: string) => {
        if(typeof(check) !== 'string')
        {
            return;
        }

        const tmp_checked: checkedEnableNumberType = {
            ...checkedEnableNumber
        };

        if(check === 'six_number')
        {
            tmp_checked.six_number = !tmp_checked.six_number
        }
        else if(check === 'first_three_number')
        {
            tmp_checked.first_three_number = !tmp_checked.first_three_number
        }
        else if(check === 'last_three_number')
        {
            tmp_checked.last_three_number = !tmp_checked.last_three_number
        }
        else if(check === 'last_two_number')
        {
            tmp_checked.last_two_number = !tmp_checked.last_two_number
        }

        setCheckedEnableNumber(tmp_checked);
    }

    const handleCreateLotto = async () => {
        if(creating)
        {
            return;
        }
        else if(
            !lottoNameRef.current || 
            !sixNumberRateRef.current || 
            !firstThreeNumberRateRef.current || 
            !lastThreeNumberRateRef.current || 
            !lastThreeNumberTwoRateRef.current || 
            !lastTwoNumberUpperRateRef.current ||
            !lastTwoNumberLowwerRateRef.current ||
            !threeNumberTodRateRef.current ||
            !runUpperRateRef.current ||
            !runLowwerRateRef.current ||
            !minBetRef.current ||
            !maxBetRef.current
        )
        {
            return;
        }
        else if(
            lottoNameRef.current.value.trim() === '' ||
            sixNumberRateRef.current.value.trim() === '' ||
            firstThreeNumberRateRef.current.value.trim() === '' ||
            lastThreeNumberRateRef.current.value.trim() === '' ||
            lastThreeNumberTwoRateRef.current.value.trim() === '' ||
            lastTwoNumberUpperRateRef.current.value.trim() === '' ||
            lastTwoNumberLowwerRateRef.current.value.trim() === '' ||
            threeNumberTodRateRef.current.value.trim() === '' ||
            runUpperRateRef.current.value.trim() === '' ||
            runLowwerRateRef.current.value.trim() === '' ||
            minBetRef.current.value.trim() === '' ||
            maxBetRef.current.value.trim() === ''
        )
        {
            return;
        }

        setCreating(true);
        try {
            const data: createFirstLottoType = {
                name: lottoNameRef.current.value.trim(),
                payRate: JSON.stringify({
                    six_number: sixNumberRateRef.current.value.trim(),
                    first_three_number: firstThreeNumberRateRef.current.value.trim(),
                    three_tod_number: threeNumberTodRateRef.current.value.trim(),
                    last_three_number: lastThreeNumberRateRef.current.value.trim(),
                    last_three_number_two: lastThreeNumberTwoRateRef.current.value.trim(),
                    last_two_upper_number: lastTwoNumberUpperRateRef.current.value.trim(),
                    last_two_lowwer_number: lastTwoNumberLowwerRateRef.current.value.trim(),
                    run_upper_number: runUpperRateRef.current.value.trim(),
                    run_lowwer_number: runLowwerRateRef.current.value.trim()
                }),
                checkedEnableNumber: JSON.stringify(checkedEnableNumber),
                min_bet: minBetRef.current.value.trim(),
                max_bet: maxBetRef.current.value.trim()
            }

            const formData: FormData = new FormData();
            if (file)
            {
                formData.append('image', file);
            }
            
            for (const key in data)
            {
                formData.append(key, (data as any)[key]);
            }

            const service = new ApiService();
            const create = await service.createLotto(cookies.get('access_token'), formData);

            setTabOpen(false);
            Swal.fire({
                icon: create.data.status_code === 200 ? "success" : "error",
                title: 'แจ้งเตือน',
                text: create.data.status_code === 200 ? "สร้างหวยเรียบร้อยแล้ว" : create.data.message
            });

            reload();
        } catch (err) {
            console.log(err);
        }
        setCreating(false);
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            const previewUrl = URL.createObjectURL(e.target.files[0]);
            setPreview(previewUrl);
        }
        else
        {
            setFile(null);
            setPreview(null);
        }
    };

    return (
        <>
            <Box sx={
                {
                    display: 'block',
                    maxWidth: {
                        xs: `90%`,
                        sm: `90%`,
                        md: `50%`,
                        lg: `30%`
                    },
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: tabOpen ? 0 : -3000,
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: 10000000,
                    color: 'black',
                    transition: 'all .3s',
                    borderLeft: 'solid 3px #e0e3e6',
                    overflowY: 'scroll'
                }
            }>
                <Box p={2}>
                    <div className="row">
                        <div className="col-6">
                            <Typography fontSize={18} fontWeight={`bold`}>
                                สร้างหวย
                            </Typography>
                        </div>
                        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button className="btn btn-primary" onClick={handleCreateLotto} disabled={creating}>สร้าง</button>
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
                            <div className="card p-2">
                                <div className="col-12">
                                    <Typography align="center">
                                        รูปหวย
                                    </Typography>
                                </div>
                                <div className="col-12">
                                    <Box sx={{ display: `flex`, justifyContent: `center`, mt: 1 }}>
                                        <img src={preview ? preview : Img300} alt="300x300" style={{ width: '100%', maxWidth: '250px', maxHeight: '250px' }} />
                                    </Box>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="formFile" className="form-label">เลือกไฟล์รูปภาพ</label>
                                    <input className="form-control" type="file" id="formFile" onChange={handleFileChange} />
                                </div>
                                <div className="col-12 mt-3">
                                    ชื่อหวย
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_name" name="lotto_name" ref={lottoNameRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 6 ตัว
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_sixNumberRateRef" name="lotto_sixNumberRateRef" ref={sixNumberRateRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 3 ตัวบน
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_firstThreeNumberRateRef" name="lotto_firstThreeNumberRateRef" ref={firstThreeNumberRateRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 3 โต๊ด
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_ThreeNumberTodRateRef" name="lotto_ThreeNumberTodRateRef" ref={threeNumberTodRateRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 3 ตัวล่าง (1)
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_lastThreeNumberRateRef" name="lotto_lastThreeNumberRateRef" ref={lastThreeNumberRateRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 3 ตัวล่าง (2)
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_lastThreeNumberTwoRateRef" name="lotto_lastThreeNumberTwoRateRef" ref={lastThreeNumberTwoRateRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 2 ตัวบน
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_lastTwoNumberRateRef" name="lotto_lastTwoNumberRateRef" ref={lastTwoNumberUpperRateRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย 2 ตัวล่าง
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_lastTwoNumberRateRef" name="lotto_lastTwoNumberRateRef" ref={lastTwoNumberLowwerRateRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย วิ่งบน
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_runUpperRateRef" name="lotto_runUpperRateRef" ref={runUpperRateRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    เรทจ่าย วิ่งล่าง
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_runLowwerRateRef" name="lotto_runLowwerRateRef" ref={runLowwerRateRef} />
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <hr/>
                                </div>
                                <div className="col-12 mt-3">
                                    ยอดแทงขั้นต่ำ / เลข
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_minBet" name="lotto_minBet" ref={minBetRef} />
                                </div>
                                <div className="col-12 mt-3">
                                    ยอดแทงสูดสุด / เลข
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="lotto_maxBet" name="lotto_maxBet" ref={maxBetRef} />
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <hr/>
                                </div>
                                {/* <div className="col-sm-12 col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="enable_sixnumber" checked={checkedEnableNumber.six_number} onClick={() => handleCheckedEnableNumber('six_number')} />
                                        <label className="form-check-label" htmlFor="enable_sixnumber">
                                            6 ตัว (รางวัลที่ 1)
                                        </label>
                                    </div>
                                </div> */}
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="enable_first_three_number" checked={checkedEnableNumber.first_three_number} onClick={() => handleCheckedEnableNumber('first_three_number')} />
                                        <label className="form-check-label" htmlFor="enable_first_three_number">
                                            3 ตัวหน้า
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="enable_last_three_number" checked={checkedEnableNumber.last_three_number} onClick={() => handleCheckedEnableNumber('last_three_number')} />
                                        <label className="form-check-label" htmlFor="enable_last_three_number">
                                            3 ตัวท้าย
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="enable_last_two_number" checked={checkedEnableNumber.last_two_number} onClick={() => handleCheckedEnableNumber('last_two_number')} />
                                        <label className="form-check-label" htmlFor="enable_last_two_number">
                                            2 ตัวล่าง
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <Box sx={
                {
                    display: tabOpen ? 'block' : 'none',
                    maxWidth: `100%`,
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, .7)',
                    zIndex: 10000000 - 1,
                    transition: 'all .5s'
                }
            } onClick={() => setTabOpen(false)} />
        </>
    )
}

export default CreateLottoTab;