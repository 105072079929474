import { useEffect, useState } from "react";
import { clickReportType, adminPermissionsType } from '../../types';
import { ApiService } from '../../services/api.service';
import Cookies from "universal-cookie";
import { Typography } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";

const ReportClick = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<clickReportType[]>([]);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        reloadData();
    }, []);

    const reloadData = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getReport = await service.getReportClick(cookies.get('access_token'));
            
            if(getReport.data.status_code === 200)
            {
                setReportData(getReport.data.report);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.report_click ? <>
                    <div className="card">
                        <div className="card-datatable table-responsive">
                            <table className="datatables-users table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">เจ้าของ</th>
                                        <th align="center">จำนวน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportData && reportData.length > 0 ? <>
                                            {
                                                reportData.sort((a: clickReportType,b: clickReportType) => b.count - a.count).map((element: clickReportType, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {
                                                                    element.reg_by
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.count
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td>
                                                    สรุป
                                                </td>
                                                <td>
                                                    {
                                                        reportData.reduce((accumulator: number, currentValue: clickReportType) => {
                                                            return accumulator + parseFloat(String(currentValue.count));
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                    }
                                                </td>
                                            </tr>
                                        </> :
                                            <tr>
                                                <td colSpan={12}>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        ไม่พบข้อมูล
                                                    </Typography>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default ReportClick;