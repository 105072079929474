import { useEffect, useState, useRef } from "react";
import { adminPermissionsType, adminProfileType, listAdminType } from '../../types';
import { ApiService } from '../../services/api.service';
import Cookies from "universal-cookie";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";
import Swal from "sweetalert2";
import { Typography } from "@mui/material";

const CreateAdmin = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    const [processing, setProcessing] = useState<boolean>(false);
    const [listAdmin, setListAdmin] = useState<listAdminType[]>([]);

    const usernameRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const [checkedPermissions, setCheckedPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});

    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }

        getListAdmin();
    }, []);

    const handleCheckPermission = (type: string) => {
        let tmp_checked = {
            ...checkedPermissions
        }

        if(type === 'member')
        {
            tmp_checked.member = !tmp_checked.member;
        }
        else if(type === 'deposit_scb')
        {
            tmp_checked.deposit_scb = !tmp_checked.deposit_scb;
        }
        else if(type === 'promotion')
        {
            tmp_checked.promotion = !tmp_checked.promotion;
        }
        else if(type === 'create_admin')
        {
            tmp_checked.create_admin = !tmp_checked.create_admin;
        }
        else if(type === 'withdraw_transactions')
        {
            tmp_checked.withdraw_transactions = !tmp_checked.withdraw_transactions;
        }
        else if(type === 'report_all')
        {
            tmp_checked.report_all = !tmp_checked.report_all;
        }
        else if(type === 'report_all_data')
        {
            tmp_checked.report_all_data = !tmp_checked.report_all_data;
        }
        else if(type === 'settings')
        {
            tmp_checked.settings = !tmp_checked.settings;
        }
        else if(type === 'deposit_transactions')
        {
            tmp_checked.deposit_transactions = !tmp_checked.deposit_transactions;
        }
        else if(type === 'managelotto')
        {
            tmp_checked.managelotto = !tmp_checked.managelotto;
        }
        else if(type === 'manange_bank_account')
        {
            tmp_checked.manange_bank_account = !tmp_checked.manange_bank_account;
        }

        setCheckedPermissions(tmp_checked);
    }

    const generatePassword = () => {
        const length = Math.floor(Math.random() * 6) + 10; // ความยาวระหว่าง 10 ถึง 15
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';
        
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters[randomIndex];
        }

        if(passwordRef.current)
        {
            passwordRef.current.value = randomString;
        }
    }

    const handleCreateAdmin = async () => {
        if(processing)
        {
            return;
        }
        else if(!usernameRef.current || !nameRef.current || !passwordRef.current)
        {
            return;
        }
        else if(usernameRef.current.value.trim() === '' || nameRef.current.value.trim() === '' || passwordRef.current.value.trim() === '')
        {
            return;
        }

        setProcessing(true);
        try {
            const service = new ApiService();
            const create = await service.createAdmin(cookies.get('access_token'), usernameRef.current.value.trim(), passwordRef.current.value.trim(), nameRef.current.value.trim(), checkedPermissions);

            if(create.data.status_code === 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `สร้างแอดมิน ${usernameRef.current.value} เรียบร้อยแล้ว`
                });
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `ERROR: ${create.data.message} (${create.data.status_code})`
                });
            }
        } catch (err) {
            console.log(err);
        }
        setProcessing(false);
    }

    const getListAdmin = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getAdmin = await service.getListAdmin(cookies.get('access_token'));

            if(getAdmin.data.status_code === 200)
            {
                setListAdmin(getAdmin.data.list_admin);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้ (${getAdmin.data.status_code} - ${getAdmin.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.create_admin ? <>
                    <div className="row g-3 mb-3">
                        <div className="col-12">
                            <div className="card p-2">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="card-title mb-0">สร้าง Admin</h5>
                                    <button type="button" className="btn btn-md btn-primary" onClick={handleCreateAdmin} disabled={processing}>สร้าง</button>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-label" htmlFor="admin_username">ชื่อผู้ใช้:</label>
                                        <input type="text" className="form-control" id="admin_username" ref={usernameRef} />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-label" htmlFor="admin_name">ชื่อ:</label>
                                        <input type="text" className="form-control" id="admin_name" ref={nameRef} />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-label" htmlFor="admin_password">รหัสผ่าน:</label>
                                        <input type="text" className="form-control" id="admin_password" ref={passwordRef} />
                                    </div>
                                    <div className="divider">
                                        <div className="divider-text">สิทธิ์การเข้าถึง</div>
                                    </div>
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_member" checked={checkedPermissions.member} onClick={() => handleCheckPermission('member')} />
                                            <label className="form-check-label" htmlFor="checked_member">
                                                จัดการสมาชิก
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_managelotto" checked={checkedPermissions.managelotto} onClick={() => handleCheckPermission('managelotto')} />
                                            <label className="form-check-label" htmlFor="checked_managelotto">
                                                จัดการหวย
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_deposit_scb" checked={checkedPermissions.deposit_scb} onClick={() => handleCheckPermission('deposit_scb')} />
                                            <label className="form-check-label" htmlFor="checked_deposit_scb">
                                                ดู/จัดการ รายการฝาก SCB
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_deposit_transactions" checked={checkedPermissions.deposit_transactions} onClick={() => handleCheckPermission('deposit_transactions')} />
                                            <label className="form-check-label" htmlFor="checked_deposit_transactions">
                                                ดู รายการฝาก Payment
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_withdraw_transactions" checked={checkedPermissions.withdraw_transactions} onClick={() => handleCheckPermission('withdraw_transactions')} />
                                            <label className="form-check-label" htmlFor="checked_withdraw_transactions">
                                                ดูรายการถอน
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_promotion" checked={checkedPermissions.promotion} onClick={() => handleCheckPermission('promotion')} />
                                            <label className="form-check-label" htmlFor="checked_promotion">
                                                ดู/จัดการ โปรโมชั่น
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_report_all_data" checked={checkedPermissions.report_all_data} onClick={() => handleCheckPermission('report_all_data')} />
                                            <label className="form-check-label" htmlFor="checked_report_all_data">
                                                ดูรายงาน (ยอดฝาก)
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_report_all" checked={checkedPermissions.report_all} onClick={() => handleCheckPermission('report_all')} />
                                            <label className="form-check-label" htmlFor="checked_report_all">
                                                ดูรายงาน (ภาพรวม)
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_settings" checked={checkedPermissions.settings} onClick={() => handleCheckPermission('settings')} />
                                            <label className="form-check-label" htmlFor="checked_settings">
                                                ตั้งค่าเว็บไซต์
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_create_admin" checked={checkedPermissions.create_admin} onClick={() => handleCheckPermission('create_admin')} />
                                            <label className="form-check-label" htmlFor="checked_create_admin">
                                                เพิ่มแอดมิน
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checked_manange_bank_account" checked={checkedPermissions.manange_bank_account} onClick={() => handleCheckPermission('manange_bank_account')} />
                                            <label className="form-check-label" htmlFor="checked_manange_bank_account">
                                                ตั้งค่าบัญชีธนาคาร
                                            </label>
                                        </div>
                                    </div>
                                    <div className="divider">
                                        <div className="divider-text">ตัวช่วย</div>
                                    </div>
                                    <div className="col-12">
                                        <button type="button" className="btn btn-md btn-primary w-100" onClick={generatePassword} disabled={processing}>GENERATE PASSWORD</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card mt-3">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className="border-top">
                                            <tr>
                                                <th align="center">
                                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                        #
                                                    </Typography>
                                                </th>
                                                <th align="center">
                                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                        ชื่อผู้ใช้
                                                    </Typography>
                                                </th>
                                                <th align="center">
                                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                        ชื่อที่ตั้ง
                                                    </Typography>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listAdmin.length > 0 ? listAdmin.map((element: listAdminType, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Typography align="center" fontSize={`0.95rem`}>
                                                                    {
                                                                        element.id
                                                                    }
                                                                </Typography>
                                                            </td>
                                                            <td>
                                                                <Typography align="center" fontSize={`0.95rem`}>
                                                                    {
                                                                        element.username
                                                                    }
                                                                </Typography>
                                                            </td>
                                                            <td>
                                                                <Typography align="center" fontSize={`0.95rem`}>
                                                                    {
                                                                        element.name
                                                                    }
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : <tr>
                                                    <td colSpan={3}>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            ไม่พบข้อมูล
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default CreateAdmin;