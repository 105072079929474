import { Box, Typography, Grid, Divider } from "@mui/material";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import { turnoverChecked, createPromotionType } from "../../types";
import AsyncSelect from 'react-select/async';
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import Img300 from './../../assets/images/300.svg';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface PropsType {
    tabOpen: boolean,
    setTabOpen: (state: boolean) => void
}

const CreatePromotionTab = ({ tabOpen, setTabOpen }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [creating, setCreating] = useState<boolean>(false);
    const [checkedTurn, setCheckedTurn] = useState<turnoverChecked>({
        all: true,
        slot: true,
        casino: true,
        fbs: true,
        poker: true,
        lotto: true,
        keno: true,
        trade: true
    });
    const [typeBonus, setTypeBonus] = useState<number>(1);

    const promotionNameRef = useRef<HTMLInputElement>(null);
    const promotionHtmlRef = useRef<HTMLTextAreaElement>(null);
    const bonusRef = useRef<HTMLInputElement>(null);
    const minDepositCreditRef = useRef<HTMLInputElement>(null);
    const maxDepositCreditRef = useRef<HTMLInputElement>(null);
    const maxBonusPerPersonPerDayRef = useRef<HTMLInputElement>(null);
    const maxGetPromotionPerPersonPerDayRef = useRef<HTMLInputElement>(null);
    const maxGetPromotionAllRef = useRef<HTMLInputElement>(null);
    const maxCreditWithdrawRef = useRef<HTMLInputElement>(null);
    const minNeedWinCreditRef = useRef<HTMLInputElement>(null);
    const maxGetPromotionRef = useRef<HTMLInputElement>(null);
    const turnoverNeedRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [valueStartDate, setValueStartDate] = useState<Dayjs | null>(null);
    const [valueEndDate, setValueEndDate] = useState<Dayjs | null>(null);

    const formatDate = (date: Dayjs | null) => {
        if (!date) return '';
        return date.format('YYYY-MM-DD');
    };

    const handleCreatePromotion = async () => {
        if(creating)
        {
            return;
        }
        else if(
            !promotionNameRef.current ||
            !promotionHtmlRef.current ||
            !bonusRef.current ||
            !minDepositCreditRef.current ||
            !maxDepositCreditRef.current ||
            !maxBonusPerPersonPerDayRef.current ||
            !maxGetPromotionPerPersonPerDayRef.current ||
            !maxCreditWithdrawRef.current ||
            !minNeedWinCreditRef.current ||
            !turnoverNeedRef.current ||
            !maxGetPromotionAllRef.current ||
            !maxGetPromotionRef.current
        )
        {
            return;
        }
        else if(
            promotionNameRef.current.value.trim() === '' ||
            promotionHtmlRef.current.value.trim() === '' ||
            bonusRef.current.value.trim() === '' ||
            minDepositCreditRef.current.value.trim() === '' ||
            maxDepositCreditRef.current.value.trim() === '' ||
            maxBonusPerPersonPerDayRef.current.value.trim() === '' ||
            maxGetPromotionPerPersonPerDayRef.current.value.trim() === '' ||
            maxCreditWithdrawRef.current.value.trim() === '' ||
            minNeedWinCreditRef.current.value.trim() === '' ||
            turnoverNeedRef.current.value.trim() === '' ||
            maxGetPromotionAllRef.current.value.trim() === '' ||
            maxGetPromotionRef.current.value.trim() === ''
        )
        {
            return;
        }

        setCreating(true);
        try {
            const data: createPromotionType = {
                promotionName: promotionNameRef.current.value.trim(),
                promotionHtml: promotionHtmlRef.current.value.trim(),
                bonus: bonusRef.current.value.trim(),
                minDepositCredit: minDepositCreditRef.current.value.trim(),
                maxDepositCredit: maxDepositCreditRef.current.value.trim(),
                maxBonusPerPersonPerDay: maxBonusPerPersonPerDayRef.current.value.trim(),
                maxGetPromotionPerPersonPerDay: maxGetPromotionPerPersonPerDayRef.current.value.trim(),
                maxGetPromotionAll: maxGetPromotionAllRef.current.value.trim(),
                maxCreditWithdraw: maxCreditWithdrawRef.current.value.trim(),
                minNeedWinCredit: minNeedWinCreditRef.current.value.trim(),
                maxGetPromotion: maxGetPromotionRef.current.value.trim(),
                turnoverNeed: turnoverNeedRef.current.value.trim(),
                checked: JSON.stringify(checkedTurn),
                typeBonus: typeBonus,
                startDate: formatDate(valueStartDate),
                endDate: formatDate(valueEndDate)
            }

            const formData: FormData = new FormData();
            if (file)
            {
                formData.append('image', file);
            }
            
            for (const key in data)
            {
                formData.append(key, (data as any)[key]);
            }

            const service = new ApiService();
            const create = await service.createPromotion(cookies.get('access_token'), formData);

            setTabOpen(false);
            Swal.fire({
                icon: create.data.status_code === 200 ? "success" : "error",
                title: 'แจ้งเตือน',
                text: create.data.status_code === 200 ? "สร้างโปรโมชั่นเรียบร้อยแล้ว" : create.data.message
            });
        } catch (err) {
            console.log(err);
        }
        setCreating(false);
    }

    const handleCheckTurnover = (type: string) => {
        let tmp_checked = {
            ...checkedTurn
        }

        if(type === 'all')
        {
            if(tmp_checked.all)
            {
                tmp_checked.all = false;
                tmp_checked.slot = false;
                tmp_checked.casino = false;
                tmp_checked.fbs = false;
                tmp_checked.poker = false;
                tmp_checked.lotto = false;
                tmp_checked.keno = false;
                tmp_checked.trade = false;
            }
            else
            {
                tmp_checked.all = true;
                tmp_checked.slot = true;
                tmp_checked.casino = true;
                tmp_checked.fbs = true;
                tmp_checked.poker = true;
                tmp_checked.lotto = true;
                tmp_checked.keno = true;
                tmp_checked.trade = true;
            }
        }
        else if(type === 'casino')
        {
            tmp_checked.casino = !tmp_checked.casino;
        }
        else if(type === 'slot')
        {
            tmp_checked.slot = !tmp_checked.slot;
        }
        else if(type === 'fbs')
        {
            tmp_checked.fbs = !tmp_checked.fbs;
        }
        else if(type === 'poker')
        {
            tmp_checked.poker = !tmp_checked.poker;
        }
        else if(type === 'lotto')
        {
            tmp_checked.lotto = !tmp_checked.lotto;
        }
        else if(type === 'keno')
        {
            tmp_checked.keno = !tmp_checked.keno;
        }
        else if(type === 'trade')
        {
            tmp_checked.trade = !tmp_checked.trade;
        }

        setCheckedTurn(tmp_checked);
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            const previewUrl = URL.createObjectURL(e.target.files[0]);
            setPreview(previewUrl);
        }
        else
        {
            setFile(null);
            setPreview(null);
        }
    };

    return (
        <>
            <Box sx={
                {
                    display: 'block',
                    maxWidth: {
                        xs: `90%`,
                        sm: `90%`,
                        md: `80%`,
                        lg: `60%`
                    },
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: tabOpen ? 0 : -3000,
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: 10000000,
                    color: 'black',
                    transition: 'all .3s',
                    borderLeft: 'solid 3px #e0e3e6',
                    overflowY: 'scroll'
                }
            }>
                <Box p={2}>
                    <div className="row">
                        <div className="col-6">
                            <Typography fontSize={18} fontWeight={`bold`}>
                                สร้างโปรโมชั่น
                            </Typography>
                        </div>
                        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button className="btn btn-primary" onClick={handleCreatePromotion} disabled={creating}>สร้าง</button>
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 mt-2">
                            <div className="card p-2">
                                <div className="col-12">
                                    <Typography align="center">
                                        รูปโปรโมชั่น
                                    </Typography>
                                </div>
                                <div className="col-12">
                                    <Box sx={{ display: `flex`, justifyContent: `center`, mt: 1 }}>
                                        <img src={preview ? preview : Img300} alt="300x300" style={{ width: '100%', maxWidth: '250px', maxHeight: '250px' }} />
                                    </Box>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="formFile" className="form-label">เลือกไฟล์รูปภาพ</label>
                                    <input className="form-control" type="file" id="formFile" onChange={handleFileChange} />
                                </div>
                                <div className="col-12 mt-3">
                                    ชื่อโปรโมชั่น
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="promotion_name" name="promotion_name" ref={promotionNameRef} />
                                </div>
                                <div className="col-12 mt-1">
                                    รายละเอียด
                                </div>
                                <div className="col-12">
                                    <textarea className="form-control" id="promotion_html" rows={5} ref={promotionHtmlRef}></textarea>
                                </div>
                                <div className="col-12 mt-1">
                                    เริ่มต้น
                                </div>
                                <div className="col-12">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={valueStartDate}
                                            onChange={(newValue) => setValueStartDate(newValue)}
                                            sx={
                                                {
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        height: '30px', // ความสูงของ Input Base
                                                        fontSize: 14,
                                                    },
                                                    '& .MuiPickersPopper-container': {
                                                        maxHeight: '30px', // ความสูงสูงสุดของ Popper
                                                    },
                                                }
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                                popper: {
                                                  disablePortal: true,
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-12 mt-1">
                                    สิ้นสุด
                                </div>
                                <div className="col-12">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={valueEndDate}
                                            onChange={(newValue) => setValueEndDate(newValue)}
                                            sx={
                                                {
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        height: '30px', // ความสูงของ Input Base
                                                        fontSize: 14,
                                                    },
                                                    '& .MuiPickersPopper-container': {
                                                        maxHeight: '30px', // ความสูงสูงสุดของ Popper
                                                    },
                                                }
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                                popper: {
                                                  disablePortal: true,
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8 mt-2">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card p-2">
                                        <div className="row">
                                            <div className="col-4">
                                                จำนวนโบนัส
                                            </div>
                                            <div className="col-8 d-flex" style={{ justifyContent: 'flex-end' }}>
                                                <input name="default-radio-1" className="form-check-input" type="radio" id="promotion_radioCredit" defaultChecked={true} onClick={() => setTypeBonus(1)} />
                                                <label className="form-check-label" htmlFor="promotion_radioCredit">
                                                    &nbsp;เครดิต
                                                </label>
                                                <input name="default-radio-1" className="form-check-input" type="radio" id="promotion_radioPercent" style={{ marginLeft: 10 }} onClick={() => setTypeBonus(2)} />
                                                <label className="form-check-label" htmlFor="promotion_radioPercent">
                                                    &nbsp;เปอร์เซ็นต์
                                                </label>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <input type="number" className="form-control form-control-sm" id="promotion_amount_bonus" name="promotion_amount_bonus" defaultValue={`0`} ref={bonusRef} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card p-2 mt-2">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <div className="row">
                                                    <div className="col-12">
                                                        ยอด <u>ฝาก</u> ขั้นต่ำ (เครดิต)
                                                    </div>
                                                    <div className="col-sm-11 col-md-11">
                                                        <input type="number" className="form-control form-control-sm" id="promotion_deposit_min" name="promotion_deposit_min" defaultValue={`0`} style={{ maxWidth: '95%' }} ref={minDepositCreditRef} />
                                                    </div>
                                                    <div className="col-12">
                                                        <Typography fontSize={11}>
                                                            0 หมายถึงไม่จำกัดยอดฝากขั้นต่ำ
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        ยอด <u>ฝาก</u> สูงสุด (เครดิต)
                                                    </div>
                                                    <div className="col-sm-11 col-md-11">
                                                        <input type="number" className="form-control form-control-sm" id="promotion_deposit_max" name="promotion_deposit_max" defaultValue={`0`} style={{ maxWidth: '95%' }} ref={maxDepositCreditRef} />
                                                    </div>
                                                    <div className="col-12">
                                                        <Typography fontSize={11}>
                                                            0 หมายถึงไม่จำกัดยอดฝากสูงสุด
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="card p-2 mt-2">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        จำนวนโบนัสสูงสุดต่อคนใน 1 วัน
                                                    </div>
                                                    <div className="col-sm-12 col-md-12">
                                                        <input type="number" className="form-control form-control-sm" id="promotion_max_credit_per_person_per_day" name="promotion_max_credit_per_person_per_day" defaultValue={`0`} style={{ maxWidth: '95%' }} ref={maxBonusPerPersonPerDayRef} />
                                                    </div>
                                                    <div className="col-12">
                                                        <Typography fontSize={11}>
                                                            0 หมายถึงไม่จำกัดจำนวนโบนัสสูงสุดต่อคนใน 1 วัน
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        จำนวนที่สามารถถอนได้(เครดิต)
                                                    </div>
                                                    <div className="col-sm-12 col-md-12">
                                                        <input type="number" className="form-control form-control-sm" id="promotion_max_withdraw_credit" name="promotion_max_withdraw_credit" defaultValue={`0`} style={{ maxWidth: '95%' }} ref={maxCreditWithdrawRef} />
                                                    </div>
                                                    <div className="col-12">
                                                        <Typography fontSize={11}>
                                                            0 หมายถึงไม่จำกัดจำนวนที่สามารถถอนได้
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        จำกัดจำนวนครั้งที่ใช้สูงสุดต่อคนใน 1 วัน
                                                    </div>
                                                    <div className="col-sm-12 col-md-12">
                                                        <input type="number" className="form-control form-control-sm" id="promotion_max_get_per_person" name="promotion_max_get_per_person" defaultValue={`0`} style={{ maxWidth: '95%' }} ref={maxGetPromotionPerPersonPerDayRef} />
                                                    </div>
                                                    <div className="col-12">
                                                        <Typography fontSize={11}>
                                                            0 หมายถึงไม่จำกัดจำนวนครั้งที่ใช้สูงสุดต่อคนใน 1 วัน
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        ยอดชนะที่ต้องทำ(เครดิต)
                                                    </div>
                                                    <div className="col-sm-12 col-md-12">
                                                        <input type="number" className="form-control form-control-sm" id="promotion_need_win_credit" name="promotion_need_win_credit" defaultValue={`0`} style={{ maxWidth: '95%' }} ref={minNeedWinCreditRef} />
                                                    </div>
                                                    <div className="col-12">
                                                        <Typography fontSize={11}>
                                                            0 หมายถึงไม่จำกัดยอดชนะที่ต้องทำ
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        จำกัดจำนวนการใช้โปรโมชันทั้งหมด(ครั้ง)
                                                    </div>
                                                    <div className="col-sm-12 col-md-12">
                                                        <input type="number" className="form-control form-control-sm" id="promotion_max_get_promotion_all" name="promotion_max_get_promotion_all" defaultValue={`0`} style={{ maxWidth: '95%' }} ref={maxGetPromotionAllRef} />
                                                    </div>
                                                    <div className="col-12">
                                                        <Typography fontSize={11}>
                                                            0 หมายถึงไม่จำกัดการใช้งานโปรโมชั่นนี้
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        จำกัดจำนวนครั้งการรับโปรโมชั่นนี้(ต่อคน)
                                                    </div>
                                                    <div className="col-sm-12 col-md-12">
                                                        <input type="number" className="form-control form-control-sm" id="promotion_max_get_promotion" name="promotion_max_get_promotion" defaultValue={`0`} style={{ maxWidth: '95%' }} ref={maxGetPromotionRef} />
                                                    </div>
                                                    <div className="col-12">
                                                        <Typography fontSize={11}>
                                                            0 หมายถึงไม่จำกัดการรับโปรโมชั่นนี้ต่อคน
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="col-12">
                                        <div className="card p-2">
                                            <div className="row">
                                                <div className="col-12">
                                                    จำนวน Turnover (เท่า)
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <input type="number" className="form-control form-control-sm" id="promotion_turnover_need" name="promotion_turnover_need" defaultValue={`0`} ref={turnoverNeedRef} />
                                                </div>
                                                <div className="col-12">
                                                    <Typography fontSize={11}>
                                                        0 หมายถึงไม่มียอด Turn over
                                                    </Typography>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    เลือกประเภทที่ต้องการให้มี Turn Over (จะมีผลก็ต่อเมื่อ ยอด Turn Over มากกว่า 0)
                                                </div>
                                                {/* const [checkedTurn, setCheckedTurn] = useState<turnoverChecked>({
                                                    all: true,
                                                    slot: true,
                                                    casino: true,
                                                    fbs: true,
                                                    poker: true,
                                                    lotto: true,
                                                    keno: true,
                                                    trade: true
                                                }); */}
                                                <div className="col-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="turnover_allCheck" checked={checkedTurn.all} onClick={() => handleCheckTurnover('all')} />
                                                        <label className="form-check-label" htmlFor="turnover_allCheck">
                                                            ทั้งหมด
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="turnover_sportsCheck" checked={checkedTurn.fbs} onClick={() => handleCheckTurnover('fbs')} />
                                                        <label className="form-check-label" htmlFor="turnover_sportsCheck">
                                                            กีฬา
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="turnover_casinoCheck" checked={checkedTurn.casino} onClick={() => handleCheckTurnover('casino')} />
                                                        <label className="form-check-label" htmlFor="turnover_casinoCheck">
                                                            คาสิโน
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="turnover_pokerCheck" checked={checkedTurn.poker} onClick={() => handleCheckTurnover('poker')} />
                                                        <label className="form-check-label" htmlFor="turnover_pokerCheck">
                                                            โป๊กเกอร์
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="turnover_slotCheck" checked={checkedTurn.slot} onClick={() => handleCheckTurnover('slot')} />
                                                        <label className="form-check-label" htmlFor="turnover_slotCheck">
                                                            สล็อต
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="turnover_kenoCheck" checked={checkedTurn.keno} onClick={() => handleCheckTurnover('keno')} />
                                                        <label className="form-check-label" htmlFor="turnover_kenoCheck">
                                                            Keno
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="turnover_lottoCheck" checked={checkedTurn.lotto} onClick={() => handleCheckTurnover('lotto')} />
                                                        <label className="form-check-label" htmlFor="turnover_lottoCheck">
                                                            หวย
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="turnover_trandingCheck" checked={checkedTurn.trade} onClick={() => handleCheckTurnover('trade')} />
                                                        <label className="form-check-label" htmlFor="turnover_trandingCheck">
                                                            Trade
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <Box sx={
                {
                    display: tabOpen ? 'block' : 'none',
                    maxWidth: `100%`,
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, .7)',
                    zIndex: 10000000 - 1,
                    transition: 'all .5s'
                }
            } onClick={() => setTabOpen(false)} />
        </>
    )
}

export default CreatePromotionTab;