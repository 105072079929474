import { Typography, TableRow, TableCell, IconButton, Tooltip, Box } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import StopIcon from '@mui/icons-material/Stop';
import LockIcon from '@mui/icons-material/Lock';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PaymentsIcon from '@mui/icons-material/Payments';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import DescriptionIcon from '@mui/icons-material/Description';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { memberType } from "../../types";
import { useState } from "react";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

interface PropsType {
    member: memberType;
    reloadMember: () => void;
    setSelectMember: (member_id: number) => void;
}

const MembersTableBody = ({ member, reloadMember, setSelectMember }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [recreating, setRecreating] = useState<boolean>(false);

    const addCredit = () => {
        Swal.fire({
            title: `เพิ่มเครดิตให้ ${member.ufa_username}`,
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "เพิ่ม",
            cancelButtonText: "ยกเลิก"
        }).then(async (res) => {
            if(res.isConfirmed)
            {
                const service = new ApiService();
                const addCredit = await service.addCredit(cookies.get('access_token'), String(member.ufa_username), res.value);

                if(addCredit.data.status_code === 200)
                {
                    reloadMember();
                }

                Swal.fire({
                    icon: addCredit.data.status_code === 200 ? 'success' : 'error',
                    title: 'แจ้งเตือน',
                    text: addCredit.data.status_code === 200 ? `เพิ่มเครดิตให้ ${member.ufa_username} จำนวน ${res.value} เรียบร้อยแล้วค่ะ` : addCredit.data.message
                });
            }
        });
    }

    const removeCredit = () => {
        Swal.fire({
            title: `ลบเครดิตของ ${member.ufa_username}`,
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "ลบ",
            cancelButtonText: "ยกเลิก"
        }).then(async (res) => {
            if(res.isConfirmed)
            {
                const service = new ApiService();
                const removeCredit = await service.removeCredit(cookies.get('access_token'), String(member.ufa_username), res.value);
                if(removeCredit.data.status_code === 200)
                {
                    reloadMember();
                }

                Swal.fire({
                    icon: removeCredit.data.status_code === 200 ? 'success' : 'error',
                    title: 'แจ้งเตือน',
                    text: removeCredit.data.status_code === 200 ? `ลบเครดิตของ ${member.ufa_username} จำนวน ${res.value} เรียบร้อยแล้วค่ะ` : removeCredit.data.message
                });
            }
        });
    }

    const reCreateUfaUser = async (member_id: number) => {
        if(recreating)
        {
            return;
        }
        setRecreating(true);

        try {
            const service = new ApiService();
            const recreate = (await service.recreateUfaUser(cookies.get('access_token'), member_id)).data;

            Swal.fire({
                icon: recreate.status_code === 200 ? 'success' : 'error',
                title: 'แจ้งเตือน',
                text: recreate.status_code === 200 ? "สร้างผู้ใช้ UFA ให้ใหม่เรียบร้อยค่ะ" : recreate.message
            }).then(() => {
                if(recreate.status_code === 200)
                {
                    reloadMember();
                }
            });
        } catch (err) {
            console.log(err);
        }

        setRecreating(false);
    }

    const handleDelete = async (id: number) => {
        Swal.fire({
            title: 'ยืนยันใช่หรือไม่ ?',
            icon: 'warning',
            text: `คุณยืนยันที่จะลบ ${member.ufa_username} ใช่หรือไม่ ?`,
            showCancelButton: true
        }).then(async (res) => {
            if(res.isConfirmed)
            {
                try {
                    const service = new ApiService();
                    const deleteMember = await service.deleteMember(cookies.get('access_token'), id);
                    if(deleteMember.data.status_code === 200)
                    {
                        reloadMember();
                        Swal.fire({
                            title: 'แจ้งเตือน',
                            icon: 'success',
                            text: `ลบ ${member.phone} เรียบร้อยแล้วค่ะ`
                        });
                    }
                    else
                    {
                        Swal.fire({
                            title: 'แจ้งเตือน',
                            icon: 'error',
                            text: `ลบ ${member.phone} ไม่สำเร็จ (${deleteMember.data.message || "ไม่ทราบสาเหตุ"})`
                        });
                    }
                } catch (err) {
                    Swal.fire({
                        title: 'แจ้งเตือน',
                        icon: 'error',
                        text: `เกิดข้อผิดพลาดไม่ทราบสาเหตุ`
                    });
                }
            }
        });
    }

    return (
        <tr>
            <td>
                {member.id}
            </td>
            <td align="center">
                {member.first_name}&nbsp;{member.last_name}
            </td>
            <td align="center">
                {member.ufa_username}
            </td>
            <td align="center">
                {member.bank}&nbsp;(X-{member.bank_number.slice(6, 10)})
            </td>
            <td align="center">
                {
                    parseFloat(String(member.credit)).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })
                }
            </td>
            <td align="center">
                {
                    member.status === 1 ? "ปกติ" : (member.status === 2) ? "ระงับการเข้าเล่น" : "ระงับการใช้งาน"
                }
            </td>
            <td align="center">
                <Link to={`/member`} onClick={() => setSelectMember(member.id)}>
                    <i className="bx bx-show" />
                </Link>
            </td>
        </tr>
    )
}

export default MembersTableBody;