import { ChangeEvent, useEffect, useState } from "react";
import { clickReportType, adminPermissionsType, reportSumDepositScbType } from '../../types';
import { ApiService } from '../../services/api.service';
import Cookies from "universal-cookie";
import { Pagination, Typography } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";
import { styled } from '@mui/system';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const formatDate = (date: Dayjs | null) => {
    if (!date) return '';
    return date.format('DD-MM-YYYY');
};

const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "rgba(255, 255, 255, .7)", // สีของหน้าที่ไม่ได้เลือก
    },
    '& .Mui-selected': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "white",
    },
});

const ReportSumDepositTotal = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<reportSumDepositScbType[]>([]);
    const [valueStartDate, setValueStartDate] = useState<Dayjs | null>(dayjs());
    const [valueEndDate, setValueEndDate] = useState<Dayjs | null>(dayjs());
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [countAllReports, setCountAllReports] = useState<number | null>(null);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        reloadData();
    }, [page, limit]);

    const reloadData = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const start_date = formatDate(valueStartDate);
            const end_date = formatDate(valueEndDate);

            const service = new ApiService();
            const getReport = await service.getReportSumDepositTotal(cookies.get('access_token'), page, limit, start_date, end_date);
            if(getReport.data.status_code === 200)
            {
                setCountAllReports(getReport.data.count_reports);
                setReportData(getReport.data.reports);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const handleChangePage = (_e: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.report_all ? <>
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 mb-2">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={valueStartDate}
                                            onChange={(newValue) => setValueStartDate(newValue)}
                                            sx={
                                                {
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        height: '40px', // ความสูงของ Input Base
                                                        fontSize: 14,
                                                    },
                                                    '& .MuiPickersPopper-container': {
                                                        maxHeight: '40px', // ความสูงสูงสุดของ Popper
                                                    },
                                                }
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                                popper: {
                                                  disablePortal: true,
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                            defaultValue={dayjs()}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-sm-12 col-md-4 mb-2">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={valueEndDate}
                                            onChange={(newValue) => setValueEndDate(newValue)}
                                            sx={
                                                {
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        height: '40px', // ความสูงของ Input Base
                                                        fontSize: 14,
                                                    },
                                                    '& .MuiPickersPopper-container': {
                                                        maxHeight: '40px', // ความสูงสูงสุดของ Popper
                                                    },
                                                }
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                                popper: {
                                                  disablePortal: true,
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                            defaultValue={dayjs()}
                                            minDate={valueStartDate || dayjs()}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-sm-12 col-md-2 mb-2">
                                    <button className="btn btn-primary w-100" onClick={reloadData} disabled={loading}>ค้นหา</button>
                                </div>
                                <div className="col-sm-12 col-md-2 mb-2" style={{ 
                                    display: `flex`,
                                    justifyContent: `flex-end`,
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    แสดง:&nbsp;
                                    <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                        style={{
                                            maxHeight: 40,
                                            maxWidth: 100
                                        }}
                                        defaultValue={`10`}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            if(!e.target)
                                            {
                                                return;
                                            }

                                            setLimit(parseInt(e.target.value));
                                        }}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="70">70</option>
                                        <option value="80">80</option>
                                        <option value="90">90</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="card py-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-6">
                                    <CustomPagination
                                        count={Math.ceil((countAllReports === null ? 0 : countAllReports) / limit)}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                                <div className="col-sm-12 col-xl-6" style={{ display: `flex`, justifyContent: `flex-end` }}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-datatable table-responsive">
                            <table className="datatables-users table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">เลขบัญชี</th>
                                        <th align="center">วันที่</th>
                                        <th align="center">จำนวน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportData && reportData.length > 0 ? <>
                                            {
                                                reportData.sort((a: reportSumDepositScbType,b: reportSumDepositScbType) => {
                                                    const dateA = new Date(a.date).getTime();
                                                    const dateB = new Date(b.date).getTime();

                                                    return dateB - dateA;
                                                }).map((element: reportSumDepositScbType, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                X-{
                                                                    element.bank_number
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    `${String(new Date(String(element.date)).getDate()).padStart(2, '0')}/${String(new Date(String(element.date)).getMonth() + 1).padStart(2, '0')}/${String(new Date(String(element.date)).getFullYear())}`
                                                                }
                                                            </td>
                                                            <td>
                                                                ฿{
                                                                    parseFloat(element.amount.toString()).toLocaleString('en-US', {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td colSpan={2}>
                                                    สรุป
                                                </td>
                                                <td>
                                                    ฿{
                                                        reportData.reduce((accumulator: number, currentValue: reportSumDepositScbType) => {
                                                            return accumulator + parseFloat(String(currentValue.amount));
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    }
                                                </td>
                                            </tr>
                                        </> :
                                            <tr>
                                                <td colSpan={12}>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        ไม่พบข้อมูล
                                                    </Typography>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card py-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-6">
                                    <CustomPagination
                                        count={Math.ceil((countAllReports === null ? 0 : countAllReports) / limit)}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                                <div className="col-sm-12 col-xl-6" style={{ display: `flex`, justifyContent: `flex-end` }}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default ReportSumDepositTotal;