import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { ApiService } from "./services/api.service";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { OutletContextProvider } from "./OutletContext";
import { useMediaQuery } from 'react-responsive';
import PageLoading from "./components/PageLoading/PageLoading";
import Header from "./components/Header/Header";

// FONTS
import './assets/vendor/fonts/boxicons.css'
import './assets/vendor/fonts/fontawesome.css'
import './assets/vendor/fonts/flag-icons.css'

// CSS
import './assets/css/demo.css'
import './assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css'
import './assets/vendor/libs/typeahead-js/typeahead.css'
import './assets/vendor/libs/apex-charts/apex-charts.css'

import Sidebar from "./components/Sidebar/Sidebar";
import { adminPermissionsType } from "./types";

import SocketIO from "./components/SocketIO";

const App = () => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    const [isOpen, setIsOpen] = useState(true);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    });
    const [theme, setTheme] = useState<string | null>('light');

    useEffect(() => {
        let savedTheme = localStorage.getItem('theme');
        if(!savedTheme)
        {
            savedTheme = 'light';
            localStorage.setItem('theme', 'light');
        }

        setTheme(savedTheme);
    }, []);

    useEffect(() => {
        if(theme === 'dark')
        {
            import('./assets/vendor/css/rtl/core-dark.css');
            import('./assets/vendor/css/rtl/theme-default-dark.css');
        }
        else
        {
            import('./assets/vendor/css/rtl/core.css');
            import('./assets/vendor/css/rtl/theme-default.css');
        }
      }, [theme]);

    useEffect(() => {
        (async () => {
            try {
                setLoaded(false);
                if(!cookies.get('access_token'))
                {
                    return navigate('/login');
                }
        
                const access_token: string = cookies.get('access_token');
                const service = new ApiService();
                const check = (await service.check_access_token(access_token)).data;

                if(check.status_code !== 200)
                {
                    cookies.remove('access_token');
                    return navigate('/login');
                }

                setPermissions(JSON.parse(check.permission));
                setLoaded(true);
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);


    const handleToggleOpen = (state: boolean) => {
        if(!state || typeof(state) !== 'boolean')
        {
            setIsOpen(!isOpen);
        }
        
        setIsOpen(state);
    }

    return (
        // DESKTOP
            // SHOW
            // light-style layout-navbar-fixed layout-compact layout-menu-fixed
            // HIDE
            // light-style layout-navbar-fixed layout-compact layout-menu-fixed layout-menu-collapsed

        // MOBILE
            // SHOW
            // light-style layout-navbar-fixed layout-compact layout-menu-fixed layout-menu-expanded
            // HIDE
            // light-style layout-navbar-fixed layout-compact layout-menu-fixed
        loaded ? <div className={
            isDesktopOrLaptop ? (
                isOpen ? "light-style layout-navbar-fixed layout-compact layout-menu-fixed" : "light-style layout-navbar-fixed layout-compact layout-menu-fixed layout-menu-collapsed"
            ) : (
                isOpen ? "light-style layout-navbar-fixed layout-compact layout-menu-fixed layout-menu-expanded" : "light-style layout-navbar-fixed layout-compact layout-menu-fixed"
            )
        }>
            {/* {
                theme === 'dark' ? <DarkTheme /> : <LightTheme />
            } */}
            <SocketIO />
            <OutletContextProvider permission={permissions}>
                <div className="layout-wrapper layout-content-navbar">
                    <div className="layout-container">
                        <Sidebar isOpen={isOpen} handleToggleOpen={handleToggleOpen} permissions={permissions} />
                        <div className="layout-page">
                            <div className="content-wrapper">
                                <div className="container-xxl flex-grow-1 container-p-y">
                                    <Header handleToggleOpen={handleToggleOpen} isOpen={isOpen} />
                                    {/* <Box sx={{
                                        display: {
                                            lg: 'none',
                                            md: 'none',
                                            sm: 'block',
                                            xs: 'block'
                                        },
                                        mb: 2
                                    }}>
                                        <Box  width={`auto`} p={1} bgcolor={localStorage.getItem('theme') !== 'dark' ? `white` : `#283144`} borderRadius={2}>
                                            <IconButton onClick={() => handleToggleOpen(isOpen ? false : true)} sx={
                                                {
                                                    color: localStorage.getItem('theme') !== 'dark' ? `#1976d2` : `white`,
                                                    backgroundColor: 'rgba(255, 255, 255, .0)'
                                                }
                                            }>
                                                <i className="bx bx-sm bx-arrow-to-right"></i>
                                                <Typography fontSize={14}>
                                                    เปิดเมนู
                                                </Typography>
                                            </IconButton>
                                        </Box>
                                    </Box> */}
                                    <Outlet></Outlet>
                                </div>
                                <div className="content-backdrop fade" />
                            </div>
                        </div>
                    </div>
                    <div className="layout-overlay layout-menu-toggle" onClick={() => handleToggleOpen(false)} />
                    <div className="drag-target" />
                </div>
            </OutletContextProvider>
        </div> : <PageLoading />
    )
}

export default App;