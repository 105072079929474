import { Box, Typography } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { updatePeriodResultsType } from "../../types";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

interface PropsType {
    tabOpen: boolean,
    setTabOpen: (state: boolean) => void,
    periodId: number | null
}

const ManageResultsPeriod = ({ tabOpen, setTabOpen, periodId }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const sixNumberRef = useRef<HTMLInputElement>(null);
    const firstThreeNumberRef = useRef<HTMLInputElement>(null);
    const lastThreeNumberRef = useRef<HTMLInputElement>(null);
    const lastThreeNumberTwoRef = useRef<HTMLInputElement>(null);
    const twoNumberRef = useRef<HTMLInputElement>(null);
    const [resultsData, setResultsData] = useState<updatePeriodResultsType>({
        six_number: 'XXXXXX',
        first_three_number: 'XXX',
        last_three_number: 'XXX',
        last_three_number_two: 'XXX',
        last_two_number: 'XX'
    });

    useEffect(() => {
        if(!tabOpen)
        {
            const elementSixNumber = document.getElementById("six_number_results") as HTMLInputElement;
            const elementFirstThreeNumber = document.getElementById("first_three_number_results") as HTMLInputElement;
            const elementLastThreeNumber = document.getElementById("last_three_number_results") as HTMLInputElement;
            const elementLastTwoNumber = document.getElementById("two_number_results") as HTMLInputElement;

            if(elementSixNumber)
            {
                elementSixNumber.value = '';
            }
            if(elementFirstThreeNumber)
            {
                elementFirstThreeNumber.value = '';
            }
            if(elementLastThreeNumber)
            {
                elementLastThreeNumber.value = '';
            }
            if(elementLastTwoNumber)
            {
                elementLastTwoNumber.value = '';
            }

            return setResultsData({
                six_number: 'XXXXXX',
                first_three_number: 'XXX',
                last_three_number: 'XXX',
                last_three_number_two: 'XXX',
                last_two_number: 'XX'
            });
        }

        if(!periodId)
        {
            return;
        }

        loadPeriodData();
    }, [tabOpen, periodId]);

    const loadPeriodData = async () => {
        if(!periodId)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getResults = await service.loadDataPeriodResults(cookies.get('access_token'), periodId);

            if(getResults.data.status_code === 200)
            {
                setResultsData({
                    six_number: getResults.data.results.six_number,
                    first_three_number: getResults.data.results.first_three_number,
                    last_three_number: getResults.data.results.last_three_number.split(",")[0],
                    last_three_number_two: getResults.data.results.last_three_number.split(",")[1],
                    last_two_number: getResults.data.results.last_two_number,
                });
            }
            else
            {
                setTabOpen(false);
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด - ${getResults.data.status_code} ${getResults.data.message}`
                });
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
        setLoading(false);
    }

    const handleEditResults = async () => {
        if(!sixNumberRef.current || !firstThreeNumberRef.current || !lastThreeNumberRef.current || !twoNumberRef.current || !lastThreeNumberTwoRef.current || !periodId)
        {
            return;
        }

        setUpdating(true);
        try {
            const data: updatePeriodResultsType = {
                six_number: sixNumberRef.current.value,
                first_three_number: firstThreeNumberRef.current.value,
                last_three_number: lastThreeNumberRef.current.value,
                last_three_number_two: lastThreeNumberTwoRef.current.value,
                last_two_number: twoNumberRef.current.value
            };

            const service = new ApiService();
            const updateResults = await service.updatePeriodResults(cookies.get('access_token'), periodId, data);

            if(updateResults.data.status_code === 200)
            {
                setTabOpen(false);
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'อัพเดทเรียบร้อยแล้วค่ะ'
                });
            }
            else
            {
                setTabOpen(false);
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด - ${updateResults.data.status_code} ${updateResults.data.message}`
                });
            }
        } catch (err) {
            console.log(err);
            setUpdating(false);
        }

        setUpdating(false);
    }

    return (
        periodId && tabOpen && !loading ? <>
            <Box sx={
                {
                    display: 'block',
                    maxWidth: {
                        xs: `90%`,
                        sm: `90%`,
                        md: `50%`,
                        lg: `30%`
                    },
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: tabOpen ? 0 : -3000,
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: 10000000,
                    color: 'black',
                    transition: 'all .3s',
                    borderLeft: 'solid 3px #e0e3e6',
                    overflowY: 'scroll'
                }
            }>
                <Box p={2}>
                    <div className="row">
                        <div className="col-6">
                            <Typography fontSize={18} fontWeight={`bold`}>
                                จัดการผลหวย
                            </Typography>
                        </div>
                        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button className="btn btn-primary" onClick={handleEditResults} disabled={updating}>อัพเดท</button>
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
                            <div className="card p-2">
                                {/* <div className="col-sm-12 col-md-12">
                                    <hr/>
                                </div> */}
                                <div className="col-12 mt-3">
                                    6 ตัว (รางวัลที่ 1)
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="six_number_results" name="six_number_results" ref={sixNumberRef} defaultValue={resultsData.six_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    3 ตัวบน
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="first_three_number_results" name="first_three_number_results" ref={firstThreeNumberRef} defaultValue={resultsData.first_three_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    3 ตัวล่าง (1)
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="last_three_number_results" name="last_three_number_results" ref={lastThreeNumberRef} defaultValue={resultsData.last_three_number} />
                                </div>
                                <div className="col-12 mt-3">
                                    3 ตัวล่าง (2)
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="last_three_number_two_results" name="last_three_number_two_results" ref={lastThreeNumberTwoRef} defaultValue={resultsData.last_three_number_two} />
                                </div>
                                <div className="col-12 mt-3">
                                    2 ตัวล่าง
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <input type="text" className="form-control form-control-sm" id="two_number_results" name="two_number_results" ref={twoNumberRef} defaultValue={resultsData.last_two_number} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <Box sx={
                {
                    display: tabOpen ? 'block' : 'none',
                    maxWidth: `100%`,
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, .7)',
                    zIndex: 10000000 - 1,
                    transition: 'all .5s'
                }
            } onClick={() => setTabOpen(false)} />
        </> : null
    )
}

export default ManageResultsPeriod;