import { Typography, Box } from "@mui/material";
import Cookies from "universal-cookie";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import { lottoDetailType, lottoPeriodType } from "../../types";
import Swal from "sweetalert2";
import moment from "moment";
import PageLoading from "../PageLoading/PageLoading";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers";
import ManageResultsPeriod from "./ManageResultsPeriod";
import ManageLimitsNumber from "./ManageLimitsNumber";

const ManageLottoById = () => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const { lotto_id } = useParams();
    const [lottoDetail, setLottoDetail] = useState<lottoDetailType | null>(null);
    const [period, setPeriod] = useState<lottoPeriodType[] | null>(null);
    const [lottoId, setLottoId] = useState<number | null>();
    const [processing, setProcessing] = useState<boolean>(false);

    const [periodIdResults, setPeriodIdResults] = useState<number | null>(null);
    const [openEditResults, setOpenEditResults] = useState<boolean>(false);

    const [periodIdLimitNumber, setPeriodIdLimitNumber] = useState<number | null>(null);
    const [openEditLimitNumber, setOpenEditLimitNumber] = useState<boolean>(false);

    const lottoPeriodNameRef = useRef<HTMLInputElement>(null);
    const [valueStartDate, setValueStartDate] = useState<Dayjs | null>(null);
    const [valueEndDate, setValueEndDate] = useState<Dayjs | null>(null);

    const formatDate = (date: Dayjs | null) => {
        if (!date) return '';
        return date.format('YYYY-MM-DD H:m:s');
    };

    useEffect(() => {
        loadLottoData(parseInt(`${lotto_id}`));
    }, [lotto_id]);

    const loadLottoData = async (lotto_id: number) => {
        try {
            const service = new ApiService();
            const loadData = await service.loadLottoDataForEdit(cookies.get('access_token'), lotto_id);
            if(loadData.data.status_code === 200)
            {
                setLottoId(lotto_id);
                setLottoDetail({
                    name: loadData.data.data.name,
                    img_path: loadData.data.data.img_path,
                    active_period_id: loadData.data.data.active_period_id,
                    active_result_period_id: loadData.data.data.active_result_period_id,
                    payRate: loadData.data.data.payRate,
                    min_bet: loadData.data.data.min_bet,
                    max_bet: loadData.data.data.max_bet
                });

                return setPeriod(loadData.data.data.period);
            }
            else if(loadData.data.status_code === 404)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'ไม่พบข้อมูลที่คุณค้นหา'
                }).then(() => {
                    navigate('/managelotto');
                });
            }

            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `เกิดข้อผิดพลาด - ${loadData.data.status_code} - ${loadData.data.message}`
            }).then(() => {
                navigate('/managelotto');
            });
        } catch (err) {
            console.log(err);
        }
    }

    const activePeriodLotto = async (period_id: number) => {
        if(!lottoId)
        {
            return;
        }

        try {
            const service = new ApiService();
            const activeLottoPeriod = await service.activeLottoPeriod(cookies.get('access_token'), lottoId, period_id);

            if(activeLottoPeriod.data.status_code === 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'ACTIVE PLAY สำเร็จ'
                });

                return loadLottoData(lottoId);
            }
            else
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `ACTIVE PLAY ไม่สำเร็จ (${activeLottoPeriod.data.status_code} - ${activeLottoPeriod.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const activeResultPeriodLotto = async (period_id: number) => {
        if(!lottoId)
        {
            return;
        }

        try {
            const service = new ApiService();
            const activeLottoPeriod = await service.activeResultLottoPeriod(cookies.get('access_token'), lottoId, period_id);

            if(activeLottoPeriod.data.status_code === 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'ACTIVE RESULT สำเร็จ'
                });

                return loadLottoData(lottoId);
            }
            else
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `ACTIVE RESULT ไม่สำเร็จ (${activeLottoPeriod.data.status_code} - ${activeLottoPeriod.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleAddPeriod = async () => {
        if(!lottoPeriodNameRef.current || !valueStartDate || !valueEndDate || !lottoId)
        {
            return;
        }
        
        try {
            const service = new ApiService();
            const createLottoPeriod = await service.createLottoPeriod(cookies.get('access_token'), lottoId, lottoPeriodNameRef.current.value, formatDate(valueStartDate), formatDate(valueEndDate));

            if(createLottoPeriod.data.status_code === 200)
            {
                setValueStartDate(null);
                setValueEndDate(null);
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'เพิ่มวันเวลาปิดเปิดให้แทงเรียบร้อยแล้วค่ะ'
                });
                loadLottoData(lottoId);
            }
            else
            {
                setValueStartDate(null);
                setValueEndDate(null);
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด ${createLottoPeriod.data.message}`
                });
                loadLottoData(lottoId);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleDeleteLottoPeriod = async (period_id: number) => {
        if(!lottoId || processing)
        {
            return;
        }

        Swal.fire({
            icon: 'warning',
            title: "แจ้งเตือน",
            text: 'คุณยืนยันที่จะลบใช่หรือไม่?',
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: `ยกเลิก`
        }).then(async (result) => {
            if (result.isConfirmed) {
                setProcessing(true);
                try {
                    const service = new ApiService();
                    const deleteLottoPeriod = await service.deleteLottoPeriod(cookies.get('access_token'), lottoId, period_id);
        
                    if(deleteLottoPeriod.data.status_code === 200)
                    {
                        loadLottoData(lottoId);
                        Swal.fire({
                            icon: 'success',
                            title: 'แจ้งเตือน',
                            text: 'ลบวันเวลาปิดเปิดให้แทงเรียบร้อยแล้วค่ะ'
                        });
                    }
                    else
                    {
                        loadLottoData(lottoId);
                        Swal.fire({
                            icon: 'success',
                            title: 'แจ้งเตือน',
                            text: `เกิดข้อผิดพลาด ${deleteLottoPeriod.data.message}`
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
                setProcessing(false);
            }
        });
    }

    const handleEditPeriodResults = (period_id: number) => {
        setPeriodIdResults(period_id);
        setOpenEditResults(true);
    }

    const handleEditPeriodLimitNumber = (period_id: number) => {
        setPeriodIdLimitNumber(period_id);
        setOpenEditLimitNumber(true);
    }

    const handlePayBill = (period_id: number) => {
        if(!lottoId || processing)
        {
            return;
        }

        Swal.fire({
            icon: 'warning',
            title: "แจ้งเตือน",
            text: 'คุณยืนยันที่จะสั่งจ่ายบิลใช่หรือไม่?',
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: `ยกเลิก`
        }).then(async (result) => {
            if (result.isConfirmed) {
                setProcessing(true);
                try {
                    const service = new ApiService();
                    const payBill = await service.payBillByPeriodId(cookies.get('access_token'), lottoId, period_id);

                    if(payBill.data.status_code === 200)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'แจ้งเตือน',
                            text: 'สั่งจ่ายบิลเรียบร้อยแล้วค่ะ'
                        });
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'แจ้งเตือน',
                            text: `สั่งจ่ายบิลไม่สำเร็จ - ${payBill.data.status_code} ${payBill.data.message}`
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
                setProcessing(false);
            }
        });
    }

    return (
        lottoDetail ? <>
            <ManageResultsPeriod tabOpen={openEditResults} setTabOpen={setOpenEditResults} periodId={periodIdResults} />
            <ManageLimitsNumber tabOpen={openEditLimitNumber} setTabOpen={setOpenEditLimitNumber} lottoId={lottoId} periodId={periodIdLimitNumber} />
            {
                processing ? <PageLoading /> : null
            }
            <div className="card mb-3">
                <Box p={2}>
                    <Typography fontSize={18} fontWeight={600}>
                        เพิ่มวันเวลาปิดเปิดให้แทง
                    </Typography>
                    <div className="col-12 mt-1">
                        วันที่ - เวลา ที่แสดงบนหน้าเว็บ
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <input type="text" className="form-control form-control-sm" id="lotto_period_name" name="lotto_period_name" ref={lottoPeriodNameRef} />
                    </div>
                    <div className="col-12 mt-1">
                        วันเวลาที่เปิดให้แทง
                    </div>
                    <div className="col-12">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label=""
                                value={valueStartDate}
                                onChange={(newValue) => setValueStartDate(newValue)}
                                sx={
                                    {
                                        backgroundColor: 'white',
                                        width: '100%',
                                        '& .MuiInputBase-root': {
                                            height: '30px', // ความสูงของ Input Base
                                            fontSize: 14,
                                        },
                                        '& .MuiPickersPopper-container': {
                                            maxHeight: '30px', // ความสูงสูงสุดของ Popper
                                        },
                                    }
                                }
                                slotProps={{
                                    field: { clearable: true },
                                    popper: {
                                        disablePortal: true,
                                    },
                                }}
                                format="DD/MM/YYYY H:m:s"
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-12 mt-1">
                        วันเวลาที่ปิดให้แทง
                    </div>
                    <div className="col-12">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label=""
                                value={valueEndDate}
                                onChange={(newValue) => setValueEndDate(newValue)}
                                sx={
                                    {
                                        backgroundColor: 'white',
                                        width: '100%',
                                        '& .MuiInputBase-root': {
                                            height: '30px', // ความสูงของ Input Base
                                            fontSize: 14,
                                        },
                                        '& .MuiPickersPopper-container': {
                                            maxHeight: '30px', // ความสูงสูงสุดของ Popper
                                        },
                                    }
                                }
                                slotProps={{
                                    field: { clearable: true },
                                    popper: {
                                        disablePortal: true,
                                    },
                                }}
                                format="DD/MM/YYYY H:m:s"
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-12 mt-2">
                        <button className="btn btn-primary w-100" onClick={handleAddPeriod}>เพิ่มวันเวลาปิดเปิดให้แทง</button>
                    </div>
                </Box>
            </div>
            <div className="card">
                <Box sx={{ px: 2, pt: 1 }}>
                    <Typography fontSize={18}>
                        จัดการ #{lottoId}&nbsp;<b>{lottoDetail.name}</b>
                    </Typography>
                </Box>
                <div className="table-responsive mt-3">
                    <table className="table">
                        <thead className="border-top">
                            <tr>
                                <th align="center">
                                    <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                        วันที่ - เวลา ที่แสดงบนหน้าเว็บ
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                        วันเวลาที่เปิดแทง
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                        วันเวลาที่ปิดแทง
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                        จัดการ
                                    </Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                period && period.length > 0 ? period.sort((a, b) => {
                                    const a_date = moment(`${a.start_dt}`).format("YYYY-MM-DD").toString();
                                    const b_date = moment(`${b.start_dt}`).format("YYYY-MM-DD").toString();
                                    return new Date(a_date).getTime() - new Date(b_date).getTime();
                                }).map((element: lottoPeriodType, index: number) => {
                                    return (
                                        <tr key={index} style={{
                                            backgroundColor: lottoDetail.active_period_id === element.id ? 'rgba(10, 255, 50, .3)' : 'unset'
                                        }}>
                                            <td>
                                                <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                    {element.name}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                    {
                                                        moment(`${element.start_dt}`).format("DD/MM/YYYY")
                                                    }
                                                    &nbsp;
                                                    {
                                                        moment(`${element.start_dt}`).format("H").toString().padStart(2, '0')
                                                    }
                                                    :
                                                    {
                                                        moment(`${element.start_dt}`).format("m").toString().padStart(2, '0')
                                                    }
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                    {
                                                        moment(`${element.end_dt}`).format("DD/MM/YYYY")
                                                    }
                                                    &nbsp;
                                                    {
                                                        moment(`${element.end_dt}`).format("H").toString().padStart(2, '0')
                                                    }
                                                    :
                                                    {
                                                        moment(`${element.end_dt}`).format("m").toString().padStart(2, '0')
                                                    }
                                                </Typography>
                                            </td>
                                            <td style={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                                                <button className="btn btn-primary d-grid" onClick={() => navigate(`/lotto/bills/${lottoId}/${element.id}`)}>ดูโพย</button>
                                                <button className="btn btn-primary d-grid" onClick={() => handlePayBill(element.id)}>สั่งจ่ายบิล</button>
                                                <button className="btn btn-primary d-grid" onClick={() => handleEditPeriodLimitNumber(element.id)}>เลขอั้น</button>
                                                <button className="btn btn-primary d-grid" onClick={() => activePeriodLotto(element.id)} disabled={lottoDetail.active_period_id === element.id}>
                                                    {
                                                        lottoDetail.active_period_id === element.id ? "ACTIVED PLAY" : "ACTIVE PLAY"
                                                    }
                                                </button>
                                                <button className="btn btn-primary d-grid" onClick={() => activeResultPeriodLotto(element.id)} disabled={lottoDetail.active_result_period_id === element.id}>
                                                    {
                                                        lottoDetail.active_result_period_id === element.id ? "ACTIVED RESULT" : "ACTIVE RESULT"
                                                    }
                                                </button>
                                                <button className="btn btn-info d-grid" onClick={() => navigate(`/lotto/topnumber/${lottoId}/${element.id}`)}>เลขที่ถูกซื้อมากที่สุด</button>
                                                <button className="btn btn-info d-grid" onClick={() => handleEditPeriodResults(element.id)}>แก้ไขผล</button>
                                                <button className="btn btn-danger d-grid" onClick={() => handleDeleteLottoPeriod(element.id)}>ลบ</button>
                                            </td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={5}>
                                        <Typography align="center" fontSize={`0.95rem`}>
                                            ไม่พบข้อมูล
                                        </Typography>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </> : <PageLoading />
    )
}

export default ManageLottoById;