import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import { useEffect, useState, ChangeEvent } from "react";
import { Typography, Pagination } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import moment from "moment";
import Swal from "sweetalert2";
import { lottoBillType } from "../../types";
import { useParams } from "react-router-dom";
import LottoBillTab from "./LottoBillTab";

const LottoBillByPeriodId = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const { lotto_id, period_id } = useParams();

    const [listBills, setListBills] = useState<lottoBillType[]>([]);
    const [countBills, setCountBills] = useState<number | null>(null);

    const [tabOpen, setTabOpen] = useState<boolean>(false);
    const [selectedBillId, setSelectedBillId] = useState<number>(0);

    const handleChangePage = (_e: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        getBill();
    }, [lotto_id, period_id, page, limit]);

    const getBill = async () => {
        if(loading || !lotto_id || !period_id){ return; }

        setLoading(true);
        try {
            const service = new ApiService();
            const getBills = await service.getBills(cookies.get('access_token'), parseInt(lotto_id), parseInt(period_id), page, limit);

            if(getBills.data.status_code === 200)
            {
                setCountBills(getBills.data.countBills);
                setListBills(getBills.data.bills);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
        setLoading(false);
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            <LottoBillTab tabOpen={tabOpen} setTabOpen={setTabOpen} billId={selectedBillId} />
            <div className="col-12 mb-3">
                <div className="card p-2">
                    <div className="row">
                        <div className="col-sm-6 col-xl-6" style={{ display: `flex`, alignContent: `center`, alignItems: `center` }}>
                        </div>
                        <div className="col-sm-6 col-xl-6" style={{ 
                            display: `flex`,
                            justifyContent: `flex-end`,
                            alignContent: `center`,
                            alignItems: `center`
                        }}>
                            แสดง:&nbsp;
                            <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                style={{
                                    maxHeight: 40,
                                    maxWidth: 100
                                }}
                                defaultValue={`10`}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    if(!e.target)
                                    {
                                        return;
                                    }

                                    setLimit(parseInt(e.target.value));
                                }}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option value="60">60</option>
                                <option value="70">70</option>
                                <option value="80">80</option>
                                <option value="90">90</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="300">300</option>
                                <option value="400">400</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card p-2">
                    <div className="row g-2">
                        <div className="col-sm-12 col-xl-6" style={{
                            display: 'flex',
                            alignContent: `center`,
                            alignItems: `center`
                        }}>
                            <Pagination
                                count={Math.ceil((countBills === null ? 0 : countBills) / limit)}
                                color="primary"
                                sx={{ color: 'white'}}
                                onChange={handleChangePage}
                                page={page}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="table-responsive">
                    <table className="table">
                        <thead className="border-top">
                            <tr>
                                <th align="center">
                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                        #
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                        เจ้าของบิล
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                        จำนวนรายการ
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                        ยอดซื้อทั้งหมด
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                        ดูบิล
                                    </Typography>
                                </th>
                                {/* <th align="center">
                                    <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                        รูปภาพ
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                        ชื่อหวย
                                    </Typography>
                                </th> */}
                                {/* <th align="center">
                                    <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                        จัดการ
                                    </Typography>
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listBills.length > 0 ? listBills.map((element: lottoBillType, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Typography align="center" fontSize={`0.95rem`}>
                                                    {
                                                        element.id
                                                    }
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography align="center" fontSize={`0.95rem`}>
                                                    {
                                                        element.member_id
                                                    }
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography align="center" fontSize={`0.95rem`}>
                                                    {
                                                        JSON.parse(element.bills).length
                                                    }
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography align="center" fontSize={`0.95rem`}>
                                                    ฿{
                                                        parseFloat(
                                                            String(JSON.parse(element.bills).reduce((acc: any, item: any) => acc + item.price, 0))
                                                        ).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                    }
                                                </Typography>
                                            </td>
                                            <td style={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                                                <button className="btn btn-primary d-grid" onClick={() => {
                                                    setSelectedBillId(element.id);
                                                    setTabOpen(true);
                                                }}>ดู</button>
                                            </td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={5}>
                                        <Typography align="center" fontSize={`0.95rem`}>
                                            ไม่พบข้อมูล
                                        </Typography>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 mt-3">
                <div className="card p-2">
                    <div className="row g-2">
                        <div className="col-sm-12 col-xl-6" style={{
                            display: 'flex',
                            alignContent: `center`,
                            alignItems: `center`
                        }}>
                            <Pagination
                                count={Math.ceil((countBills === null ? 0 : countBills) / limit)}
                                color="primary"
                                sx={{ color: 'white'}}
                                onChange={handleChangePage}
                                page={page}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LottoBillByPeriodId;