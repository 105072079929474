import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SoundCashInEffect from './../assets/sound/cashprize.wav';

const socket = io('https://api.betwon.com');
const SocketIO = () => {

    const PlayScbCashInEffect = () => {
        const audio = new Audio(SoundCashInEffect);
        audio.currentTime = 0;
        audio.volume = 0.5;
        audio.play();
    };

    useEffect(() => {
        socket.on('message', (msg) => {
            if(msg.type == 'scb_deposit')
            {
                if(msg.match)
                {
                    toast.success(() => (
                        <>
                            <h6 style={{ margin: 0, padding: 0, marginBottom: 5 }}>
                                <b>มีรายการ SCB ใหม่ (MATCH: {msg.match.ufa_username})</b>
                            </h6>
                            ชื่อบัญชี: {msg.data.bank_account_name}
                            <br/>
                            เลขบัญชี: {msg.data.bank_number}
                            <br/>
                            ธนาคาร: {msg.data.bank_name}
                            <br/>
                            จำนวน: ฿{
                                parseFloat(String(msg.data.amount).replace(',', '')).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                        </>
                    ));
                }
                else
                {
                    toast.info(() => (
                        <>
                            <h6 style={{ margin: 0, padding: 0, marginBottom: 5 }}>
                                <b>มีรายการ SCB ใหม่ (NOT MATCH)</b>
                            </h6>
                            ชื่อบัญชี: {msg.data.bank_account_name}
                            <br/>
                            เลขบัญชี: {msg.data.bank_number}
                            <br/>
                            ธนาคาร: {msg.data.bank_name}
                            <br/>
                            จำนวน: ฿{
                                parseFloat(String(msg.data.amount).replace(',', '')).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                        </>
                    ));
                }

                PlayScbCashInEffect();
            }

            toast.play()
        });

        // Cleanup
        return () => {
            socket.off('message');
        };
    }, []);

    return (
        <>
            <ToastContainer />
        </>
    );
};

export default SocketIO;
