import { Typography, Box } from "@mui/material";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import { listTopNumberType } from "../../types";
import PageLoading from "../PageLoading/PageLoading";

const TopNumber = () => {
    const cookies = new Cookies(null, { path: '/' });
    const { lotto_id, period_id } = useParams();
    const [listTopNumber, setListTopNumber] = useState<listTopNumberType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        if(!lotto_id || !period_id)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getTopnumber = await service.getTopnumberLotto(cookies.get('access_token'), lotto_id, period_id);

            if(getTopnumber.data.status_code === 200)
            {
                setListTopNumber(getTopnumber.data.top_number);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        !loading ? <>
            <div className="card">
                <Box sx={{ px: 2, pt: 1 }}>
                    <Typography fontSize={18}>
                        ตัวเลขที่ซื้อมากที่สุด
                    </Typography>
                </Box>
                <div className="table-responsive mt-3">
                    <table className="table">
                        <thead className="border-top">
                            <tr>
                                <th align="center">
                                    <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                        เลข
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                        ประเภท
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                        จำนวนรายการ
                                    </Typography>
                                </th>
                                <th align="center">
                                    <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                        จำนวนเงิน
                                    </Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listTopNumber.sort((a: listTopNumberType, b: listTopNumberType) => b.total_price - a.total_price).map((element: listTopNumberType, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                    {
                                                        element.number
                                                    }
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                    {
                                                        String(element.amountNumberState) === "3" && String(element.subType) === "1"
                                                        ? "3 ตัวบน"
                                                        : String(element.amountNumberState) === "3" && String(element.subType) === "3"
                                                        ? "3 ตัวบนโต๊ด"
                                                        : String(element.amountNumberState) === "2" && String(element.subType) === "1"
                                                        ? "2 ตัวบน"
                                                        : String(element.amountNumberState) === "2" && String(element.subType) === "3"
                                                        ? "2 ตัวล่าง"
                                                        : String(element.amountNumberState) === "4" && String(element.subType) === "1"
                                                        ? "วิ่งบน"
                                                        : String(element.amountNumberState) === "4" && String(element.subType) === "2"
                                                        ? "วิ่งล่าง"
                                                        : `${element.amountNumberState}-${element.subType}`
                                                    }
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                    {
                                                        element.count
                                                    }
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                    ฿{
                                                        parseFloat(element.total_price.toString()).toLocaleString('en-US', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })
                                                    }
                                                </Typography>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {/*  */}
                        </tbody>
                    </table>
                </div>
            </div>
        </> : <PageLoading />
    )
}

export default TopNumber;