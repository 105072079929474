import { Grid, Typography, Box, Divider } from "@mui/material";
import { memberInfoType } from "../../types";

const calculateTimeDifference = (startDate: Date, endDate: Date) => {
    const diffMs = endDate.getTime() - startDate.getTime();
    const diffHrs = Math.floor(diffMs / 3600000);
    const diffMins = Math.floor((diffMs % 3600000) / 60000);
    // const diffSecs = Math.floor((diffMs % 60000) / 1000);

    if(diffHrs >= 24)
    {
        return `${String(parseFloat(String(diffHrs / 24)).toLocaleString('en-US', { maximumFractionDigits: 0 }))} วันที่ผ่านมา`;
    }
    else if(diffHrs === 0 && diffMins === 0)
    {
        return `เมื่อกี้`;
    }
    else
    {
        return `${diffHrs} ชั่วโมง ${diffMins} นาที ที่ผ่านมา`;
    }
};

interface propsType {
    memberInfo: memberInfoType | null;
}

const MemberInfoDevice = ({ memberInfo }: propsType) => {
    const now = new Date();
    const timeDifference = calculateTimeDifference(memberInfo !== null ? new Date(memberInfo.createdAt) : now, now);
    const timeDifferenceLastestLogin = calculateTimeDifference(memberInfo !== null ? new Date(!memberInfo.lastest_login ? memberInfo.createdAt : memberInfo.lastest_login) : now, now);

    return (
        <Grid item xs={12} sm={12} md={4} lg={3}>
            {
                memberInfo !== null ? <Box width={`100`} sx={
                    {
                        backgroundColor: 'transparent',
                        border: 'solid 1px rgba(255, 255, 255, .4)',
                        borderRadius: 2,
                        p: 2
                    }
                }>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography display={`flex`} justifyContent={`flex-end`} color={`white`} fontSize={14}>
                                การสมัคร
                            </Typography>
                            <Typography display={`flex`} justifyContent={`flex-start`} color={`white`} fontSize={14} lineHeight={2}>
                                เครื่องที่ใช้สมัคร: {memberInfo.reg_ip}
                            </Typography>
                            <Typography display={`flex`} justifyContent={`flex-start`} color={`white`} fontSize={14} lineHeight={2}>
                                วันเวลาที่สมัคร: {
                                    <>
                                        {
                                            `${String(new Date(String(memberInfo.createdAt)).getDate())}/${String(new Date(String(memberInfo.createdAt)).getMonth())}/${String(new Date(String(memberInfo.createdAt)).getFullYear())}`
                                        }
                                        &nbsp;
                                        {
                                            `${String(new Date(String(memberInfo.createdAt)).getHours())}:${String(new Date(String(memberInfo.createdAt)).getMinutes())}`
                                        }
                                    </>
                                }
                            </Typography>
                            <Typography display={`flex`} justifyContent={`flex-start`} color={`white`} fontSize={14} lineHeight={2}>
                                เวลาที่ผ่านมา: {timeDifference}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ borderColor: 'rgba(255, 255, 255, .4)' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography display={`flex`} justifyContent={`flex-end`} color={`white`} fontSize={14}>
                                Login ล่าสุด
                            </Typography>
                            <Typography display={`flex`} justifyContent={`flex-start`} color={`white`} fontSize={14} lineHeight={2}>
                                เครื่องที่ใช้เข้าสู่ระบบล่าสุด: {memberInfo.lastest_ip === null ? memberInfo.reg_ip : memberInfo.lastest_ip}
                            </Typography>
                            <Typography display={`flex`} justifyContent={`flex-start`} color={`white`} fontSize={14} lineHeight={2}>
                                วันที่เข้าสู่ระบบล่าสุด: {
                                    memberInfo.lastest_login === null ? <>
                                        {
                                            `${String(new Date(String(memberInfo.createdAt)).getDate())}/${String(new Date(String(memberInfo.createdAt)).getMonth())}/${String(new Date(String(memberInfo.createdAt)).getFullYear())}`
                                        }
                                        &nbsp;
                                        {
                                            `${String(new Date(String(memberInfo.createdAt)).getHours())}:${String(new Date(String(memberInfo.createdAt)).getMinutes())}`
                                        }
                                    </> : <>
                                        {
                                            `${String(new Date(String(memberInfo.lastest_login)).getDate())}/${String(new Date(String(memberInfo.lastest_login)).getMonth())}/${String(new Date(String(memberInfo.lastest_login)).getFullYear())}`
                                        }
                                        &nbsp;
                                        {
                                            `${String(new Date(String(memberInfo.lastest_login)).getHours())}:${String(new Date(String(memberInfo.lastest_login)).getMinutes())}`
                                        }
                                    </>
                                }
                            </Typography>
                            <Typography display={`flex`} justifyContent={`flex-start`} color={`white`} fontSize={14} lineHeight={2}>
                                เวลาที่ผ่านมา: {timeDifferenceLastestLogin}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box> : null
            }
        </Grid>
    )
}

export default MemberInfoDevice;