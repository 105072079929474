import { useEffect, useState, ChangeEvent } from "react";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import { addOrRemoveLogType } from "../../types";

import {
    Grid,
    Box,
    Typography,
    Paper,
    Divider,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Pagination
} from "@mui/material";

const LogAddCredit = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [countLogs, setCountLogs] = useState<number>(0);
    const [logs, setLogs] = useState<addOrRemoveLogType[] | null>(null);

    useEffect(() => {
        getLogs();
    }, [page]);

    const getLogs = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getLogAddCredit = await service.getLogAddCredit(cookies.get('access_token'), page, limit);

            if(getLogAddCredit.data.status_code === 200)
            {
                setLogs(getLogAddCredit.data.data);
                setCountLogs(getLogAddCredit.data.count);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const handleChangePage = (_event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper sx={{ background: '#181818' }}>
                    <Box p={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography display={`flex`} justifyContent={`center`} color={`white`}>
                                    รายการเพิ่มเครดิตโดยแอดมิน
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ borderColor: 'white', mb: 2 }} />
                            </Grid>
                            {
                                loading ? <Grid item xs={12} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                    <CircularProgress size={24} sx={
                                        {
                                            color: 'white'
                                        }
                                    } />
                                </Grid> : <>
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper} sx={{ backgroundColor: '#181818' }}>
                                            <Table>
                                                <TableHead sx={{ backgroundColor: '#000000' }}>
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ชื่อผู้ใช้งาน UFA
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center" colSpan={3}>
                                                            <Typography color={`white`}>
                                                                เครดิต
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                เพิ่มโดย
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                วันที่และเวลา
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                IP ADDRESS
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell />
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                ก่อนเพิ่ม
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                เพิ่ม
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography color={`white`}>
                                                                หลังเพิ่ม
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        logs !== null && logs.length > 0 ? logs.sort((a: addOrRemoveLogType, b: addOrRemoveLogType) => {
                                                            const dateA = new Date(`${String(a.createdAt)} ${String(a.createdAt)}`).getTime();
                                                            const dateB = new Date(`${String(b.createdAt)} ${String(b.createdAt)}`).getTime();
                                                            return dateB - dateA;
                                                        }).map((element: addOrRemoveLogType, index: number) => {
                                                            return (
                                                                <TableRow key={`log-${index}`}>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            { element.member_ufa_username }
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {
                                                                                element.res_update_credit !== null ? <>
                                                                                    {
                                                                                        JSON.parse(element.res_update_credit).depositResultUpdate.data !== undefined ? JSON.parse(element.res_update_credit).depositResultUpdate.data.allCredit : "0"
                                                                                    }
                                                                                </> : '0'
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={element.res_update_credit !== null && JSON.parse(element.res_update_credit).depositResultUpdate.data.amount !== undefined ? "#05c855" : "#f4ce5c"}>
                                                                            +{
                                                                                element.res_update_credit !== null ? <>
                                                                                    {
                                                                                        JSON.parse(element.res_update_credit).depositResultUpdate.data !== undefined ? JSON.parse(element.res_update_credit).depositResultUpdate.data.amount : "0"
                                                                                    }
                                                                                </> : "0"
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            {
                                                                                element.res_update_credit !== null ? <>
                                                                                    {
                                                                                        JSON.parse(element.res_update_credit).depositResultUpdate.data !== undefined ? JSON.parse(element.res_update_credit).depositResultUpdate.data.new_credit : "0"
                                                                                    }
                                                                                </> : '0'
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography color={`white`}>
                                                                            { element.admin_username }
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography sx={
                                                                            {
                                                                                color: 'white'
                                                                            }
                                                                        }>
                                                                            {
                                                                                `${String(new Date(String(element.createdAt)).getDate())}/${String(new Date(String(element.createdAt)).getMonth())}/${String(new Date(String(element.createdAt)).getFullYear())}`
                                                                            }
                                                                            &nbsp;
                                                                            {
                                                                                `${String(new Date(String(element.createdAt)).getHours())}:${String(new Date(String(element.createdAt)).getMinutes())}`
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography sx={
                                                                            {
                                                                                color: 'white'
                                                                            }
                                                                        }>
                                                                            { element.ip_address }
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }) : <TableRow>
                                                            <TableCell align="center" colSpan={7}>
                                                                <Typography color={`white`}>
                                                                    ไม่พบข้อมูล
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Pagination
                                            count={Math.ceil((countLogs === null ? 0 : countLogs) / limit)}
                                            color="primary"
                                            sx={{ color: 'white'}}
                                            onChange={handleChangePage}
                                            page={page}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default LogAddCredit;