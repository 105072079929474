import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { adminProfileType } from "../../types";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import { IconButton, Typography } from "@mui/material";

interface PropsType {
    handleToggleOpen: (state: boolean) => void;
    isOpen: boolean;
}

const Header = ({ handleToggleOpen, isOpen }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [profile, setProfile] = useState<adminProfileType | null>(null);
    const [theme, setTheme] = useState<string>('light');

    useEffect(() => {
        loadProfile();
    }, []);

    useEffect(() => {
        let savedTheme = localStorage.getItem('theme');
        if(!savedTheme)
        {
            savedTheme = 'light';
            localStorage.setItem('theme', 'light');
        }

        setTheme(savedTheme);
    }, []);

    const loadProfile = async () => {
        try {
            const service = new ApiService();
            const getProfile = await service.getProfile(cookies.get('access_token'));

            setProfile({
                username: getProfile.data.username,
                name: getProfile.data.name
            });
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangeTheme = () => {
        const current_theme = localStorage.getItem('theme');
        if(current_theme === 'light')
        {
            localStorage.setItem('theme', 'dark')
        }
        else if(current_theme === 'dark')
        {
            localStorage.setItem('theme', 'light')
        }
        else
        {
            localStorage.setItem('theme', 'light')
        }

        window.location.reload();
    }

    return (
        <div className="d-flex mb-3" style={{ justifyContent: 'space-between', alignContent: `center`, alignItems: `center`, color: 'black' }}>
            <div>
                <IconButton onClick={() => handleToggleOpen(isOpen ? false : true)} sx={
                    {
                        color: localStorage.getItem('theme') !== 'dark' ? `#1976d2` : `white`,
                        backgroundColor: 'rgba(255, 255, 255, .0)'
                    }
                }>
                    <i className="bx bx-sm bx-align-left"></i>
                    <Typography fontSize={14}>
                        {isOpen ? "ซ่อน" : "เปิด"}เมนู
                    </Typography>
                </IconButton>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: `center`, alignItems: `center`, color: 'black' }}>
                <i className={`bx bx-${theme === 'light' ? 'moon' : 'sun'} me-2 text-light`} style={{ fontSize: 24, cursor: 'pointer' }} onClick={handleChangeTheme}></i>
                <Link to="/profile">
                    <div className="avatar avatar-md me-2">
                        <span className="avatar-initial rounded-circle shadow bg-success">{profile?.username.slice(0, 2)}</span>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Header;