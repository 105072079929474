import { useState, useEffect, ChangeEvent } from "react";
import { adminPermissionsType, bankAccountListType } from "../../types";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

const ManageBankAccountMain = () => {
    const cookies = new Cookies(null, { path: '/' });
    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    const [loading, setLoading] = useState<boolean>(false);
    const [bankAccount, setBankAccount] = useState<bankAccountListType[]>([]);

    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        getBankAccount();
    }, []);

    const getBankAccount = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getAccount = await service.getBankAccount(cookies.get('access_token'));

            if(getAccount.data.status_code === 200)
            {
                setBankAccount(getAccount.data.bank_account_list);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด (${getAccount.data.status_code} - ${getAccount.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const updateCondition = async (bank_id: number, value: string) => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const updateCon = await service.updateBankCondition(cookies.get('access_token'), bank_id, value);

            if(updateCon.data.status_code === 200)
            {
                const service = new ApiService();
                const getAccount = await service.getBankAccount(cookies.get('access_token'));

                if(getAccount.data.status_code === 200)
                {
                    setBankAccount(getAccount.data.bank_account_list);
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: `เกิดข้อผิดพลาด (${getAccount.data.status_code} - ${getAccount.data.message})`
                    });
                }
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด (${updateCon.data.status_code} - ${updateCon.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const updateConditionValue = async (bank_id: number) => {
        if(loading)
        {
            return;
        }

        const elementInput = document.getElementById(`value_condition_bank_${bank_id}`) as HTMLInputElement;
        if(elementInput)
        {
            setLoading(true);
            try {
                const service = new ApiService();
                const updateCon = await service.updateBankConditionValue(cookies.get('access_token'), bank_id, elementInput.value);
    
                if(updateCon.data.status_code === 200)
                {
                    const service = new ApiService();
                    const getAccount = await service.getBankAccount(cookies.get('access_token'));
    
                    if(getAccount.data.status_code === 200)
                    {
                        setBankAccount(getAccount.data.bank_account_list);
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: `เกิดข้อผิดพลาด (${getAccount.data.status_code} - ${getAccount.data.message})`
                        });
                    }
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: `เกิดข้อผิดพลาด (${updateCon.data.status_code} - ${updateCon.data.message})`
                    });
                }
            } catch (err) {
                console.log(err);
            }
            setLoading(false);
        }
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.manange_bank_account ? <>
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="card-title mb-0">ตั้งค่าบัญชีรับเงิน</h5>
                            </div>
                            <div className="row p-2">
                                <div className="col-xs-12 col-md-8 mt-3">
                                    <h6 style={{ display: 'flex', justifyContent: 'center' }}>ตั้งค่าบัญชี</h6>
                                    <table className="datatables-users table">
                                        <thead className="border-top">
                                            <tr>
                                                <th align="center">ธนาคาร</th>
                                                <th align="center">ชื่อบัญชี</th>
                                                <th align="center">เลขบัญชี</th>
                                                <th align="center">เงื่อนไข</th>
                                                <th align="center">เงื่อนไข 2</th>
                                                <th align="center">อัพเดทเงื่อนไข</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bankAccount.length > 0 ? bankAccount.map((element: bankAccountListType, index: number) => {
                                                    return (
                                                        <tr key={`bank-list-${index}`}>
                                                            <td>
                                                                {
                                                                    element.bank_abbr
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.bank_account_name
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.bank_account_number
                                                                }
                                                            </td>
                                                            <td>
                                                                <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                                                    style={{
                                                                        maxWidth: '100%'
                                                                    }}
                                                                    defaultValue={`${element.condition}`}
                                                                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                                                        if(!e.target)
                                                                        {
                                                                            return;
                                                                        }

                                                                        // setLimit(parseInt(e.target.value));
                                                                        updateCondition(element.id, e.target.value);
                                                                    }}
                                                                >
                                                                    <option value="0">ไม่โชว์</option>
                                                                    <option value="1">สำหรับลูกค้าใหม่</option>
                                                                    <option value="2">สำหรับลูกค้าเก่า</option>
                                                                    <option value="3">สำหรับลูกค้า VIP</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <div className="input-group input-group-merge">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eff1f2' }}>
                                                                        {
                                                                            element.condition === 1 ? "ยอดฝาก <=" : (
                                                                                element.condition === 2 ? "ยอดฝาก >=" : "ไม่มีเงื่อนไข"
                                                                            )
                                                                        }
                                                                    </span>
                                                                    <input type="text" id={`value_condition_bank_${element.id}`} className="form-control"
                                                                        name={`value_condition_bank_${element.id}`} style={{
                                                                            textAlign: 'center'
                                                                        }} defaultValue={element.condition_value} readOnly={element.condition === 0} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                            <button type="button" className="btn btn-md btn-primary" onClick={() => {
                                                                updateConditionValue(element.id);
                                                            }} disabled={loading}>อัพเดท</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :  <tr>
                                                    <td align="center" colSpan={6}>
                                                        ไม่มีบัญชีในระบบ
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-xs-12 col-md-4 mt-3">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default ManageBankAccountMain;