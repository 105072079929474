import { ApiService } from "../../services/api.service";
import { memberInfoType } from "../../types";
import Cookies from "universal-cookie";
import { useEffect, useState, SyntheticEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Grid, Box, Paper, Typography, Divider, Tab, Tabs } from "@mui/material";

import MemberInfo from "./MemberInfo";
import MemberInfoDevice from "./MemberInfoDevice";
import MemberTransactionPayment from "./MemberTransactionPayment";
import MemberTransactionSCB from "./MemberTransactionSCB";

import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const isNumericString = (str: string) => {
    return /^\d+$/.test(str);
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`user-detail-${index}`}
            aria-labelledby={`user-detail-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const a11yProps = (index: number) => {
    return {
      id: `user-detail-${index}`,
      'aria-controls': `user-detail-${index}`,
    };
}

const MemberDetail = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [memberInfo, setMemberInfo] = useState<memberInfoType | null>(null);
    const navigate = useNavigate();
    const { member_id } = useParams();
    const [value, setValue] = useState<number>(0);

    useEffect(() => {
        getMember();
    }, []);

    const getMember = async () => {
        if(!isNumericString(String(member_id)))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'ไม่พบผู้ใช้งานนี้'
            }).then(() => {
                navigate('/member');
            });
        }

        try {
            const getMemberId = parseInt(String(member_id));
            const service = new ApiService();
            const getMember = await service.getMember(cookies.get('access_token'), getMemberId);

            if(getMember.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `ไม่สามารถดึงข้อมูลผู้ใช้งานได้ในขณะนี้ (Err: ${getMember.data.message || "ไม่ทราบข้อผิดพลาด"})`
                }).then(() => {
                    navigate('/member');
                });
            }

            setMemberInfo(getMember.data.member);
            setLoaded(true);
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangeTab = (_event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper sx={{ background: '#181818' }}>
                    <Box p={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography display={`flex`} justifyContent={`center`} color={`white`}>
                                    ข้อมูลของสมาชิก { memberInfo !== null && memberInfo.ufa_username }
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ borderColor: 'white', mb: 2 }} />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    loaded ? <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={value} onChange={handleChangeTab} aria-label="Member tab menu" textColor="inherit">
                                                    <Tab icon={<PermContactCalendarIcon sx={{ fontSize: 20 }} />} label="รายละเอียดสมาชิก" sx={{ color: 'white', fontSize: 14 }} {...a11yProps(0)} />
                                                    <Tab icon={<PointOfSaleIcon sx={{ fontSize: 20 }} />} label="ประวัติการเงิน PAYMENT" sx={{ color: 'white', fontSize: 14 }} {...a11yProps(1)} />
                                                    <Tab icon={<PointOfSaleIcon sx={{ fontSize: 20 }} />} label="ประวัติการเงิน SCB" sx={{ color: 'white', fontSize: 14 }} {...a11yProps(2)} />
                                                </Tabs>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTabPanel value={value} index={0}>
                                                <Grid container spacing={2}>
                                                    <MemberInfo memberInfo={memberInfo} />
                                                    <MemberInfoDevice memberInfo={memberInfo} />
                                                </Grid>
                                            </CustomTabPanel>
                                            <CustomTabPanel value={value} index={1}>
                                                <MemberTransactionPayment memberInfo={memberInfo} />
                                            </CustomTabPanel>
                                            <CustomTabPanel value={value} index={2}>
                                                <MemberTransactionSCB memberInfo={memberInfo} />
                                            </CustomTabPanel>
                                        </Grid>
                                        
                                    </Grid> : <Typography align="center" color={`white`}>กำลังดึงข้อมูล...</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default MemberDetail;