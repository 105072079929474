import { ApiService } from "../../services/api.service";
import { adminPermissionsType, lottosType } from "../../types";
import Cookies from "universal-cookie";
import { useEffect, useState, ChangeEvent } from "react";
import { Typography, Pagination } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { Link, useNavigate } from "react-router-dom";
import CreateLottoTab from "./CreateLottoTab";
import Img300 from './../../assets/images/300.svg';
import { useOutletContext } from "../../OutletContext";
import moment from "moment";
import Swal from "sweetalert2";
import EditLottoTab from "./EditLottoTab";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ManageLotto = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [countLottos, setCountLottos] = useState<number | null>(null);
    const [lottos, setLottos] = useState<lottosType[]>([]);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [tabOpen, setTabOpen] = useState<boolean>(false);
    const [tabOpenEdit, setTabOpenEdit] = useState<boolean>(false);
    const [editLottoId, setEditLottoId] = useState<number | null>(null);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        getLottos();
    }, [page, limit]);

    useEffect(() => {
        setEditLottoId(null);
    }, [tabOpenEdit]);

    const getLottos = async () => {
        if(loading)
        {
            return;
        }

        try {
            setLoading(true);
            const service = new ApiService();
            const getLottos = await service.getLottos(cookies.get('access_token'), page, limit);

            if(getLottos.data.status_code === 200)
            {
                setCountLottos(getLottos.data.countLottos)
                setLottos(getLottos.data.lottos);
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangePage = (_e: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;
    
        const updatedItems = Array.from(lottos);
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);
        const updatedItemsWithIds = updatedItems.map((item, index) => ({
            ...item,
            sort: index + 1,
        }));

        Swal.fire({
            icon: 'warning',
            title: 'แจ้งเตือน',
            text: 'คุณยืนยันที่จะจัดเรียงหวยใหม่ใช่หรือไม่',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน'
        }).then(async (res) => {
            if(res.isConfirmed)
            {
                setLoading(true);
                try {
                    const service = new ApiService();
                    const updateSort = await service.updateLottoSort(cookies.get('access_token'), updatedItemsWithIds);

                    if(updateSort.data.status_code === 200)
                    {
                        setLottos(updatedItemsWithIds);
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: 'อัพเดทการจัดเรียงไม่สำเร็จ'
                        });
                    }
                } catch (err) {
                    console.log(err)
                }
                setLoading(false);
            }
        })
    };

    const handleDeleteLotto = async (id: number) => {
        try {
            Swal.fire({
                icon: 'warning',
                title: "แจ้งเตือน",
                text: 'คุณยืนยันที่จะลบใช่หรือไม่?',
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: `ยกเลิก`
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setLoading(true);
                    const service = new ApiService();
                    const deleteLotto = await service.deleteLotto(cookies.get('access_token'), id);

                    if(deleteLotto.data.status_code === 200)
                    {
                        setLoading(false);
                        getLottos();
                        return Swal.fire({
                            icon: 'success',
                            title: 'แจ้งเตือน',
                            text: 'ลบหวยออกเรียบร้อยแล้ว'
                        });
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: `ลบหวยไม่สำเร็จ (${deleteLotto.data.message})`
                    });
                    setLoading(false);
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    const handleEditLotto = (id: number) => {
        setEditLottoId(id);
        setTabOpenEdit(true);
    }

    const handleToggleDisableLotto = async (id: number, disable: number) => {
        setLoading(true);
        try {
            const service = new ApiService();
            const updateToggle = await service.toggleDisableLotto(cookies.get('access_token'), id, disable);

            if(updateToggle.data.status_code === 200)
            {
                getLottos();
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: disable === 1 ? "ปิดการใช้งานเรียบร้อยแล้วค่ะ" : "เปิดการใช้งานเรียบร้อยแล้วค่ะ"
                });
            }
            else
            {
                getLottos();
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: disable === 1 ? `ปิดการใช้งานไม่สำเร็จค่ะ (${updateToggle.data.message})` : `เปิดการใช้งานไม่สำเร็จค่ะ (${updateToggle.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.promotion && !loading && lottos.length > 0 ? <>
                    <CreateLottoTab setTabOpen={setTabOpen} tabOpen={tabOpen} reload={getLottos} />
                    <EditLottoTab setTabOpen={setTabOpenEdit} tabOpen={tabOpenEdit} reload={getLottos} lotto_id={editLottoId} />
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row">
                                <div className="col-sm-6 col-xl-6" style={{ display: `flex`, alignContent: `center`, alignItems: `center` }}>
                                    <button className="btn btn-primary d-grid" onClick={() => setTabOpen(!tabOpen)}>เพิ่มหวย</button>
                                </div>
                                <div className="col-sm-6 col-xl-6" style={{ 
                                    display: `flex`,
                                    justifyContent: `flex-end`,
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    แสดง:&nbsp;
                                    <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                        style={{
                                            maxHeight: 40,
                                            maxWidth: 100
                                        }}
                                        defaultValue={`10`}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            if(!e.target)
                                            {
                                                return;
                                            }

                                            setLimit(parseInt(e.target.value));
                                        }}
                                        value={limit}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="70">70</option>
                                        <option value="80">80</option>
                                        <option value="90">90</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <Pagination
                                        count={Math.ceil((countLottos === null ? 0 : countLottos) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="table-responsive">
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="droppable-table">
                                    {(provided) => (
                                        <table {...provided.droppableProps} ref={provided.innerRef} className="table">
                                            <thead className="border-top">
                                                <tr>
                                                    <th align="center">
                                                        <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                            #
                                                        </Typography>
                                                    </th>
                                                    <th align="center">
                                                        <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                                            รูปภาพ
                                                        </Typography>
                                                    </th>
                                                    <th align="center">
                                                        <Typography fontSize={`0.75rem`} fontWeight={`bold`}>
                                                            ชื่อหวย
                                                        </Typography>
                                                    </th>
                                                    <th align="center">
                                                        <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                            จัดการ
                                                        </Typography>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lottos.length > 0 ? lottos.sort((a: lottosType, b: lottosType) => a.sort - b.sort).map((element: lottosType, index: number) => {
                                                        return (
                                                            <Draggable key={element.id.toString()} draggableId={element.id.toString()} index={index}>
                                                                {(provided) => (
                                                                    <tr
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                    >
                                                                        <td>
                                                                            <Typography align="center" fontSize={`0.95rem`}>
                                                                                {
                                                                                    element.id
                                                                                }
                                                                            </Typography>
                                                                        </td>
                                                                        <td>
                                                                            <img src={`${process.env.REACT_APP_API_URL}/image/${element.img_path}`} style={{ maxWidth: 25 }} />
                                                                        </td>
                                                                        <td>
                                                                            <Typography fontSize={`0.95rem`} fontWeight={`bold`}>
                                                                                {
                                                                                    element.name
                                                                                }
                                                                            </Typography>
                                                                        </td>
                                                                        <td style={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                                                                            <button className="btn btn-primary d-grid" onClick={() => navigate(`/managelotto/${element.id}`)}>จัดการงวด</button>
                                                                            <button className="btn btn-warning d-grid" onClick={() => handleEditLotto(element.id)}>แก้ไข</button>
                                                                            <button className={`btn btn-${element.disable === 0 ? "danger" : "success"} d-grid`} onClick={() => handleToggleDisableLotto(element.id, element.disable === 0 ? 1 : 0)}>
                                                                                {
                                                                                    element.disable === 0 ? "ปิดใช้งาน" : "เปิดใช้งาน"
                                                                                }
                                                                            </button>
                                                                            <button className="btn btn-danger d-grid" onClick={() => handleDeleteLotto(element.id)}>ลบหวย</button>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    }) : <tr>
                                                        <td colSpan={5}>
                                                            <Typography align="center" fontSize={`0.95rem`}>
                                                                ไม่พบข้อมูล
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                }
                                            {provided.placeholder}
                                            </tbody>
                                        </table>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <Pagination
                                        count={Math.ceil((countLottos === null ? 0 : countLottos) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default ManageLotto;