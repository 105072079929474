import { useEffect, useState, ChangeEvent, useRef, FormEvent } from "react";
import { allReportType, adminPermissionsType, reportMemberType, reportAffiliateType } from '../../types';
import { ApiService } from '../../services/api.service';
import Cookies from "universal-cookie";
import { Typography, Pagination } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditMember from "../Members/EditMember";
import { Link } from "react-router-dom";

const formatDate = (date: Dayjs | null) => {
    if (!date) return '';
    return date.format('DD-MM-YYYY');
};

const ReportAffiliate = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<reportAffiliateType[]>([]);
    const [valueStartDate, setValueStartDate] = useState<Dayjs | null>(dayjs());
    const [valueEndDate, setValueEndDate] = useState<Dayjs | null>(dayjs());

    const [selectMemberId, setSelectMemberId] = useState<number | null>(null);
    const [tabOpen, setTabOpen] = useState<boolean>(false);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        reloadData();
    }, []);

    const reloadData = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const start_date = formatDate(valueStartDate);
            const end_date = formatDate(valueEndDate);

            const service = new ApiService();
            const getReport = await service.getReportAffiliateData(cookies.get('access_token'), start_date, end_date);
            
            if(getReport.data.status_code === 200)
            {
                setReportData(getReport.data.data);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.report_all ? <>
                    <EditMember tabOpen={tabOpen} setTabOpen={setTabOpen} member_id={selectMemberId} />
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row">
                                <div className="col mb-2">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={valueStartDate}
                                            onChange={(newValue) => setValueStartDate(newValue)}
                                            sx={
                                                {
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        height: '40px', // ความสูงของ Input Base
                                                        fontSize: 14,
                                                    },
                                                    '& .MuiPickersPopper-container': {
                                                        maxHeight: '40px', // ความสูงสูงสุดของ Popper
                                                    },
                                                }
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                                popper: {
                                                  disablePortal: true,
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                            defaultValue={dayjs()}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col mb-2">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={valueEndDate}
                                            onChange={(newValue) => setValueEndDate(newValue)}
                                            sx={
                                                {
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        height: '40px', // ความสูงของ Input Base
                                                        fontSize: 14,
                                                    },
                                                    '& .MuiPickersPopper-container': {
                                                        maxHeight: '40px', // ความสูงสูงสุดของ Popper
                                                    },
                                                }
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                                popper: {
                                                  disablePortal: true,
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                            defaultValue={dayjs()}
                                            minDate={valueStartDate || dayjs()}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-sm-12 col-md-2">
                                    <button className="btn btn-primary w-100" onClick={reloadData} disabled={loading}>ค้นหา</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-datatable table-responsive">
                            <table className="datatables-users table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">ชื่อผู้ใช้</th>
                                        <th align="center">ชื่อ-นามสกุล</th>
                                        <th align="center">จำนวนที่ชวน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportData && reportData.length > 0 ? <>
                                            {
                                                reportData.map((element: reportAffiliateType, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Link to={`/report-affiliate`} onClick={() => {
                                                                    setSelectMemberId(element.member_id);
                                                                    setTabOpen(true);
                                                                }}>
                                                                    {
                                                                        element.ufa_username
                                                                    }
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.fullname
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    parseFloat(String(element.affiliate_count)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                } คน
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td colSpan={2}>
                                                    สรุป
                                                </td>
                                                <td>
                                                    {
                                                        reportData.reduce((accumulator: number, currentValue: reportAffiliateType) => {
                                                            return accumulator + parseFloat(`${currentValue.affiliate_count}`);
                                                        }, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                    } คน
                                                </td>
                                            </tr>
                                        </> :
                                            <tr>
                                                <td colSpan={13}>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        ไม่พบข้อมูล
                                                    </Typography>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default ReportAffiliate;