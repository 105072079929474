import { Box, CircularProgress, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { lottoBillOnceType } from "../../types";

interface PropsType {
    tabOpen: boolean,
    setTabOpen: (state: boolean) => void,
    billId: number
}

const LottoBillTab = ({ tabOpen, setTabOpen, billId }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});

    const [loading, setLoading] = useState<boolean>(false);
    const [bill, setBill] = useState<lottoBillOnceType | null>(null);

    useEffect(() => {
        if(billId === 0 || !setTabOpen)
        {
            setBill(null);
            return;
        }

        loadBill();
    }, [billId]);

    const loadBill = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getBill = await service.getBillById(cookies.get('access_token'), billId);

            if(getBill.data.status_code === 200)
            {
                setBill(getBill.data.bill);
                setLoading(false);
            }
            else
            {
                setLoading(false);
                setTabOpen(false);
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `ไม่สามารถโหลดบิลได้ (${getBill.data.status_code} - ${getBill.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
        setLoading(false);
    }

    return (
        bill ? <>
            <Box sx={
                {
                    display: 'block',
                    maxWidth: {
                        xs: `90%`,
                        sm: `90%`,
                        md: `50%`,
                        lg: `30%`
                    },
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: (tabOpen && bill) ? 0 : -3000,
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: 10000000,
                    color: 'black',
                    transition: 'all .3s',
                    borderLeft: 'solid 3px #e0e3e6',
                    overflowY: 'scroll'
                }
            }>
                <Box p={2}>
                    <div className="row">
                        {
                            loading ? <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                <CircularProgress />
                            </Box> : <>
                                <div className="col-6">
                                    <Typography fontSize={18} fontWeight={`bold`}>
                                        บิล #{bill.uuid.slice(0, 15)}...
                                    </Typography>
                                </div>
                                <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className="btn btn-primary" onClick={() => setTabOpen(false)}>ปิด</button>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
                                    <div className="card p-2">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="border-top">
                                                    <tr>
                                                        <th align="center">
                                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                                เลข
                                                            </Typography>
                                                        </th>
                                                        <th align="center">
                                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                                ประเภท
                                                            </Typography>
                                                        </th>
                                                        <th align="center">
                                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                                ราคา
                                                            </Typography>
                                                        </th>
                                                        <th align="center">
                                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                                อัตราจ่าย
                                                            </Typography>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        JSON.parse(bill.bills).map((element: {
                                                            number: string,
                                                            subType: number,
                                                            amountNumberState: number,
                                                            price: number,
                                                            pay: number
                                                        }, index: number) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                                            {
                                                                                element.number
                                                                            }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                                            {element.amountNumberState === 3 && element.subType === 1
                                                                            ? "3 ตัวบน"
                                                                            : element.amountNumberState === 3 && element.subType === 3
                                                                            ? "3 ตัวบนโต๊ด"
                                                                            : element.amountNumberState === 2 && element.subType === 1
                                                                            ? "2 ตัวบน"
                                                                            : element.amountNumberState === 2 && element.subType === 3
                                                                            ? "2 ตัวล่าง"
                                                                            : element.amountNumberState === 4 && element.subType === 1
                                                                            ? "วิ่งบน"
                                                                            : element.amountNumberState === 4 && element.subType === 2
                                                                            ? "วิ่งล่าง"
                                                                            : element.amountNumberState}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                                            ฿{
                                                                                parseFloat(String(element.price)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                            }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                                            x{
                                                                                element.pay
                                                                            }
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {/* <tr key={index}>
                                                        <td>
                                                            <Typography align="center" fontSize={`0.95rem`}>
                                                                {
                                                                    element.id
                                                                }
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography align="center" fontSize={`0.95rem`}>
                                                                {
                                                                    element.member_id
                                                                }
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography align="center" fontSize={`0.95rem`}>
                                                                {
                                                                    JSON.parse(element.bills).length
                                                                }
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography align="center" fontSize={`0.95rem`}>
                                                                ฿{
                                                                    parseFloat(
                                                                        String(JSON.parse(element.bills).reduce((acc: any, item: any) => acc + item.price, 0))
                                                                    ).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                }
                                                            </Typography>
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Box>
            </Box>
            <Box sx={
                {
                    display: tabOpen ? 'block' : 'none',
                    maxWidth: `100%`,
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, .7)',
                    zIndex: 10000000 - 1,
                    transition: 'all .5s'
                }
            } onClick={() => setTabOpen(false)} />
        </> : null
    )
}

export default LottoBillTab;