import { useState, useEffect } from "react";
import { adminPermissionsType, settingsType, listPaymentType, agentListType } from "../../types";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

const SettingsMain = () => {
    const cookies = new Cookies(null, { path: '/' });
    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    const [loading, setLoading] = useState<boolean>(false);
    const [websiteSettings, setWebsiteSettings] = useState<settingsType | null>(null);
    const [paymentData, setPaymentData] = useState<listPaymentType[] | null>(null);
    const [agentData, setAgentData] = useState<agentListType[] | null>(null);

    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        reloadData();
    }, []);

    const reloadData = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getSettings = await service.getWebsiteSettings(cookies.get('access_token'));
            
            if(getSettings.data.status_code === 200)
            {
                setWebsiteSettings({
                    id: getSettings.data.settings.id,
                    agent_id_active: getSettings.data.settings.agent_id_active,
                    payment_id: getSettings.data.settings.payment_id
                });
                setPaymentData(getSettings.data.payment);
                setAgentData(getSettings.data.agent);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const setAgentActive = async (agent_id: number) => {
        try {
            const service = new ApiService();
            const setAgentActiveById = await service.setActiveAgentById(cookies.get('access_token'), agent_id);

            if(setAgentActiveById.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด (ERR: ${setAgentActiveById.data.message || "ไม่ทราบสาเหตุ"})`
                })
            }

            reloadData();
        } catch (err) {
            console.log(err);
        }
    }

    const setActivePayment = async (payment_id: number) => {
        try {
            const service = new ApiService();
            const setActivePaymentById = await service.setActivePaymentById(cookies.get('access_token'), payment_id);

            if(setActivePaymentById.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด (ERR: ${setActivePaymentById.data.message || "ไม่ทราบสาเหตุ"})`
                })
            }

            reloadData();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.settings ? <>
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="card-title mb-0">ตั้งค่าเว็บไซต์</h5>
                            </div>
                            <div className="row p-2">
                                <div className="col-xs-12 col-md-4 mt-3">
                                    <h6 style={{ display: 'flex', justifyContent: 'center' }}>ตั้งค่า Agent</h6>
                                    <table className="datatables-users table">
                                        <thead className="border-top">
                                            <tr>
                                                <th align="center">Agent</th>
                                                <th align="center">Active</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                agentData && agentData.length > 0 ? agentData.map((element: agentListType, index: number) => {
                                                    return (
                                                        <tr key={`agent-list-${index}`}>
                                                            <td>
                                                                {
                                                                    element.username
                                                                }
                                                            </td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    id={`button-set-active-agent-${element.username}`}
                                                                    className={`btn ${websiteSettings?.agent_id_active !== element.id ? "btn-outline-" : "btn-"}primary`}
                                                                    onClick={() => setAgentActive(element.id)}
                                                                >
                                                                    <i className="bx bx-check"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :  <tr>
                                                    <td align="center" colSpan={2}>
                                                        ไม่มีข้อมูล Agent
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-xs-12 col-md-4 mt-3">
                                    <h6 style={{ display: 'flex', justifyContent: 'center' }}>ตั้งค่า PAYMENT</h6>
                                    <table className="datatables-users table">
                                        <thead className="border-top">
                                            <tr>
                                                <th align="center">Payment</th>
                                                <th align="center">Active</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                paymentData && paymentData.length > 0 ? paymentData.map((element: listPaymentType, index: number) => {
                                                    return (
                                                        <tr key={`agent-list-${index}`}>
                                                            <td>
                                                                {
                                                                    element.name
                                                                }
                                                            </td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    id={`button-set-active-payment-${element.name}`}
                                                                    className={`btn ${websiteSettings?.payment_id !== element.id ? "btn-outline-" : "btn-"}primary`}
                                                                    onClick={() => setActivePayment(element.id)}
                                                                >
                                                                    <i className="bx bx-check"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :  <tr>
                                                    <td align="center" colSpan={2}>
                                                        ไม่มีข้อมูล Payment
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default SettingsMain;