import { useEffect, useState, useRef } from "react";
import { adminPermissionsType, adminProfileType } from '../../types';
import { ApiService } from '../../services/api.service';
import Cookies from "universal-cookie";
import PageLoading from "../PageLoading/PageLoading";
import { useOutletContext } from "../../OutletContext";
import Swal from "sweetalert2";

const Profile = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [profile, setProfile] = useState<adminProfileType | null>(null);
    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    const [processing, setProcessing] = useState<boolean>(false);

    const nameRef = useRef<HTMLInputElement>(null);
    const oldPasswordRef = useRef<HTMLInputElement>(null);
    const newPasswordRef = useRef<HTMLInputElement>(null);
    const confirmNewPasswordRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        loadProfile();
    }, []);

    const loadProfile = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getProfile = await service.getProfile(cookies.get('access_token'));

            setProfile({
                username: getProfile.data.username,
                name: getProfile.data.name
            });
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const handleEditProfile = async () => {
        if(!nameRef.current)
        {
            return;
        }
        else if(nameRef.current.value.trim() === '')
        {
            return;
        }
        else if(!profile)
        {
            return;
        }
        else if(processing)
        {
            return;
        }

        setProcessing(true);
        try {
            const service = new ApiService();
            const editProfile = await service.editProfile(cookies.get('access_token'), nameRef.current.value.trim());

            Swal.fire({
                icon: editProfile.data.status_code === 200 ? 'success' : 'error',
                title: 'แจ้งเตือน',
                text: editProfile.data.message
            });
        } catch (err) {
            console.log(err);
        }
        setProcessing(false);
    }

    const handleChangePassword = async () => {
        if(!oldPasswordRef.current || !newPasswordRef.current || !confirmNewPasswordRef.current)
        {
            return;
        }
        else if(oldPasswordRef.current.value.trim() === '' || newPasswordRef.current.value.trim() === '' || confirmNewPasswordRef.current.value.trim() === '')
        {
            return;
        }
        else if(newPasswordRef.current.value.trim() !== confirmNewPasswordRef.current.value.trim())
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านใหม่ไม่ตรงกัน กรุณากรอกใหม่อีกครั้ง'
            });
        }
        else if(!profile)
        {
            return;
        }
        else if(processing)
        {
            return;
        }

        setProcessing(true);
        try {
            const service = new ApiService();
            const changePassword = await service.changePassword(cookies.get('access_token'), profile.username, oldPasswordRef.current.value.trim(), newPasswordRef.current.value.trim());

            Swal.fire({
                icon: changePassword.data.status_code === 200 ? 'success' : 'error',
                title: 'แจ้งเตือน',
                text: changePassword.data.message
            });
        } catch (err) {
            console.log(err);
        }
        setProcessing(false);
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                profile ? <>
                    <div className="row g-3 mb-3">
                        <div className="col-12">
                            <div className="card p-2">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="card-title mb-0">โปรไฟล์ของ {profile.name}</h5>
                                    <button type="button" className="btn btn-md btn-primary" onClick={handleEditProfile} disabled={processing}>แก้ไขโปรไฟล์</button>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-label" htmlFor="admin_username">ชื่อผู้ใช้:</label>
                                        <input type="text" className="form-control" id="admin_username" value={profile.username} readOnly />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-label" htmlFor="admin_name">ชื่อ:</label>
                                        <input type="text" className="form-control" id="admin_name" defaultValue={profile.name} ref={nameRef} />
                                    </div>
                                </div>
                                <div className="divider">
                                    <div className="divider-text">แก้ไขรหัสผ่าน</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-label" htmlFor="admin_old_password">รหัสผ่านเก่า:</label>
                                        <input type="password" className="form-control" id="admin_old_password" ref={oldPasswordRef} />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-label" htmlFor="admin_new_password">รหัสผ่านใหม่:</label>
                                        <input type="password" className="form-control" id="admin_new_password" ref={newPasswordRef} />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-label" htmlFor="admin_confirm_new_password">ยืนยันรหัสผ่านใหม่:</label>
                                        <input type="password" className="form-control" id="admin_confirm_new_password" ref={confirmNewPasswordRef} />
                                    </div>
                                    <div className="col-sm-12 mt-2">
                                        <button type="button" className="btn btm-sm btn-primary w-100" onClick={handleChangePassword} disabled={processing}>แก้ไขรหัสผ่าน</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default Profile;