import { ApiService } from "../../services/api.service";
import { adminPermissionsType, memberScbTransactionDepositType } from "../../types";
import Cookies from "universal-cookie";
import { useEffect, useState, ChangeEvent } from "react";
import { Typography, Pagination } from "@mui/material";
import PageLoading from "../PageLoading/PageLoading";
import { Link } from "react-router-dom";
import DepositScbMatch from "./DepositScbMatch";
import { useOutletContext } from "../../OutletContext";
import { styled } from "@mui/system";
import EditMember from "../Members/EditMember";

const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "rgba(255, 255, 255, .7)", // สีของหน้าที่ไม่ได้เลือก
    },
    '& .Mui-selected': {
        color: localStorage.getItem('theme') === 'light' ? 'black' : "white",
    },
});

const DepositTransactionScb = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [countTransactions, setCountTransactions] = useState<number | null>(null);
    const [transactions, setTransactions] = useState<memberScbTransactionDepositType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [showOnlyNotMatch, setShowOnlyNotMatch] = useState<boolean>(false);
    const [matchOpen, setMatchOpen] = useState<boolean>(false);
    const [matchTransasction, setMatchTransaction] = useState<memberScbTransactionDepositType | null>(null);

    const [selectMemberId, setSelectMemberId] = useState<number | null>(null);
    const [tabOpen, setTabOpen] = useState<boolean>(false);

    const context = useOutletContext();
    const [permissions, setPermissions] = useState<adminPermissionsType>({member: false, deposit_scb: false, promotion: false, create_admin: false, withdraw_transactions: false, report_all: false, report_all_data: false, report_click: false, settings: false, deposit_transactions: false, managelotto: false, manange_bank_account: false});
    useEffect(() => {
        const tmp_permissions: adminPermissionsType | null = context;
        if(tmp_permissions)
        {
            setPermissions(tmp_permissions);
        }
    }, []);

    useEffect(() => {
        getAllTransactions();
    }, [page, limit, showOnlyNotMatch]);

    useEffect(() => {
        if(!matchOpen)
        {
            setMatchTransaction(null);
        }
    }, [matchOpen])

    const getAllTransactions = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getTransactions = await service.getDepositTransactionsScb(cookies.get('access_token'), page, limit, showOnlyNotMatch);
            if(getTransactions.data.status_code !== 200)
            {
                return;
            }
            setCountTransactions(getTransactions.data.allCount);
            setTransactions(getTransactions.data.transaction);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const handleChangePage = (_e: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const matchTransaction = (transaction: memberScbTransactionDepositType) => {
        if(transaction.member_id !== null)
        {
            return;
        }

        setMatchOpen(true);
        setMatchTransaction(transaction);
    }

    return (
        <>
            {
                loading && <PageLoading />
            }
            {
                permissions.deposit_scb ? <>
                    <DepositScbMatch matchTransasction={matchTransasction} matchOpen={matchOpen} setMatchOpen={setMatchOpen} reloadTransaction={getAllTransactions} />
                    <EditMember tabOpen={tabOpen} setTabOpen={setTabOpen} member_id={selectMemberId} />
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row">
                                <div className="col-sm-12 col-xl-4 d-flex" style={{
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="remember-me" onClick={() => setShowOnlyNotMatch(showOnlyNotMatch ? false : true)} />
                                        <label className="form-check-label" htmlFor="remember-me" style={{ color: '#51659e' }}>
                                            Only not match
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-xl-8" style={{ 
                                    display: `flex`,
                                    justifyContent: `flex-end`,
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    แสดง:&nbsp;
                                    <select id="select2Basic" className="select2 form-select" data-allow-clear="true"
                                        style={{
                                            maxHeight: 40,
                                            maxWidth: 100
                                        }}
                                        defaultValue={`10`}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            if(!e.target)
                                            {
                                                return;
                                            }

                                            setLimit(parseInt(e.target.value));
                                        }}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="70">70</option>
                                        <option value="80">80</option>
                                        <option value="90">90</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <CustomPagination
                                        count={Math.ceil((countTransactions === null ? 0 : countTransactions) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="table-responsive">
                            <table className="table">
                                <thead className="border-top">
                                    <tr>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ชื่อผู้ใช้
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                บัญชีที่โอน
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                บัญชีที่รับ
                                            </Typography>
                                        </th>
                                        <th align="center" colSpan={3}>
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                เครดิต
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                สถานะ
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                วันเวลาที่โอน
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                วันเวลาที่เพิ่ม
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ยืนยันโดย
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                Match
                                            </Typography>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th />
                                        <th />
                                        <th />
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                ก่อนเพิ่ม
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                เพิ่ม
                                            </Typography>
                                        </th>
                                        <th align="center">
                                            <Typography align="center" fontSize={`0.75rem`} fontWeight={`bold`}>
                                                หลังเพิ่ม
                                            </Typography>
                                        </th>
                                        <th />
                                        <th />
                                        <th />
                                        <th />
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactions.length > 0 ? transactions.sort((a: memberScbTransactionDepositType, b: memberScbTransactionDepositType) => {
                                            const dateA = new Date(`${String(a.date)} ${String(a.time)}`).getTime();
                                            const dateB = new Date(`${String(b.date)} ${String(b.time)}`).getTime();
                                            return dateB - dateA;
                                        }).map((transaction: memberScbTransactionDepositType, index: number) => {
                                            return (
                                                <tr key={index} style={
                                                    {
                                                        backgroundColor: transaction.member_id !== null ? "" : "rgba(255, 0, 0, .2)"
                                                    }
                                                }>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                transaction.member_id === null ? "NOT MATCH" : <Link to={`/transaction-deposit-scb`} onClick={() => {
                                                                    setSelectMemberId(transaction.member_id);
                                                                    setTabOpen(true);
                                                                }}>
                                                                    {
                                                                        transaction.ufa_username
                                                                    }
                                                                </Link>
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                transaction.bank_name
                                                            }
                                                            &nbsp;
                                                            (X-{transaction.bank_number})
                                                        </Typography>
                                                        <Typography align="center" fontSize={`0.65rem`}>
                                                            {
                                                                transaction.bank_account_name
                                                            }...
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            SCB (X-{transaction.to_bank_number})
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                parseFloat(String(transaction.get_allCredit)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })
                                                            }
                                                        </Typography>
                                                        
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                parseFloat(String(transaction.amount)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })
                                                            }
                                                        </Typography>
                                                        
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                parseFloat(String(transaction.get_newCredit)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`} color={transaction.member_id === null ? "" : "rgb(5, 200, 85)"}>
                                                            {
                                                                transaction.member_id === null ? "กำลังรอ" : "สำเร็จ"
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                `${String(new Date(String(transaction.date)).getDate()).padStart(2, '0')}/${String(new Date(String(transaction.date)).getMonth() + 1).padStart(2, '0')}/${String(new Date(String(transaction.date)).getFullYear())}`
                                                            }
                                                            &nbsp;
                                                            {
                                                                `${String(transaction.time).slice(0, 5)}`
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                transaction.updatedAt !== null ? <>
                                                                    {
                                                                        `${String(new Date(String(transaction.updatedAt)).getDate()).padStart(2, '0')}/${String(new Date(String(transaction.updatedAt)).getMonth() + 1).padStart(2, '0')}/${String(new Date(String(transaction.updatedAt)).getFullYear())}`
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        `${String(new Date(String(transaction.updatedAt)).getHours()).padStart(2, '0')}:${String(new Date(String(transaction.updatedAt)).getMinutes()).padStart(2, '0')}`
                                                                    }
                                                                </> : "กำลังรอ"
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`}>
                                                            {
                                                                transaction.admin_name
                                                            }
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography align="center" fontSize={`0.95rem`} sx={{ cursor: 'pointer', color: 'rgb(90, 141, 238)', '&:hover': { color: 'rgb(81, 127, 214)' } }} onClick={() => matchTransaction(transaction)}>
                                                            <i className="bx bx-task"></i>
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                            <tr>
                                                <td colSpan={11}>
                                                    <Typography align="center" fontSize={`0.95rem`}>
                                                        ไม่พบข้อมูล
                                                    </Typography>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card p-2">
                            <div className="row g-2">
                                <div className="col-sm-12 col-xl-6" style={{
                                    display: 'flex',
                                    alignContent: `center`,
                                    alignItems: `center`
                                }}>
                                    <CustomPagination
                                        count={Math.ceil((countTransactions === null ? 0 : countTransactions) / limit)}
                                        color="primary"
                                        sx={{ color: 'white'}}
                                        onChange={handleChangePage}
                                        page={page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default DepositTransactionScb;