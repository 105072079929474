import React, { createContext, useContext, ReactNode } from 'react';
import { adminPermissionsType } from './types';

interface OutletContextProps {
    permission: adminPermissionsType;
    children: ReactNode;
}

const OutletContext = createContext<adminPermissionsType | null>(null);

export const OutletContextProvider: React.FC<OutletContextProps> = ({ permission, children }) => {
    return (
        <OutletContext.Provider value={permission}>
            {children}
        </OutletContext.Provider>
    );
};

export const useOutletContext = () => useContext(OutletContext);