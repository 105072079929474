import { Grid, OutlinedInput, FormControl, FormLabel, Typography, InputAdornment, IconButton, Box, Divider } from "@mui/material";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { memberInfoType } from "../../types";

interface propsType {
    memberInfo: memberInfoType | null;
}

const bank_list = [
    {
        "bank_abbr": "BBL",
        "bank_code": "002",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bbl.png",
        "bank_name_en": "Bangkok Bank",
        "bank_name_th": "ธนาคารกรุงเทพ"
    },
    {
        "bank_abbr": "KBANK",
        "bank_code": "004",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kbank.png",
        "bank_name_en": "Kasikorn Bank",
        "bank_name_th": "ธนาคารกสิกรไทย"
    },
    {
        "bank_abbr": "KTB",
        "bank_code": "006",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ktb.png",
        "bank_name_en": "Thai Bank",
        "bank_name_th": "ธนาคารกรุงไทย"
    },
    {
        "bank_abbr": "TTB",
        "bank_code": "011",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ttb.png",
        "bank_name_en": "TMBThanachart Bank",
        "bank_name_th": "ธนาคารทหารไทยธนชาต"
    },
    {
        "bank_abbr": "SCB",
        "bank_code": "014",
        "bank_logo": "https://cdn.bullpay.me/images/logo/scb.png",
        "bank_name_en": "The Siam Commercial Bank",
        "bank_name_th": "ธนาคารไทยพาณิชย์"
    },
    {
        "bank_abbr": "BAY",
        "bank_code": "025",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bay.png",
        "bank_name_en": "Bank of Ayudhya Bank",
        "bank_name_th": "ธนาคารกรุงศรีอยุธยา"
    },
    {
        "bank_abbr": "KKP",
        "bank_code": "069",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kkp.png",
        "bank_name_en": "KIATNAKIN PHATRA BANK",
        "bank_name_th": "ธนาคารเกียรตินาคินภัทร"
    },
    {
        "bank_abbr": "CIMBT",
        "bank_code": "022",
        "bank_logo": "https://cdn.bullpay.me/images/logo/cimb.png",
        "bank_name_en": "CIMB Thai Bank",
        "bank_name_th": "ธนาคารซีไอเอ็มบีไทย"
    },
    {
        "bank_abbr": "TISCO",
        "bank_code": "067",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tisco.png",
        "bank_name_en": "TISCO Bank",
        "bank_name_th": "ธนาคารทิสโก้"
    },
    {
        "bank_abbr": "UOBT",
        "bank_code": "024",
        "bank_logo": "https://cdn.bullpay.me/images/logo/uob.png",
        "bank_name_en": "United Chinese Bank",
        "bank_name_th": "ธนาคารยูโอบี"
    },
    {
        "bank_abbr": "LHFG",
        "bank_code": "073",
        "bank_logo": "https://cdn.bullpay.me/images/logo/lhbank.png",
        "bank_name_en": "Land and Houses",
        "bank_name_th": "ธนาคารแลนด์ แอนด์ เฮ้าส์"
    },
    {
        "bank_abbr": "BAAC",
        "bank_code": "034",
        "bank_logo": "https://cdn.bullpay.me/images/logo/baac.png",
        "bank_name_en": "Bank for Agriculture and Agricultural Cooperatives",
        "bank_name_th": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
    },
    {
        "bank_abbr": "GSB",
        "bank_code": "030",
        "bank_logo": "https://cdn.bullpay.me/images/logo/gsb.png",
        "bank_name_en": "Government Savings Bank",
        "bank_name_th": "ธนาคารออมสิน"
    },
    {
        "bank_abbr": "GHB",
        "bank_code": "033",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ghb.png",
        "bank_name_en": "Government Housing Bank",
        "bank_name_th": "ธนาคารอาคารสงเคราะห์"
    },
    {
        "bank_abbr": "TMW",
        "bank_code": "099",
        "bank_logo": "https://cdn.bullpay.me/images/logo/truemoney.png",
        "bank_name_en": "True Money Wallet",
        "bank_name_th": "ทรูมันนี่วอลเล็ท"
    },
    {
        "bank_abbr": "TBANK",
        "bank_code": "065",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tbank.png",
        "bank_name_en": "Thanachart Bank Public Company Limited",
        "bank_name_th": "ธนาคารธนชาต"
    },
    {
        "bank_abbr": "SCBT",
        "bank_code": "020",
        "bank_logo": "https://cdn.bullpay.me/images/logo/scbt.png",
        "bank_name_en": "Standard Chartered Bank (Thai) Public Company Limited",
        "bank_name_th": "ธนาคารสแตนดาร์ดชาร์เตอร์ด ประเทศไทย"
    },
    {
        "bank_abbr": "TCRB",
        "bank_code": "071",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tcrb.png",
        "bank_name_en": "The Thai Credit Retail Bank Public Company Limited",
        "bank_name_th": "ธนาคารไทยเครดิต"
    },
    {
        "bank_abbr": "ISBT",
        "bank_code": "066",
        "bank_logo": "https://cdn.bullpay.me/images/logo/isbt.png",
        "bank_name_en": "ISLAMIC BANK OF THAILAND",
        "bank_name_th": "ธนาคารอิสลาม"
    },
    {
        "bank_abbr": "SMEB",
        "bank_code": "098",
        "bank_logo": "https://cdn.bullpay.me/images/logo/smeb.png",
        "bank_name_en": "Small and Medium Enterprise Development Bank of Thailand",
        "bank_name_th": "ธนาคารเพื่อเอสเอมอี"
    },
    {
        
        "bank_abbr": "ACL",
        "bank_code": "070",
        "bank_logo": "https://cdn.bullpay.me/images/logo/acl.png",
        "bank_name_en": "ACL Bank Public Company Limited",
        "bank_name_th": "ACL Bank"
    }
];

const MemberInfo = ({ memberInfo }: propsType) => {
    const copyToClipboard = (text: string) => {
        // Copy the text inside the text field
        navigator.clipboard.writeText(text);
    }

    return (
        <Grid item xs={12} sm={12} md={4} lg={3}>
            {
                memberInfo !== null ? <Box width={`100`} sx={
                    {
                        backgroundColor: 'transparent',
                        border: 'solid 1px rgba(255, 255, 255, .4)',
                        borderRadius: 2,
                        p: 2
                    }
                }>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor="user_ufa_username">
                                    <Typography color={`white`} fontSize={14}>
                                        ชื่อผู้ใช้ UFA
                                    </Typography>
                                </FormLabel>
                                <OutlinedInput
                                    name='user_ufa_username'
                                    type='text'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => copyToClipboard(memberInfo.ufa_username)}>
                                                <ContentPasteIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    readOnly
                                    fullWidth
                                    autoComplete="user_ufa_username"
                                    value={memberInfo.ufa_username}
                                    sx={
                                        {
                                            backgroundColor: 'white',
                                            maxHeight: 34
                                        }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor="user_name">
                                    <Typography color={`white`} fontSize={14}>
                                        ชื่อ-สกุล
                                    </Typography>
                                </FormLabel>
                                <OutlinedInput
                                    name='user_name'
                                    type='text'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => copyToClipboard(`${memberInfo.first_name} ${memberInfo.last_name}`)}>
                                                <ContentPasteIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    readOnly
                                    fullWidth
                                    autoComplete="user_name"
                                    value={`${memberInfo.first_name} ${memberInfo.last_name}`}
                                    sx={
                                        {
                                            backgroundColor: 'white',
                                            maxHeight: 34
                                        }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor="user_phone">
                                    <Typography color={`white`} fontSize={14}>
                                        เบอร์โทร
                                    </Typography>
                                </FormLabel>
                                <OutlinedInput
                                    name='user_phone'
                                    type='text'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => copyToClipboard(memberInfo.phone)}>
                                                <ContentPasteIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    readOnly
                                    fullWidth
                                    autoComplete="user_phone"
                                    value={`${memberInfo.phone}`}
                                    sx={
                                        {
                                            backgroundColor: 'white',
                                            maxHeight: 34
                                        }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor="user_line">
                                    <Typography color={`white`} fontSize={14}>
                                        ไลน์
                                    </Typography>
                                </FormLabel>
                                <OutlinedInput
                                    name='user_line'
                                    type='text'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton  onClick={() => copyToClipboard(`${memberInfo.line === '' ? '-' : memberInfo.line}`)}>
                                                <ContentPasteIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    readOnly
                                    fullWidth
                                    autoComplete="user_line"
                                    value={`${memberInfo.line === '' ? '-' : memberInfo.line}`}
                                    sx={
                                        {
                                            backgroundColor: 'white',
                                            maxHeight: 34
                                        }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    <Typography color={`white`} fontSize={14}>
                                        บัญชีธนาคาร
                                    </Typography>
                                </FormLabel>
                                <Box width={`100%`} sx={
                                    {
                                        backgroundColor: 'transparent',
                                        border: 'solid 1px rgba(255, 255, 255, .4)',
                                        borderRadius: 2
                                    }
                                }>
                                    <Grid container spacing={.5} sx={
                                        {
                                            p: 1
                                        }
                                    }>
                                        <Grid item xs={2} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                            <img
                                                src={bank_list[bank_list.findIndex((element) => element.bank_abbr === memberInfo.bank)].bank_logo}
                                                alt={bank_list[bank_list.findIndex((element) => element.bank_abbr === memberInfo.bank)].bank_name_th}
                                                style={{ maxHeight: 30 }}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography fontSize={12} color={`white`} display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                { memberInfo.first_name } { memberInfo.last_name }
                                            </Typography>
                                            <Divider sx={{ borderColor: 'rgba(255, 255, 255, .4)' }} />
                                            <Typography fontSize={12} color={`white`} display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                { memberInfo.bank_number }
                                                <IconButton onClick={() => copyToClipboard(memberInfo.bank_number)}>
                                                    <ContentPasteIcon sx={{ fontSize: 12, color: 'white' }} />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor="user_knowat">
                                    <Typography color={`white`} fontSize={14}>
                                        รู้จักจาก
                                    </Typography>
                                </FormLabel>
                                <OutlinedInput
                                    name='user_knowat'
                                    type='text'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => copyToClipboard(memberInfo.know_at)}>
                                                <ContentPasteIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    readOnly
                                    fullWidth
                                    autoComplete="user_knowat"
                                    value={`${memberInfo.know_at}`}
                                    sx={
                                        {
                                            backgroundColor: 'white',
                                            maxHeight: 34
                                        }
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box> : null
            }
        </Grid>
    )
}

export default MemberInfo;